<ng-container *ngIf="editorService.isConfigCompleted | async">
  <h4 *ngIf="config.title" class="fieldtitle">{{ config.title }}</h4>
  <mat-form-field [formGroup]="formGroup" appearance="outline">
    <mat-label style="margin-bottom: 12px">
      {{ config.label }}
    </mat-label>

    <editor [formControlName]="config.key" [init]="editorService.settings" [toolbar]="editorService.toolbar"
      [required]="config.required" tinyMatFormControl></editor>
    <mat-error *ngIf="formGroup.get(config.key).errors?.maxlength">
      {{ 'schadenmeldung.error_maxlength.area' | translate }}
    </mat-error>
  </mat-form-field>
</ng-container>
