/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2025-01-23 10:24:57.

import { Observable } from "rxjs";;

export interface AbstractAngularController {
}

/**
 * Used to detect edit conflicts between client and the server. The server always sends the current version to the client. The client sends back the same version (=> The version it is editing) to the server. The server can then detect, if the object was modified between opening the dialog and submission. For new Objects, the Version sent by the client is always 0.
 */
export interface AbstractVersionedEditDto {
    /**
     * Current version. 0 for new entities.
     */
    version: number;
}

export interface AktePosteingangDto {
    aktuellImEingang: boolean;
    gesendetAm: Date;
    vonName: string;
    anName: string;
    betreff: string;
    aktenAnhang: AktenAnhang[];
    zuordnung: string[];
}

export interface AnsprechpartnerDto {
    id: number;
    label: string;
    email: string;
    name: string;
    telefon: string;
}

export interface BedingungDto {
    id: number;
    bezeichnung: string;
}

export interface BriefAdresseDto extends IBriefAdresse {
}

export interface BundleDto {
    id: number;
    sichtbarAb: string;
    verbindlichAb: string;
    bedingungen: BedingungDto[];
    akzeptiert: boolean;
    mandatory: boolean;
}

export interface CaptchaErrorResponseDto {
    showCaptcha: boolean;
}

export interface DateFilterDto {
    min: string;
    max: string;
}

export interface DomainObjectIdDto {
    vertragId: number;
    schadenId: number;
    aktenEintragId: number;
    akteKundeId: number;
    schadenEreignisId: number;
}

export interface EigenverwaltungDataDto {
    tenantFelder: ObjectNode;
}

export interface EigenverwaltungStammdatenDto {
    fieldConfigDtos: AbstractFieldConfigDtoUnion[];
}

/**
 * Simple DTO indicating that an exception occurred. We may fill it with more information, but now it is just emoty.
 */
export interface ExceptionDto {
    error: boolean;
}

export interface FontEditDto {
    id: number;
    name: string;
}

export interface IdBezeichnungDto {
    id: string;
    bezeichnung: string;
    iconKey?: string;
}

export interface IdBezeichnungWithGesperrtFlagDto extends IdBezeichnungDto {
    gesperrt: boolean;
}

export interface IdBezeichnungWithParentDto extends IdBezeichnungDto {
    parentId: string;
    aktiv: boolean;
}

export interface IdBezeichnungWithRefDto extends IdBezeichnungDto {
    foreignId: string;
    info: string;
}

export interface InternalHyperlinkDto {
    label: string;
    path: string;
    icon: string | null;
}

export interface KeycloakUrlDto {
    url: string;
}

export interface ListeDto<T> {
    /**
     * Inhalt der Seite
     */
    list: T[];
    /**
     * Zahl aller einträge
     */
    length: number;
    /**
     * aktuelle Seite (nullbasiert)
     */
    pageIndex: number;
    /**
     * Anzahl Einzträge pro Seite
     */
    pageSize: number;
    canCreate: boolean;
}

export interface ListeKundeOptionsDto {
    kundeBezeichnung: string;
    kundeVbnr: number;
    kundennummer: string;
}

export interface LocalDateFilterDto {
    min: string;
    max: string;
}

export interface MinimalExternalUserDto {
    id: number;
    name: string;
}

/**
 * Simple DTO indicating that an exception occurred. We may fill it with more information, but now it is just emoty.
 */
export interface ReserveTooLowExceptionDto extends ExceptionDto {
    reserveToLow: boolean;
}

export interface RisikoStrukturKnotenDto extends IdBezeichnungWithParentDto {
    typ: RisikoStrukturKnotenTypDto;
    children: RisikoStrukturKnotenDto[];
}

export interface SortDto<T> extends ISortDto<T> {
    order: SortOrderDto;
}

export interface SupportDto {
    youtube: string;
}

export interface TempWithVersionDto {
    version: number;
}

export interface TerminPosteingangDto {
    erinnert: boolean;
    erledigt: boolean;
    zeitpunkt: Date;
    grund: string;
    id: number;
    version: number;
}

export interface UpdateInfoDto {
    title: string;
    description: string;
    date: string;
    id: number;
}

export interface UrlDto {
    url: string;
}

/**
 * Send user name + id to the client. We have two user types: ecc_mitarbeiter and external user.
 */
export interface UserReferenceDto {
    id: number;
    name: string;
    partnernummer: number;
    aktiv: boolean;
    localeLanguage: string;
}

export interface AdressbuchEintragConnectDto {
    eintragId: number;
    zuordnungDto: AdressbuchEintragRefDto;
}

export interface AdressbuchEintragDto extends CustomFieldValueSupport {
    eintragId: number;
    anredeId: string;
    titel: string;
    vorname: string;
    nachname: string;
    unternehmen: string;
    adresszusatz: string;
    kategorieId: number;
    position: string;
    strasse: string;
    hausnummer: string;
    postleitzahl: string;
    ort: string;
    land: string;
    telefon: string;
    mobil: string;
    email: string;
    /**
     * Typed id where the type can be Schaden, Vertrag or Kundenakte (Box)
     */
    zuordnungDto: AdressbuchEintragRefDto;
    typ: AdressbuchEintragTypDto;
}

export interface AdressbuchEintragListeDto {
    eintraege: AdressbuchEintragDto[];
    totalResults: number;
    pageSize: number;
}

export interface AdressbuchEintragRefDto {
    refId: number;
    refTyp: AdressbuchEintragRefTypDto;
}

export interface AdressbuchInfoDto {
    values: AdresseInfo[];
}

export interface AdressbuchInfoSucheDto {
    typ: AdressbuchSucheTypDto;
    ref: AdressbuchEintragRefDto;
}

export interface AdressbuchKategorieDto {
    id: number;
    tenantId: number;
    bezeichnung: string;
    aktiv: boolean;
}

export interface AdressbuchKategorieListeDto {
    categories: AdressbuchKategorieListenEintragDto[];
}

export interface AdressbuchKategorieListenEintragDto extends AdressbuchKategorieDto {
    refCount: number;
}

export interface AdressbuchStammdatenDto extends CustomFieldSupport {
    anrede: IdBezeichnungDto[];
    kategorien: AdressbuchKategorieDto[];
}

export interface AdressbuchSucheDto extends ListRequest {
    searchTerm: string;
    searchIndex: string;
    typ: AdressbuchSucheTypDto;
    /**
     * Optional ref to Schaden/Vertrag
     */
    ref: AdressbuchEintragRefDto;
    /**
     * Sort order
     */
    order: SortDto<string>;
    pageIndex: number;
    spalten: string[];
}

export interface AbstractAkteUploadListItemDto {
    type: "existing" | "upload";
}

/**
 * Information about which actions are possible for that akte eintrag
 */
export interface AkteEintragActionsDto {
    edit: AbstractAkteEditDtoUnion;
    /**
     * If the user is allowed to remove a digital folder entry. True if the entry is managed by Ecclesia, false otherwise
     */
    canBeRemoved: boolean;
    /**
     * If the user is allowed to create a note. That depends on the AV-Role
     */
    canCreateNote: boolean;
    /**
     * If the user is allowed to create a due date.
     */
    canCreateTermin: boolean;
    canCreateVorlage: boolean;
    forward: AkteEditEmailDto;
    /**
     * Aufgabentyp der erstellt werden kann (mit / ohne ADV)
     */
    aufgabenTyp: AufgabeTypDto;
    /**
     * An Makler antworten. Achtung: Bei Erstellen der Antwort im MaklernachrichtErstellenDto mitsenden, dass es eine ist.
     */
    replyToMakler: AkteEditMaklernachrichtDto;
    /**
     * Vereinfachtes Weiterleiten für Nutzer ohne AV
     */
    canForwardLight: boolean;
    /**
     * If the user is allowed to change anhang file name.
     */
    canEditAnhangName: boolean;
}

export interface AkteListeFullRequestDto extends ListRequest {
    order: SortDto<AkteSortDto>;
    schlagworte: SchlagwortFilterDto;
    search: string;
    akteDatum: DateFilterDto;
    akteRefs: AkteRefDto[];
    onlyWithUploads: boolean;
    concernsMe: boolean;
    kundeVbnrs: number[];
    typen: AkteListeTypRequestDto[];
    dateiTypen: DateiTypDto[];
}

export interface AkteListePagedRequestDto extends AkteListeFullRequestDto, ISavableUserFilter<AkteListePagedRequestDto> {
    pageIndex: number;
    elementToShowId: number;
}

export interface AktePermissionsDto {
    hasPermissionOn: AktePermissionDto[];
}

/**
 * Represents typed references as tuples (<type>, <id>) where <type> is of type {@link AkteRefTypDto} and <id> is a number
 */
export interface AkteRefDto {
    /**
     * The type associated with the id
     */
    aktenTyp: AkteRefTypDto;
    /**
     * I am only relevant for type {@link AkteRefTypDto#KUNDENAKTE}
     */
    boxThemaId?: number;
    /**
     * The id's type {@link AkteRefTypDto}
     */
    id: number;
}

export interface AkteTypenRightDto {
    akteTypenRights: AkteListeTypRequestDto[];
}

export interface AkteUploadListItemExistingDto extends AbstractAkteUploadListItemDto {
    type: "existing";
    id: number;
    fileName: string;
}

export interface AkteUploadListItemUploadDto extends AbstractAkteUploadListItemDto {
    type: "upload";
    index: number;
}

export interface AktenAnhangDto {
    /**
     * The ID of the AktenAnhang (not to confuse with the ID of the Eintrag this Anhang belongs to)
     */
    id: number;
    /**
     * The file name provided by the user. It is constrained by a set of allowed characters
     */
    fileName: string;
    previewAvailable: boolean;
    /**
     * Only a fixed set of data formats can be previewed (pdf, jpg, png, docx, pptx, xlsx)
     */
    previewAnhang: boolean;
    pages: number;
    order: number;
}

export interface AktenAnhangEditDto extends IAktenAnhangEdit {
    /**
     * The unique identifier of the attachment
     */
    id: number;
    /**
     * The file name provided by the user.
     */
    fileName: string;
}

export interface AktenEintragAnhaengeZipDownloadRequestDto {
    aktenEintragId: number;
}

export interface AktenEintragDto extends AktenListenEintragDto {
    aufgabe: LightAufgabeDtoUnion;
    aktionen: AkteEintragActionsDto;
    terminDto: TerminDetailsDto;
    nachricht: string;
    weitergeleitetAm: string;
    geantwortetAm: string;
    /**
     * The akteRefs this entry is for. An entry may be in multiple Akte, so we might have multiple references here.
     */
    akteRefs: LabeledAkteRefDto[];
    anzahlAnhaenge: number;
    schlagworte: string[];
    kommentar: string;
}

export interface AktenListeDto {
    eintraege: ListeDto<AktenListenEintragDto>;
    akteRefs: AkteRefDto[];
}

export interface AktenListenEintragDto {
    id: number;
    hasTermin: boolean;
    vonName: string;
    anPostkorb: string;
    anName: string;
    betreff: string;
    gesendetAm: string;
    typ: AktenTypDto;
    richtungTyp: AkteEintragTypRichtungDto;
    /**
     * E-Mail was uploaded but not fully processed yet.
     */
    unfinishedEmailUpload: boolean;
    anhaenge: AktenAnhangDto[];
    /**
     * Answers if the associated inbox entry can be removed (marked as done))
     */
    hasPosteingang: boolean;
    aktenAnhaenge: AktenAnhangDto[];
}

export interface AkteneintragErstellenDto<T> {
    akteRefs: AkteRefDto[];
    edit: T;
    termin: TerminCreateDto;
}

/**
 * Contains the ids of the domain objects (Box | Kunde | Schaden | Vertrag)
 */
export interface AnhaengeZipDownloadRequestDto {
    domainObjectIds: number[];
}

export interface DateiTypenListDto {
    dateiTypen: DateiTypDto[];
}

export interface EmailAblegenDto extends AkteneintragErstellenDto<AkteEditEmailDto> {
    edit: AkteEditEmailDto;
    richtungTyp: AkteEintragTypRichtungDto;
}

export interface EmailEmpfaengerDto {
    name: string;
    email: string;
}

/**
 * Client → Server, create an email.
 */
export interface EmailErstellenDto extends AkteneintragErstellenDto<AkteEditEmailDto> {
    edit: AkteEditEmailDto;
}

export interface LabeledAkteRefDto extends AkteRefDto {
    bezeichnung: string;
}

export interface MaklernachrichtAdressenRequestDto {
    akteRefs: AkteRefDto[];
    maklernachrichtId: number;
}

export interface MaklernachrichtEmpfaengerDto {
    id: number;
    adresse: BriefAdresseDto;
    defaultSelection: boolean;
    maklerNachrichtTarget: MaklerNachrichtTargetDto;
}

export interface MaklernachrichtErstellenDto extends AkteneintragErstellenDto<AkteEditMaklernachrichtDto> {
    edit: AkteEditMaklernachrichtDto;
    reply: boolean;
}

/**
 * Client → Server, create a note.
 */
export interface NotizErstellenDto extends AkteneintragErstellenDto<AkteEditNotizDto> {
    edit: AkteEditNotizDto;
}

export interface PosteingangDetailEintragDto {
    id: number;
    user: UserReferenceDto;
    kommentar: string;
    akteRefs: LabeledAkteRefDto[];
    akteEintrag: AktenEintragDto;
    date: string;
    typ: PosteingangListenEintragTypDto;
}

export interface PosteingangFilterDto extends ISavableUserFilter<PosteingangFilterDto> {
    datum: DateFilterDto;
    userIds: number[];
    typ: PosteingangListenEintragTypDto;
    searchTerm: string;
    order: SortDto<PosteingangSortDto>;
    pageIndex: number;
    elementToShowId?: number;
}

export interface PosteingangListeDto {
    liste: ListeDto<PosteingangListenEintragDto>;
    listenInfo: PosteingangListenInfoDto[];
}

export interface PosteingangListenEintragDto {
    id: number;
    akteEintrag: AktenListenEintragDto;
    date: string;
    typ: PosteingangListenEintragTypDto;
}

export interface PosteingangListenInfoDto {
    anzahl: number;
    typ: PosteingangListenEintragTypDto;
}

export interface SchlagwortFilterDto {
    values: string[];
    operator: SchlagwortOperatorDto;
}

/**
 * Client → Server, create a note.
 */
export interface SeeAnforderungErstellenDto extends IAkteEditSeeAnforderung {
    akteRefs: AkteRefDto[];
}

export interface AufgabeDto extends LightAufgabeDto, IAufgabe {
    aufgabeTyp: "full";
    todos: AufgabeTodoDto[];
}

export interface AufgabeTodoDto extends IAufgabeTodo {
}

export interface ExternalUserWithAccessDto {
    user: MinimalExternalUserDto;
    mayAccess: boolean;
}

export interface LightAufgabeDto extends ILightAufgabe {
    aufgabeTyp: "light" | "full";
    user: MinimalExternalUserDto;
}

/**
 * Nur Schlagworte bearbeiten.
 */
export interface AkteEditBriefDto extends AkteEditSchlagworteDto, IAkteEditBrief {
    editingType: "brief" | "briefentwurf";
}

export interface BriefEditorStammdatenDto {
    userKontaktdaten: IdBezeichnungDto[];
    freigabenUser: IdBezeichnungDto[];
    unterschriftUser: IdBezeichnungDto[];
    versandArten: IdBezeichnungDto[];
    briefboegen: BriefbogenStammdatenDto[];
    standardBriefbogen: number;
}

export interface BriefPreviewDto {
    base64Pages: string[];
}

export interface BriefSendenReplyDto {
    attachmentCount: number;
    versandArtDto: VersandArtDto;
    akteEintragId: number;
    anhaenge: AktenAnhangDto[];
}

export interface BriefSendenRequestDto {
    akteEintragId: number;
    schlagworte: string[];
    kommentar: string;
}

export interface BriefbogenBackgroundInfoDto {
    hasFirstPageBackground: boolean;
    hasFollowPageBackground: boolean;
}

export interface BriefbogenBoxDto {
    id: number;
    briefbogenId: number;
    boxType: BriefbogenBoxTypeDto;
    fontId: number;
    fontName: string;
    fontSize: number;
    textAlign: string;
    startX: number;
    startY: number;
    width: number;
    height: number;
    label: string;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    defaultValueSchaden: string;
    defaultValueVertrag: string;
    defaultValueKundenakte: string;
    absenderText: string;
}

export interface BriefbogenBoxTypeDto {
    boxType: BoxType;
    color: string;
}

export interface BriefbogenDto {
    id: number;
    title: string;
    datePattern: string;
    locale: Locale;
    boxes: BriefbogenBoxDto[];
}

export interface BriefbogenEditorStammdatenDto {
    availableBoxTypen: BriefbogenBoxTypeDto[];
    availableFonts: IdBezeichnungDto[];
    availableTextAlignments: string[];
    availableLocales: IdBezeichnungDto[];
    userKontaktdaten: IdBezeichnungDto[];
}

export interface BriefbogenListDto {
    entries: BriefbogenListEntryDto[];
}

export interface BriefbogenListEntryDto extends IBriefbogen {
    type: "briefbogen";
    id: number;
    title: string;
    datePattern: string;
    locale: Locale;
}

export interface BriefbogenListStammdatenDto {
    availableFonts: IdBezeichnungDto[];
    availableLocales: IdBezeichnungDto[];
    availableTemplates: IdBezeichnungDto[];
}

export interface BriefbogenPreviewRequestDto {
    briefbogenId: number;
    coloredBoxes: boolean;
    userKontaktdatenUserId: number;
}

export interface BriefbogenSaveRequestDto {
    /**
     * The id of the object
     */
    id: number;
    /**
     * The id of an object used as a template
     */
    templateId: number;
    title: string;
    fontId: number;
    datePattern: string;
    locale: Locale;
}

export interface BriefbogenStammdatenDto {
    briefbogen: IdBezeichnungDto;
    additionalBoxes: IdBezeichnungDto[];
    betreffBox: IdBezeichnungDto;
}

export interface BriefeditorTextbausteinRequestDto extends TextbausteinRequestDto {
    briefentwurfDataDto: BriefentwurfDataDto;
}

export interface BriefentwurfDataDto {
    briefbogenId: number;
    position: string;
    anredeId: string;
    titel: string;
    vorname: string;
    nachname: string;
    unternehmen: string;
    adresszusatz: string;
    strasse: string;
    hausnummer: string;
    plz: string;
    ort: string;
    land: string;
    email: string;
    betreff: string;
    nachricht: string;
    datum: string;
    userKontaktDatenUserId: number;
    additionalBox1Value: string;
    additionalBox2Value: string;
    additionalBox3Value: string;
    additionalBox4Value: string;
    akteRefs: AkteRefDto[];
}

/**
 * Nur Schlagworte bearbeiten.
 */
export interface BriefentwurfDto extends AkteEditBriefDto, IAkteEditBriefentwurf, AkteEditWithFilesDto {
    editingType: "briefentwurf";
    versandArt: VersandArtDto;
    kommentar: string;
    freigaben: FreigabenDto;
    briefentwurfDataDto: BriefentwurfDataDto;
    akteId: number;
}

/**
 * Client → Server, create a briefentwurf.
 */
export interface BriefentwurfErstellenDto extends AkteneintragErstellenDto<BriefentwurfDto> {
    edit: BriefentwurfDtoUnion;
}

export interface BriefhistorieDto {
    briefhistorienEintraege: BriefhistorieEintragDto[];
}

export interface BriefhistorieEintragDto {
    text: string;
    kommentar: string;
    eintragVon: string;
    weitergeleitetAn: string;
    datum: string;
}

export interface FontDto {
    id: number;
    name: string;
    base64Ttf: string;
}

export interface ForwardRequestDto {
    userToForwardTo: number;
    akteEintragId: number;
}

export interface FreigabeDto {
    userId: number;
    typ: FreigabeTyp;
    freigegeben: boolean;
}

export interface FreigabeViewDto {
    freigaben: FreigabenDto;
    kommentar: string;
    versandArtDto: VersandArtDto;
    preview: BriefPreviewDto;
    schlagworte: string[];
}

export interface FreigabenDto {
    freigaben: FreigabeDto[];
    linksUnterschrift: FreigabeDto;
    rechtsUnterschrift: FreigabeDto;
}

export interface TextbausteinDto {
    id: number;
    bezeichnung: string;
    rawText: string;
    resolvedText: string;
    visibility: TextbausteinVisibilityDto;
}

export interface TextbausteinErstellenDto {
    bezeichnung: string;
    text: string;
    visibility: TextbausteinVisibilityDto;
}

export interface TextbausteinRequestDto {
    akteRefs: AkteRefDto[];
}

export interface TextbausteinVariableDto {
    group: TextbausteinVarGroup;
    variableName: string;
    label: string;
    text: string;
    deprecated: boolean;
}

export interface VariableGroupRequestDto {
    variableGroup: VariableGroup;
    tenantId: number;
}

/**
 * For editing an {@link de.ecclesia.connect.core.db.entity.akte.AktenEintrag}.Sent from Client to Server to edit. Sent from Server to client to fill the edit dialog.
 */
export interface AbstractAkteEditDto extends AbstractVersionedEditDto {
    editingType: "AbstractAkteEditDto" | "schadenmeldung" | "schlagworte" | "brief" | "briefentwurf" | "email" | "maklernachricht" | "notiz";
    akteRefs: AkteRefDto[];
}

export interface AkteCreateForwardLightDto {
    akteEintragId: number;
}

/**
 * Nur Schlagworte bearbeiten.
 */
export interface AkteEditEmailDto extends AkteEditSchlagworteDto, IAkteEditMail, AkteEditWithFilesDto {
    editingType: "email";
    to: EmailEmpfaengerDto[];
    cc: EmailEmpfaengerDto[];
    akteEintragTypRichtungDto: AkteEintragTypRichtungDto;
}

/**
 * Nur Schlagworte bearbeiten.
 */
export interface AkteEditMaklernachrichtDto extends AkteEditSchlagworteDto, IAkteEditMaklernachricht, AkteEditWithFilesDto {
    editingType: "maklernachricht";
    nachrichtTarget: MaklerNachrichtTargetDto;
    richtungDto: AkteEintragTypRichtungDto;
    gesendetAm: string;
}

/**
 * Editing a note
 */
export interface AkteEditNotizDto extends AkteEditSchlagworteDto, IAkteEditNotiz, AkteEditWithFilesDto {
    editingType: "notiz";
    an: MinimalExternalUserDto;
    typ: AkteEintragTypRichtungDto;
}

/**
 * Currently this is a version number holder needed to delete instances of this class
 */
export interface AkteEditSchadenmeldungDto extends AbstractAkteEditDto {
    editingType: "schadenmeldung";
}

/**
 * Nur Schlagworte bearbeiten.
 */
export interface AkteEditSchlagworteDto extends AbstractAkteEditDto {
    editingType: "schlagworte" | "brief" | "briefentwurf" | "email" | "maklernachricht" | "notiz";
    /**
     * Schlagworte as array
     */
    schlagworte: string[];
    forward: UserToForwardDto[];
}

export interface AkteEditWithFilesDto {
    files: AbstractAkteUploadListItemDtoUnion[];
}

export interface UserToForwardDto extends IUserToForwardTo {
    user: MinimalExternalUserDto;
}

export interface BoxCreateResultDto {
    entryId: number;
}

export interface BoxDataDto extends EigenverwaltungDataDto {
    status: BoxStatusDto;
    boxThemaId: number;
}

export interface BoxFormDataDto extends BoxDataDto {
    boxEntryId: number;
    stammdaten: BoxStammdatenDto;
}

export interface BoxListPermissionsDto {
    /**
     * Answers if the user can create new box entries (a.k.a 'AkteKunde or Kundenakte')
     */
    canCreate: boolean;
}

export interface BoxListRequestDto extends ListRequest, ISavableUserFilter<BoxListRequestDto> {
    pageIndex: number;
    search: string;
    status: BoxStatusDto[];
    sort: SortDto<string>;
    spalten: string[];
    boxThemaId: number;
}

export interface BoxPermissionsDto {
    /**
     * Answers if the user can update/edit box entries (a.k.a 'AkteKunde or Kundenakte') and there are at least one editable extension field available
     */
    canEdit: boolean;
}

export interface BoxRefDto {
    /**
     * The id of the box entry
     */
    id: number;
    /**
     * The id of topic this box entry is associated with
     */
    themaId: number;
}

export interface BoxStammdatenDto extends EigenverwaltungStammdatenDto {
}

export interface BoxTenantFeldListDto extends PosteingangEntryFilterDto, ISavableUserFilter<BoxTenantFeldListDto> {
    key: "bad";
}

export interface CaptchaDto {
    /**
     * The ID of this challenge
     */
    id: number;
    /**
     * The image that should be displayed to the user, as data: url
     */
    imageData: string;
    /**
     * The token that needs to be sent in response (just an alternative to the ID, we would not really need this)
     */
    token: string;
}

export interface CaptchaSolutionDto extends CaptchaSolutionData {
}

export interface DashboardDto {
    anzahlOffeneSchaeden: TogglableDataDto<number>;
    anzahlGeschlosseneSchaeden: TogglableDataDto<number>;
    anzahlVertraege: TogglableDataDto<number>;
    gesamtBruttoPraemie: TogglableDataDto<number>;
    schadenTypen: TogglableDataDto<SchadenTypCountDto[]>;
    versicherungsArten: TogglableDataDto<VertragArtStatisticDto[]>;
    innenDienstAnsprechpartner: TogglableDataDto<AnsprechpartnerDto>;
    aussenDienstAnsprechpartner: TogglableDataDto<AnsprechpartnerDto>;
    kfzAnsprechpartner: TogglableDataDto<AnsprechpartnerDto>;
    canSchadenmelden: boolean;
    canSchadenErstellen: boolean;
    hasAktionen: boolean;
}

export interface SchadenTypCountDto {
    id: string;
    bezeichnung: string;
    count: number;
}

export interface TogglableDataDto<T> extends TogglableDto {
    data: T;
}

export interface TogglableDto {
    toggled: boolean;
}

/**
 * API DTO for a 'Gruppierung'. A Gruppierung defines the type of a Vertrag, computed by it's most relevant Sparte. Two instances may be considered equal if they have the same gruppierungsTyp and the same schluessel.
 */
export interface VertragArtStatisticDto extends VertragArtDto {
    count: number;
    cumulativeBruttoJahresPraemie: number;
}

export interface AbstractEvbAbrufRequestWagnisDto {
    type: "AbstractEvbAbrufRequestWagnisDto" | "predefined" | "sonstige";
}

export interface AbstractEvbAbrufResponseDto {
    type: "fehlgeschlagen" | "success";
    abrufPdf: AktenAnhangDownloadDto;
}

export interface DauerEvbDto {
    bezeichnung: string;
    strasse: string;
    plz: string;
    ort: string;
    dauerEvb: string;
}

export interface EvbAbrufRequestAbweichenderHalterDto extends AbweichenderHalterAdresse {
}

export interface EvbAbrufRequestDto {
    /**
     * ID des Kunden, für den die Anfrage ist
     */
    kundeId: number;
    /**
     * Das Wagnis. Für vordefinierte die ID aus den Stammdaten, für alle anderen den eingegebenen Text
     */
    wagnis: AbstractEvbAbrufRequestWagnisDtoUnion;
    /**
     * ID für die Verwendung aus den Stammdaten
     */
    verwendungId: string;
    /**
     * Adresse des abweichenden Halters - null falls kein abweichender Halter
     */
    abweichenderHalter: EvbAbrufRequestAbweichenderHalterDto;
}

export interface EvbAbrufRequestWagnisPredefinedDto extends AbstractEvbAbrufRequestWagnisDto {
    type: "predefined";
    wagnisId: string;
}

export interface EvbAbrufRequestWagnisSonstigeDto extends AbstractEvbAbrufRequestWagnisDto {
    type: "sonstige";
    wagnis: string;
}

export interface EvbAbrufResponseFehlgeschlagenDto extends AbstractEvbAbrufResponseDto {
    type: "fehlgeschlagen";
}

export interface EvbAbrufResponseSuccessDto extends AbstractEvbAbrufResponseDto {
    type: "success";
    vbNummer: string;
}

export interface EvbAbrufStammdatenDto {
    kunden: IdBezeichnungDto[];
    wagnisse: IdBezeichnungDto[];
    verwendungen: IdBezeichnungDto[];
}

export interface KundenRisikoDto {
    id: string;
    bezeichnung: string;
    strasse: string;
    plz: string;
    ort: string;
}

export interface RisikoVertragSpartenRequestDto extends ListRequest, ISavableUserFilter<RisikoVertragSpartenRequestDto> {
    pageIndex: number;
    order: SortDto<string>;
    spalten: RisikoVertragSpartenListeSpalteDto[];
    searchTerm: string;
}

export interface KundenSeiteAnhangDto {
    id: number;
    /**
     * The text to search for in the HTML and replace with the link
     */
    placeholder: string;
    /**
     * The new link text to use instead of the placeholder.
     */
    linkText: string;
}

export interface KundenSeiteDownloadDto {
    /**
     * ID of the AktenAnhang
     */
    id: number;
    /**
     * The token to download that Anhang.
     */
    token: string;
}

export interface KundenSeiteDto {
    title: string;
    content: string;
    anhaenge: KundenSeiteAnhangDto[];
}

export interface KundenSeiteVariablenDto {
    beschreibung: string;
    platzhalter: string;
}

export interface BadgeDto {
    label: string;
    variant: string;
}

export interface CellKennzeichenDto extends CellTextOrKennzeichenDto {
    kennzeichen: KfzKennzeichenDto;
}

export interface CellTextDto extends CellTextOrKennzeichenDto {
    text: string;
}

export interface CellTextOrKennzeichenDto {
}

export interface ColumnHeaderDto {
    columnId: string;
    label: string;
    sortable: boolean;
    type: ColumnTypeDto;
    visible: boolean;
    groupId: string;
}

export interface ColumnValueDto<T> {
    columnId: string;
    value: T;
}

export interface DetailsDto<T> {
    fields: FieldDto<any>[];
    id: T;
}

export interface FieldDto<T> {
    header: ColumnHeaderDto;
    value: ColumnValueDto<T>;
}

export interface ListDto {
    columnHeaders: ColumnHeaderDto[];
    rows: RowDto<any>[];
    paging: PagingDto;
}

export interface PagingDto {
    pageSize: number;
    pageIndex: number;
    length: number;
}

export interface RowDto<T> {
    columns: ColumnValueDto<any>[];
    entryId: T;
    mode: RowModeDto;
}

export interface KundenSeiteLinkDto {
    linkText: string;
    id: number;
}

export interface LoginInfoDto {
    user: UserReferenceDto;
    menu: MenuInfoDto;
    bundles: BundleDto[];
    developFeatures: boolean;
    kundenFeatures: KundenFeatureName[];
}

export interface MenuInfoDto {
    kundenseiten: KundenSeiteLinkDto[];
    visibleItems: EMenuItem[];
}

export interface PosteingangBoxFilterDto extends PosteingangEntryFilterDto, ISavableUserFilter<PosteingangBoxFilterDto> {
    key: "b";
}

export interface PosteingangEintragDto {
    id: number;
    kommentar: string;
    timestamp: Date;
    akteeintragId: number;
    vonName: string;
    anName: string;
    betreff: string;
    zuordnungen: string[];
    aktenAnhaenge: AktenAnhangDto[];
}

export interface PosteingangEintragListDto {
    posteingangEintragList: PosteingangEintragDto[];
    posteingangAlleEingehend: AktePosteingangDto[];
    posteingangAlleGesendet: AktePosteingangDto[];
    posteingangTerminList: TerminPosteingangDto[];
}

export interface PosteingangEntryFilterDto {
    key: "bad" | "b" | "s" | "v" | "ser" | "sad" | "vad";
    columns: string[];
}

export interface PosteingangEntryFiltersDto {
    schaden: PosteingangSchadenFilterDto;
    vertrag: PosteingangVertragFilterDto;
    schadenFelder: SchadenTenantFeldListDto;
    vertragFelder: VertragTenantFeldListDto;
    box: PosteingangBoxFilterDto;
    boxFelder: BoxTenantFeldListDto;
    schadenereignisFelder: SchadenEreignisFeldListDto;
}

export interface PosteingangSchadenFilterDto extends PosteingangEntryFilterDto, ISavableUserFilter<PosteingangSchadenFilterDto> {
    key: "s";
}

export interface PosteingangVertragFilterDto extends PosteingangEntryFilterDto, ISavableUserFilter<PosteingangVertragFilterDto> {
    key: "v";
}

/**
 * Specialized list containing a collection of associations between a group identifier and an i18n name
 */
export interface GroupedListDto extends ListDto {
    columnHeaderGroups: { [index: string]: string };
}

export interface IndividuelleSchadenmeldungDto {
    tenantId: number;
    hiddenFieldkeys: string[];
    schadenTypId: string;
}

export interface ReserveUebersichtStammdatenDto {
    betriebsstaetten: IdBezeichnungDto[];
    ous: IdBezeichnungWithParentDto[];
}

export interface SchadenAnsprechpartnerDto {
    id: number;
    vorname: string;
    name: string;
    telefon: string;
}

export interface SchadenAufwandDto {
    zahlung: number;
    selbstbeteiligung: number;
    reserve: number;
    aufwand: number;
}

export interface SchadenDto extends SchadenListeEintragDto {
    reportedBy: string;
    ansprechpartner: SchadenAnsprechpartnerDto;
    versicherer: VersichererDto;
}

export interface SchadenEreignisDetailsDto {
    ereignisId: number;
    schaeden: DetailsDto<number>[];
    ereignisLfdNr: string;
    schadenTypen: string[];
    schadenDatumList: string[];
    hasUnberechtigteSchaeden: boolean;
    diagramDto: DiagramDto;
}

export interface SchadenEreignisDetailsPdfRequestDto {
    ereignisId: number;
    schadenFields: string[];
}

export interface SchadenEreignisDetailsRequestDto {
    ereignisId: number;
}

export interface SchadenEreignisFeldListDto extends PosteingangEntryFilterDto, ISavableUserFilter<SchadenEreignisFeldListDto> {
    key: "ser";
    fieldNames: SchadenListeSpalteDto[];
}

export interface SchadenEreignisListeRequestDto extends ListRequest, ISavableUserFilter<SchadenEreignisListeRequestDto> {
    pageIndex: number;
    elementToShowId: number;
    order: SortDto<string>;
    search: string;
    spalten: SchadenEreignisSpalteDto[];
}

export interface SchadenKfzZusatzDto extends SchadenZusatzDto {
    type: "kfz";
    kennzeichen: string;
}

export interface SchadenKurzdarstellungDataDto extends SchadenKurzdarstellungEdit {
    kurzdarstellung: string;
}

export interface SchadenListeEintragDto {
    id: number;
    schadenNummer: number;
    schadenDatum: string;
    meldeDatum: string;
    schadenTyp: IdBezeichnungDto;
    schadenArt: SchadenArtDto;
    zusatz: SchadenZusatzDto;
    schadenAufwand: SchadenAufwandDto;
    status: SchadenStatusDto;
    expiredSchadenVerfahrensstaende: SchadenVerfahrensstandDto[];
    activeSchadenVerfahrensstaende: SchadenVerfahrensstandDto[];
}

export interface SchadenListePermissionsDto {
    /**
     * The user can create claim reports to be sent to ecclesia.
     */
    canMelden: boolean;
    /**
     * The user can create new claims ('Eigenverwaltet')
     */
    canErstellen: boolean;
}

export interface SchadenListeRequestDto extends ListRequest, ISavableUserFilter<SchadenListeRequestDto> {
    pageIndex: number;
    elementToShowId: number;
    order: SortDto<string>;
    typ: SchadenStatusTypDto[];
    search: string;
    schadenDatum: LocalDateFilterDto;
    spalten: string[];
    verwaltung: Verwaltung[];
    eigenverwaltung: boolean;
}

export interface SchadenRisikoZusatzDto extends SchadenZusatzDto {
    type: "risikozusatz";
    risikozusatz: string;
}

export interface SchadenStatusDto {
    status: "geschlossen" | "offen";
}

export interface SchadenStatusGeschlossenDto extends SchadenStatusDto {
    status: "geschlossen";
    schliessgrund: IdBezeichnungDto;
}

export interface SchadenStatusOffenDto extends SchadenStatusDto {
    status: "offen";
}

export interface SchadenTenantFeldListDto extends PosteingangEntryFilterDto, ISavableUserFilter<SchadenTenantFeldListDto> {
    key: "sad";
}

export interface SchadenVerfahrensstandDto {
    id: string;
    gueltigVon: string;
    gueltigBis: string;
    bezeichnung: string;
    gesperrt: boolean;
}

export interface SchadenZusatzDto {
    type: "kfz" | "risikozusatz";
}

export interface VersichererDto {
    name: string;
    logoId: number;
}

export interface ZahlungReserveDto {
    tenantFelder: ObjectNode;
}

export interface DrgDto extends IDrg {
}

export interface SchadenEigenverwaltungAdresseDto extends SchadenEigenverwaltungAdresse {
}

export interface SchadenEigenverwaltungDataDto extends EigenverwaltungDataDto, SchadenEigenverwaltetDaten {
    verfahrensstaendeGroup: SchadenEigenverwaltungVerfahrensstaendeDto;
    /**
     * KFZ extra data. Never null, but will be ignored if not an KFZ schaden typ.
     */
    kfz: SchadenEigenverwaltungKfzDto;
    /**
     * Heilwesen extra data. Never null, but will be ignored if not an Heilwesen schaden typ.
     */
    heilwesen: SchadenEigenverwaltungHeilwesenDto;
    adresse: SchadenEigenverwaltungAdresseDto;
}

export interface SchadenEigenverwaltungFahrerDto extends SchadenEigenverwaltungFahrer {
}

export interface SchadenEigenverwaltungFahrzeugDto extends SchadenEigenverwaltungFahrzeug {
}

export interface SchadenEigenverwaltungFahrzeugtypDatenDto {
    typen: IdBezeichnungDto[];
}

export interface SchadenEigenverwaltungFahrzeugtypRequestDto {
    herstellerId: string;
}

export interface SchadenEigenverwaltungHeilwesenDto extends SchadenEigenverwaltungHeilwesen {
    patient: SchadenEigenverwaltungHeilwesenPatientDto;
    drg: DrgDto;
}

export interface SchadenEigenverwaltungHeilwesenPatientDto extends SchadenEigenverwaltungHeilwesenPatient {
}

export interface SchadenEigenverwaltungKfzDto extends SchadenEigenverwaltungKfz {
    kennzeichen: KfzKennzeichenDto;
    fahrer: SchadenEigenverwaltungFahrerDto;
    fahrzeug: SchadenEigenverwaltungFahrzeugDto;
}

export interface SchadenEigenverwaltungMeldungAntwortDto {
}

export interface SchadenEigenverwaltungResultDto {
    schadenNummer: number;
    schadenId: number;
}

export interface SchadenEigenverwaltungSchadenartDatenDto {
    ursachen1: IdBezeichnungDto[];
    ursachen2: IdBezeichnungDto[];
}

export interface SchadenEigenverwaltungSchadenartRequestDto {
    schadentypId: string;
    schadenartId: string;
    schadenId: number;
}

export interface SchadenEigenverwaltungSchadentypDatenDto {
    schadenarten: IdBezeichnungDto[];
}

export interface SchadenEigenverwaltungSchadentypDto extends IdBezeichnungWithGesperrtFlagDto {
    /**
     * Display the 'KFZ' extra details and allow user to fill them.
     */
    hasKfz: boolean;
    /**
     * Display the 'heilwesen' extra details and allow user to fill them.
     */
    hasHeilwesen: boolean;
}

export interface SchadenEigenverwaltungSchadentypRequestDto {
    schadentypId: string;
}

export interface SchadenEigenverwaltungStammdatenDto extends EigenverwaltungStammdatenDto {
    betriebsstaetten: IdBezeichnungDto[];
    ous: IdBezeichnungWithParentDto[];
    status: IdBezeichnungDto[];
    schliessgruende: IdBezeichnungWithGesperrtFlagDto[];
    verfahrensstaende: IdBezeichnungWithGesperrtFlagDto[];
    schadenTypen: SchadenEigenverwaltungSchadentypDto[];
    geschlecht: IdBezeichnungDto[];
    kfzSchadenort: IdBezeichnungWithGesperrtFlagDto[];
    verschulden: IdBezeichnungWithGesperrtFlagDto[];
    fuehrerschein: IdBezeichnungWithGesperrtFlagDto[];
    beschaeftigungsverhaeltnis: IdBezeichnungWithGesperrtFlagDto[];
    hersteller: IdBezeichnungDto[];
    mdcs: IdBezeichnungWithGesperrtFlagDto[];
    fachrichtungen: IdBezeichnungWithGesperrtFlagDto[];
    fachrichtungenAnaesthesie: IdBezeichnungWithGesperrtFlagDto[];
    behandlungsarten: IdBezeichnungWithGesperrtFlagDto[];
    dienstarten: IdBezeichnungWithGesperrtFlagDto[];
    heilwesenSchadenort: IdBezeichnungWithGesperrtFlagDto[];
    ursachen: IdBezeichnungWithGesperrtFlagDto[];
    verursacher: IdBezeichnungWithGesperrtFlagDto[];
    schadenbegriffe: IdBezeichnungWithGesperrtFlagDto[];
    haftung: IdBezeichnungWithGesperrtFlagDto[];
    land: IdBezeichnungDto[];
    schadenHoeheRange: IdBezeichnungDto[];
}

export interface SchadenEigenverwaltungVerfahrensstaendeDto extends SchadenEigenverwaltungVerfahrensstaende {
    verfahrensstaende: SchadenEigenverwaltungVerfahrensstandDto[];
}

export interface SchadenEigenverwaltungVerfahrensstandDto extends SchadenEigenverwaltungVerfahrensstand {
}

export interface SchadenVertragDto {
    id: number;
    berechtigt: boolean;
    aktenzeichen: string;
    bezeichnung: string;
    sparte: string;
    versicherer: string;
}

export interface ReserveCancelDto {
    reserveId: number;
}

export interface ReserveCreateDto {
    /**
     * The reason for creating the reserve change. Single line text
     */
    grund: string;
    /**
     * The amount to change the reserve. Negative: decrement, Positive: increment.
     */
    betrag: number;
    /**
     * Optional tenant fields for reserve
     */
    tenantFields: ObjectNode;
}

export interface ReserveListRequestDto extends ListRequest, ISavableUserFilter<ReserveListRequestDto> {
    spalten: string[];
    order: SortDto<string>;
    pageIndex: number;
}

export interface ReserveListSummaryDto {
    sum: number;
    canModifyReserve: boolean;
    canEditReserve: boolean;
}

export interface SchadenEreignisReserveListRequestDto extends ListRequest, ISavableUserFilter<SchadenEreignisReserveListRequestDto> {
    spalten: string[];
    order: SortDto<string>;
    pageIndex: number;
    searchTerm: string;
}

export interface ReserveStammdatenDto {
    tenantFields: AbstractFieldConfigDtoUnion[];
}

export interface SchadenEreignisZahlungenListRequestDto extends ListRequest, ISavableUserFilter<SchadenEreignisZahlungenListRequestDto> {
    spalten: string[];
    order: SortDto<string>;
    pageIndex: number;
    searchTerm: string;
}

export interface SchadenZahlungDto {
    id: number;
    betrag: number;
    verwendungszweck: string;
    kostenart: IdBezeichnungDto;
    vorgangsart: IdBezeichnungDto;
    zahlungsKategorie: IdBezeichnungDto;
    absender: SchadenZahlungKontoDto;
    empfaenger: SchadenZahlungKontoDto;
    storno: SchadenZahlungStornoDto;
    tenantFelder: ObjectNode;
}

export interface SchadenZahlungKontoDto {
    iban: string;
    kontoinhaber: string;
}

export interface SchadenZahlungStornoDto {
    storniertGrund: string;
    instant: string;
}

export interface ZahlungBankdatenDto {
    id: number;
    tenantId: number;
    iban: string;
    kontoinhaber: string;
}

export interface ZahlungCancelDto {
    zahlungId: number;
    grund: string;
}

export interface ZahlungCreateDto {
    absender: SchadenZahlungKontoDto;
    empfaenger: SchadenZahlungKontoDto;
    verwendungszweck: string;
    /**
     * Needs to be a valid key from the stammdaten
     */
    kostenart: string;
    /**
     * Needs to be a valid key from the stammdaten
     */
    zahlungsKategorie: string;
    /**
     * Betrag. Positive: Outgoing payment. Negative: Incomming payment.
     */
    betrag: number;
    tenantFields: ObjectNode;
}

export interface ZahlungEditDto {
    tenantFields: ObjectNode;
}

export interface ZahlungListRequestDto extends ListRequest, ISavableUserFilter<ZahlungListRequestDto> {
    spalten: string[];
    order: SortDto<string>;
    pageIndex: number;
}

export interface ZahlungListSummaryDto {
    sum: number;
    canAddZahlungseingang: boolean;
    canAddZahlungsausgang: boolean;
    canUseTenantWorkflow: boolean;
    canCancel: boolean;
    canEdit: boolean;
}

export interface ZahlungReserveEditDto {
    tenantFields: ObjectNode;
}

export interface ZahlungStammdatenDto {
    /**
     * Kostenarten to select when creating a Zahlung
     */
    kostenarten: IdBezeichnungDto[];
    /**
     * Kategorien to select when creating a Zahlung
     */
    zahlungsKategorien: IdBezeichnungDto[];
    /**
     * Current reserve for the Schaden
     */
    remainingReserve: number;
    /**
     * Available bank details
     */
    bankdetails: ZahlungBankdatenDto[];
    /**
     * Tenant fields
     */
    tenantFields: AbstractFieldConfigDtoUnion[];
}

export interface ZahlungsuebersichtRequestDto extends ListRequest, ISavableUserFilter<ZahlungsuebersichtRequestDto> {
    spalten: string[];
    order: SortDto<string>;
    pageIndex: number;
    searchTerm: string;
    minErfassungszeitpunkt: string;
}

export interface SchadenEreignisDto extends ISchadenEreignis {
}

export interface SchadenEreignisEditDto extends SchadenEreignisDto, ISchadenEreignisEdit {
}

export interface SchadenEreignisPermissionsDto {
    /**
     * Returns true if the user has the AV-Role, false otherwise.
     */
    canCreate: boolean;
}

export interface SchadenEreignisResultDto {
    id: number;
    lfdNr: string;
}

export interface AbstractSchadenmeldungFormularTargetDto {
    type: "kunde" | "lzr" | "ou";
}

/**
 * A akten ahnang for the schadenmeldungContains the download tokensShould not be used by logged in users, since they can generate tokens on the fly.
 */
export interface AktenAnhangDownloadDto extends AktenAnhangDto {
    token: string;
}

export interface DezentraleSchadenmeldungDto {
    zuordnungId: number;
    zuordnungTyp: SchadenDezentralTyp;
}

export interface FirmaDetailsDto {
    firmaId: string;
    datenschutzLink: string;
    kundenErstinformationLink: string;
    version: number;
}

export interface FirmaDto {
    firmaId: string;
    firmaName: string;
    firmaDetailsDto: FirmaDetailsDto;
    firmaHomepage: string;
}

export interface KundeDto {
    id: number;
    bezeichnung: string;
    kundennummer: string;
}

export interface SchadenArtDto extends ComboboxOptionDto {
    schadenTypId: string;
}

export interface SchadenGruppeDto extends IdBezeichnungDto {
    options: IdBezeichnungWithRefDto[];
    gruppe: SchadenGruppe;
}

export interface SchadenMeldungAllgemeineDatenDto {
    firma: FirmaDto;
    /**
     * The container for mixed (SchadenTyp and SchadenArt) entities
     */
    schadenGruppen: SchadenGruppeDto[];
    allgemeineFragen: AbstractFieldConfigDtoUnion[];
    /**
     * The receiver of the schadenmeldung (kunde | lzr | ou)
     */
    target: AbstractSchadenmeldungFormularTargetDtoUnion;
}

export interface SchadenTypDto extends ComboboxOptionDto {
}

export interface SchadenmeldungAntwortDto {
    schadenId: number;
    schadennummer: number;
    token: string;
}

export interface SchadenmeldungErgebnisDto {
    files: AktenAnhangDownloadDto[];
    schadenNummer: number;
}

export interface SchadenmeldungFormularDto {
    spezielleFragenConfig: AbstractFieldConfigDtoUnion[];
    versicherungsnehmerFragenConfig: AbstractFieldConfigDtoUnion[];
    beschreibungFragenConfig: AbstractFieldConfigDtoUnion[];
    bankverbindungFragenConfig: AbstractFieldConfigDtoUnion[];
    ansprechpartnerFragenConfig: AbstractFieldConfigDtoUnion[];
    datenschutzFragenConfig: AbstractFieldConfigDtoUnion[];
    customFragenConfig: AbstractFieldConfigDtoUnion[];
    target: AbstractSchadenmeldungFormularTargetDtoUnion;
}

export interface SchadenmeldungFormularTargetKundenDto extends AbstractSchadenmeldungFormularTargetDto {
    type: "kunde";
    kunden: KundeDto[];
}

export interface SchadenmeldungFormularTargetLzrDto extends AbstractSchadenmeldungFormularTargetDto {
    type: "lzr";
    lzrs: IdBezeichnungDto[];
}

export interface SchadenmeldungFormularTargetOuDto extends AbstractSchadenmeldungFormularTargetDto {
    type: "ou";
    ous: IdBezeichnungWithParentDto[];
}

export interface AbstractFieldConfigDto {
    inputType: "adresse" | "input_big_decimal" | "checkbox" | "combobox" | "date" | "headline" | "hinweis" | "input_area" | "input" | "kennzeichen" | "list" | "input_numeric" | "time";
    label: string;
    /**
     * If this is set, the user should be forced to enter a value into this field.
     */
    required: boolean;
    showOnPdf: boolean;
    key: string;
    visibilityConditions: AbstractFieldVisibilityConditionUnion[];
    title?: string;
}

export interface AddressVisiblePartsDto {
    name: boolean;
    strasse: boolean;
    plz: boolean;
    ort: boolean;
    telefon: boolean;
    email: boolean;
    land: boolean;
}

export interface ComboboxOptionDto extends IdBezeichnungDto {
    /**
     * A list of exclusions. Once this option is added, none of the exclusions may be added any more. The server is responsible for making the exclusions tow-way, so client does only have to check one way.
     */
    exclusions: string[];
}

export interface FieldConfigAdresseDto extends AbstractFieldConfigDto {
    inputType: "adresse";
    multiple: boolean;
    visibleParts: AddressVisiblePartsDto;
}

export interface FieldConfigBigDecimalInputDto extends AbstractFieldConfigDto {
    inputType: "input_big_decimal";
    icon: string;
}

export interface FieldConfigCheckboxDto extends AbstractFieldConfigDto {
    inputType: "checkbox";
    defaultValue: boolean;
}

export interface FieldConfigComboboxDto extends AbstractFieldConfigDto {
    inputType: "combobox";
    values: ComboboxOptionDto[];
    multiple: boolean;
    dynamic: boolean;
}

export interface FieldConfigDateDto extends AbstractFieldConfigDto {
    inputType: "date";
    /**
     * The minimum date to enter. Inclusive (this date may be entered)
     */
    minDate: string;
    /**
     * The maximum date to enter. Inclusive (this date may be entered)
     */
    maxDate: string;
}

export interface FieldConfigHeadlineDto extends AbstractFieldConfigDto {
    inputType: "headline";
}

export interface FieldConfigHinweisDto extends AbstractFieldConfigDto {
    inputType: "hinweis";
}

export interface FieldConfigInputAreaDto extends AbstractFieldConfigDto {
    inputType: "input_area";
    icon: string;
    maxLength: number;
}

export interface FieldConfigInputDto extends AbstractFieldConfigDto {
    inputType: "input";
    maxLength: number;
    icon: string;
}

export interface FieldConfigKennzeichenDto extends AbstractFieldConfigDto {
    inputType: "kennzeichen";
}

export interface FieldConfigListDto extends AbstractFieldConfigDto {
    inputType: "list";
    values: IdBezeichnungDto[];
    multiple: boolean;
    dynamic: boolean;
}

export interface FieldConfigNumericInputDto extends AbstractFieldConfigDto {
    inputType: "input_numeric";
    icon: string;
}

export interface FieldConfigTimeDto extends AbstractFieldConfigDto {
    inputType: "time";
}

export interface FieldVisibilityConditionAlwaysDto extends AbstractFieldVisibilityCondition {
    conditionType: "always";
}

export interface FieldVisibilityConditionHasValueDto extends AbstractFieldVisibilityCondition {
    conditionType: "hasValue";
    otherFieldKey: string;
    valueThatKeyNeeds: string[];
}

export interface FieldVisibilityConditionNotDto extends AbstractFieldVisibilityCondition {
    conditionType: "not";
    condition: AbstractFieldVisibilityConditionUnion;
}

export interface FieldVisibilityConditionSchadenartDto extends AbstractFieldVisibilityCondition {
    conditionType: "schadenart";
    typ: string;
    arten: string[];
}

export interface FieldVisibilityConditionSchadentypDto extends AbstractFieldVisibilityCondition {
    conditionType: "schadentyp";
    typen: string[];
}

export interface AbstractSchadenmeldungTargetDto {
    type: "kunde" | "lzr" | "ou";
}

export interface AllgemeineDatenDto {
    schadenTyp: string;
    schadenHoehe: string;
    kundenNummer: string;
    target: AbstractSchadenmeldungTargetDtoUnion;
    schadenDatum: string;
    /**
     * The one Versicherungsnehmer. Optional (then the list is empty or null). Modelled like this due to legacy reasons
     */
    versicherungsNehmer: SchadenmeldungAdresseDto[];
}

export interface AnsprechpartnerDatenDto extends IAnsprechpartnerDaten {
    ansprechpartner: SchadenmeldungAdresseDto[];
}

export interface BankdatenDto extends IBankdaten {
}

export interface BeschreibungDto extends IBeschreibung {
    /**
     * Beschreibung als HTML-Text
     */
    beschreibung: string;
}

export interface DatenschutzErklaerungDto extends IDatenschutzErklaerung {
}

export interface KfzKennzeichenDto extends IKfzKennzeichen {
}

export interface SchadenmeldungAdresseDto extends IAdresse {
}

export interface SchadenmeldungDto {
    allgemeineDaten: AllgemeineDatenDto;
    beschreibung: BeschreibungDto;
    ansprechpartnerDaten: AnsprechpartnerDatenDto;
    spezifischeDaten: ObjectNode;
    bankdaten: BankdatenDto;
    datenschutzSeite: DatenschutzErklaerungDto;
    captchaSolutionData: CaptchaSolutionDto;
    tenantFields: ObjectNode;
    dezentraleSchadenmeldungUuId: string;
}

export interface SchadenmeldungTargetKundeDto extends AbstractSchadenmeldungTargetDto {
    type: "kunde";
    kundeId: number;
}

export interface SchadenmeldungTargetLzrDto extends AbstractSchadenmeldungTargetDto {
    type: "lzr";
    lzrId: number;
}

export interface SchadenmeldungTargetOuDto extends AbstractSchadenmeldungTargetDto {
    type: "ou";
    ouId: number;
}

export interface SchadenTypenStatistikRequestDto extends ListRequest, ISavableUserFilter<SchadenTypenStatistikRequestDto> {
    order: SortDto<string>;
    spalten: SchadenTypStatistikSpalteDto[];
    pageIndex: number;
    saveFilter: boolean;
}

/**
 * These are the display privileges for claims (Schaden), contracts (Vertrag) and customer's digital folder (Kundenakte).
 */
export interface StatistikRechteDto {
    canDisplaySchaden: boolean;
    canDisplayVertragKunde: boolean;
    canSaveStatistikAuswertung: boolean;
    has19066Exports: boolean;
}

/**
 * Available criteria for a statistik
 */
export interface AvailableStatistikCriteriaDto {
    /**
     * available filters
     */
    filters: FilterDto[];
    filterIdsByDomain: { [P in FilterDomainDto]?: string[] };
    /**
     * available columns (for table)
     */
    columns: StatistikColumnDto[];
    /**
     * available diagram values (for the y-axis)
     */
    diagramValues: DiagramValueDto[];
    /**
     * available diagram groupings (for the x-axis)
     */
    diagramGroupings: DiagramGroupingDto[];
}

/**
 * date range filter
 */
export interface DateRangeFilterOptionDto extends RangeFilterOptionDto<string> {
    /**
     * min value for the range
     */
    minValue: string;
    /**
     * max value for the range
     */
    maxValue: string;
}

/**
 * The data for a bar segment. See EC-2749
 */
export interface DiagramDatasetDto {
    /**
     * The label for a bar segment
     */
    label: string;
    /**
     * A collection of associations between a column id and its value for a bar segment. Each bar segment represents a group which values are returned by this method
     */
    data: DiagramDatasetValueDto[];
}

/**
 * An association between an object identifier and its value within a collection of values
 */
export interface DiagramDatasetValueDto {
    key: string;
    value: number;
}

/**
 * diagram data
 */
export interface DiagramDto {
    /**
     * type of diagram
     */
    type: StatistikColumnTypeDto;
    /**
     * requested x-axis diagramId
     */
    diagramId: string;
    /**
     * x-axis data
     */
    groups: DiagramGroupingWithValueDto[];
    /**
     * y-axis data
     */
    datasets: DiagramDatasetDto[];
}

/**
 * x-axis grouping
 */
export interface DiagramGroupingDto {
    /**
     * id
     */
    groupId: string;
    /**
     * localized label
     */
    label: string;
}

/**
 * x-axis grouping value
 */
export interface DiagramGroupingWithValueDto extends DiagramGroupingDto {
    /**
     * The row id associated with value
     */
    rowId: string;
    /**
     * x-axis grouping value
     */
    value: string;
}

/**
 * y-axis value
 */
export interface DiagramValueDto {
    /**
     * id
     */
    diagramId: string;
    /**
     * localized labels
     */
    labels: string[];
    /**
     * an optional label for the group of values as an alternative to the labels of the columns
     */
    valueGroupLabel: string;
    /**
     * type of value
     */
    valueType: StatistikColumnTypeDto;
    type: StatistikColumnTypeDto;
}

/**
 * A filter
 */
export interface FilterDto {
    /**
     * type of the filter
     */
    filterType: FilterTypeDto;
    /**
     * internal ID of the filter
     */
    filterId: string;
    /**
     * localized label of the filter
     */
    label: string;
}

export interface FilterOptionDto {
    /**
     * type of the filter
     */
    filterType: FilterTypeDto;
}

/**
 * Filter options request
 */
export interface FilterOptionsRequestDto {
    /**
     * TODO currently set filters
     */
    setFilters: string[];
    /**
     * requested filter id
     */
    requestedFilterId: string;
}

/**
 * a filter with options
 */
export interface FilterWithOptionsDto extends FilterDto {
    /**
     * list of filter options
     */
    filterOptions: FilterOptionDto[];
}

export interface ListSumsDto {
    sums: SumForColumnDto[];
}

/**
 * number range filter
 */
export interface NumberRangeFilterOptionDto extends RangeFilterOptionDto<number> {
    /**
     * min value for the range
     */
    minValue: number;
    /**
     * max value for the range
     */
    maxValue: number;
}

/**
 * range filter
 */
export interface RangeFilterOptionDto<T> extends FilterOptionDto {
    /**
     * min value for the range
     */
    minValue: T;
    /**
     * max value for the range
     */
    maxValue: T;
}

/**
 * option of a select filter
 */
export interface SelectFilterOptionDto extends FilterOptionDto {
    /**
     * TODO count of matches
     */
    matches: number;
    /**
     * localized label for the option
     */
    label: string;
    /**
     * technical value or id of the option
     */
    value: string;
}

/**
 * option of a select filter with a parent
 */
export interface SelectFilterOptionWithParentDto extends SelectFilterOptionDto {
    parent: SelectFilterOptionDto;
}

/**
 * table column
 */
export interface StatistikColumnDto {
    /**
     * type of column
     */
    type: StatistikColumnTypeDto;
    /**
     * localized label
     */
    label: string;
    /**
     * column id
     */
    columnId: string;
}

/**
 * definition for a statistik
 */
export interface StatistikDefinitionDto extends ListRequest {
    /**
     * type of statistik
     */
    type: StatistikDefinitionTypeDto;
    /**
     * selected filters with options
     */
    filters: FilterWithOptionsDto[];
    /**
     * selected column ids
     */
    columns: string[];
    /**
     * selected diagram value id
     */
    diagram: string;
    /**
     * selected diagram group id
     */
    group: string;
    /**
     * order column for the table
     */
    order: SortDto<string>;
}

/**
 * Statistik result wrapper, contains diagram and table
 */
export interface StatistikDto {
    /**
     * the diagram result data
     */
    diagram: DiagramDto;
    /**
     * the list result data
     */
    list: ListDto;
}

/**
 * request for a schaden list
 */
export interface StatistikSchadenListRequestDto extends ListRequest, ISavableUserFilter<StatistikSchadenListRequestDto> {
    /**
     * selected filters with options
     */
    filters: FilterWithOptionsDto[];
    /**
     * spalten to return
     */
    spalten: string[];
    /**
     * order column for the table
     */
    order: SortDto<string>;
    /**
     * page index for the list
     */
    pageIndex: number;
}

export interface SumForColumnDto {
    columnId: string;
    sum: number;
}

/**
 * The request to create or update a statistics evaluation object
 */
export interface StatistikAuswertungDto {
    id: number;
    name: string;
    lastEditedByName: string;
    type: StatistikAuswertungAccessType;
    configuration: string;
    defaultView: StatistikAuswertungDefaultView;
}

export interface StatistikAuswertungListDto {
    /**
     * The type of the view.
     */
    view: StatistikAuswertungAccessType;
    /**
     * A collection of statistics evaluation entries
     */
    entries: StatistikAuswertungListEntryDto[];
    /**
     * Answers if the evaluation statistics can be viewed
     */
    canView: boolean;
    /**
     * Answers if the evaluation statistics can be updated
     */
    canEdit: boolean;
    /**
     * Answers if the evaluation statistics can be deleted
     */
    canDelete: boolean;
}

/**
 * Statistics auswertung list entry short info
 */
export interface StatistikAuswertungListEntryDto {
    id: number;
    /**
     * The name of the statistics auswertung's last editor
     */
    lastEditedByName: string;
    /**
     * The auswertung name provided by the user
     */
    name: string;
    /**
     * The type of the list entry.
     */
    accessType: StatistikAuswertungAccessType;
    createdOn: string;
    updatedOn: string;
    /**
     * The view loaded / displayed initially
     */
    defaultView: StatistikAuswertungDefaultView;
}

export interface StatistikAuswertungListsDto {
    /**
     * All types of statistics evalustions
     */
    views: StatistikAuswertungListDto[];
}

/**
 * definition for a chainladder
 */
export interface ChainladderStatistikDto {
    /**
     * definition for a statistik
     */
    statistikSchadenListRequestDto: StatistikSchadenListRequestDto;
    /**
     * selected chainladder type
     */
    chainladderType: ChainLadderDataType;
}

export interface TenantFeldDto {
    typ: string;
    label: string;
    kategorie: string;
}

export interface TenantListeDto {
    tenants: TenantListenEintragDto[];
}

export interface TenantListeFeldDto {
    felder: TenantFeldDto[];
}

export interface TenantListenEintragDto {
    id: number;
    partnerNr: number;
    name: string;
    status: string;
}

export interface AbstractTerminCreateDto {
    grundId: string;
    grund: string;
    todoList: TerminTodoDto[];
}

export interface AbstractTerminDto {
    id: number;
    version: number;
    grundDomains: TerminGrundDomain[];
    grundId: string;
    grund: string;
    todoList: TerminTodoDto[];
}

export interface AbstractTerminEditDto {
    id: number;
    version: number;
    grundId: string;
    grund: string;
    todoList: TerminTodoDto[];
}

export interface AbstractTerminListePagedRequestDto extends ListRequest {
    pageIndex: number;
    elementToShowId?: number;
    grundId?: string;
    terminDatum: DateFilterDto;
    searchTerm: string;
    spalten: string[];
    order: SortDto<string>;
    erledigt: boolean;
}

export interface DomainObjectTerminListePagedRequestDto extends AbstractTerminListePagedRequestDto {
    domainObjectId: DomainObjectIdDto;
}

export interface PosteingangTerminListePagedRequestDto extends AbstractTerminListePagedRequestDto, ISavableUserFilter<PosteingangTerminListePagedRequestDto> {
    userIds: number[];
}

export interface TerminCreateDto extends AbstractTerminCreateDto {
    benutzer: number;
    zeitpunkt: Date;
    vertragId?: number;
    schadenId?: number;
    aktenEintragId?: number;
    akteKundeId?: number;
}

export interface TerminDetailsDto extends TerminListenEintragDto {
    vertragLfdNr: string;
    policenNr: string;
    schadenLzr: string;
    schadenBetriebsstaette: string;
    kundenNr: string;
    editable: boolean;
}

export interface TerminEditDto extends AbstractTerminEditDto {
    benutzer: number;
    zeitpunkt: Date;
    erledigt: boolean;
}

export interface TerminFormDataDto {
    possibleUsers: MinimalExternalUserDto[];
    enableTodoList: boolean;
    enableFreitextGrund: boolean;
    enableVorlagen: boolean;
    canEditVorlagen: boolean;
}

export interface TerminGrundDto {
    id: string;
    domain: TerminGrundDomain;
    bezeichnung: string;
}

/**
 * Specialized list containing a collection of associations between a group identifier and an i18n name
 */
export interface TerminListDto extends GroupedListDto {
    canCreate: boolean;
    canCreateVorlage: boolean;
    canToggleView: boolean;
}

export interface TerminListenEintragDto extends AbstractTerminDto {
    benutzer: MinimalExternalUserDto;
    zeitpunkt: Date;
    aktenListenEintragId: number;
    aktenListenEintrag: AktenListenEintragDto;
    vertragId: number;
    schadenId: number;
    boxRef: BoxRefDto;
    erledigt: boolean;
    erledigtVon: MinimalExternalUserDto;
    enableTodoList: boolean;
    enableFreitextGrund: boolean;
    enableVorlagen: boolean;
    canEditVorlagen: boolean;
    vertragInfo: string;
    schadenInfo: string;
    usersWithSamePN: MinimalExternalUserDto[];
}

export interface TerminRefDto {
    terminRefTypDto: TerminRefTypDto;
    id: number;
}

export interface TerminReferencesDto {
    vertragId?: number;
    schadenId?: number;
    aktenEintragId?: number;
    akteKundeId?: number;
}

export interface TerminTodoDto {
    id: number;
    version: number;
    text: string;
    erledigt: boolean;
}

export interface TerminVorlageCreateDto extends AbstractTerminCreateDto {
    bezeichnung: string;
    offset: number;
    offsetType: OffsetType;
}

export interface TerminVorlageDto extends AbstractTerminDto {
    bezeichnung: string;
    offset: number;
    offsetType: OffsetType;
    editable: boolean;
}

export interface TerminVorlageEditDto extends AbstractTerminEditDto {
    bezeichnung: string;
    offset: number;
    offsetType: OffsetType;
}

export interface TermineLastUpdatedDto {
    id: number;
    label: string;
    typ: TerminRefDto;
    isErledigt: boolean;
}

export interface UserPreferencesDto {
    dailySummaryMail: boolean;
    mailOnNewFile: boolean;
    terminListView: boolean;
    startPage: string;
    standardBriefbogen: string;
    vertragTabsOrder: string[];
    schadenTabsOrder: string[];
    schadenereignisTabsOrder: string[];
    boxTabsOrder: string[];
    posteingangTabsOrder: string[];
    statistikTabsOrder: string[];
    benutzereinstellungenTabsOrder: string[];
    risikoTabsOrder: string[];
}

export interface UserPreferencesMenuDto {
    userPreferencesDto: UserPreferencesDto;
    useTerminList: boolean;
    avUser: boolean;
    avKunde: boolean;
    isAvKunde: boolean;
    isAvUser: boolean;
    getUseTerminList: boolean;
}

export interface DetailsWithAnsprechpartnerDto<T> extends DetailsDto<T> {
    ansprechpartnerDaten: AnsprechpartnerDto[];
    canSeeAnsprechpartner: boolean;
    mayContactAnsprechpartner: boolean;
    canAnonymize: boolean;
}

export interface KfzAnmeldenKundeDto {
    id: number;
    kundennummer: string;
    bezeichnung: string;
    bevorzugterVersicherer: string;
    kfzStueckpreisTarif: boolean;
    industriekunde: boolean;
}

export interface KfzAnmeldenRequestDto extends IAnmeldeDatenBasis {
    kundeId: number;
    kennzeichen: KfzKennzeichenDto;
}

export interface KfzAnmeldenResultDto {
    kundeId: number;
    eintragId: number;
}

export interface KfzAnmeldenStammdatenDto {
    kunden: KfzAnmeldenKundeDto[];
    leasinggeber: KfzLeasinggeberDto[];
    kaskoTypen: IdBezeichnungDto[];
    deckungssummeVollkasko: IdBezeichnungDto[];
    deckungssummeTeilkasko: IdBezeichnungDto[];
    deckungssummeInsassen: IdBezeichnungDto[];
    absellplaetze: IdBezeichnungDto[];
    wohneigentum: IdBezeichnungDto[];
    zahlungsweisen: IdBezeichnungDto[];
    nutzungen: IdBezeichnungDto[];
    berufsgruppen: IdBezeichnungDto[];
    showTarifmerkmale: boolean;
}

export interface KfzFahrzeugwechselRequestDto {
    abmeldung: VertragKfzAbmeldungDto;
    anmeldung: KfzAnmeldenRequestDto;
}

export interface KfzFahrzeugwechselStammdatenDto {
    vertragId: number;
    versicherungsscheinNummer: string;
    kennzeichen: KfzKennzeichenDto;
    kundeId: number;
    stammdaten: KfzAnmeldenStammdatenDto;
}

export interface KfzLeasinggeberDto {
    id: number;
    schluessel: string;
    bezeichnung: string;
}

export interface PraemienAufteilungRequestDto extends ListRequest {
    pageIndex: number;
    order: SortDto<string>;
    spalten: PraemienListeSpalteDto[];
    searchTerm: string;
}

/**
 * API DTO for a 'Gruppierung'. A Gruppierung defines the type of a Vertrag, computed by it's most relevant Sparte. Two instances may be considered equal if they have the same gruppierungsTyp and the same schluessel.
 */
export interface VertragArtDto {
    /**
     * The Schlüssel of the Gruppierung. Won't be null.
     */
    schluessel: string;
    /**
     * The label in the UI
     */
    label: string;
}

export interface VertragDto extends DetailsWithAnsprechpartnerDto<number> {
    id: number;
    abmeldenErlaubt: boolean;
    fahrzeugwechselErlaubt: boolean;
    kundennummer: string;
    typ: string;
}

export interface VertragEigenverwaltungDataDto extends EigenverwaltungDataDto, VertragEigenverwaltetDaten {
}

export interface VertragEigenverwaltungResultDto {
    vertragId: number;
}

export interface VertragEigenverwaltungStammdatenDto extends EigenverwaltungStammdatenDto {
}

export interface VertragKfzAbmeldenDto {
    vsnr: string;
    kennzeichen: string;
}

export interface VertragKfzAbmeldenResultDto {
    /**
     * The ID of the Vertrag the Abmeldung was requested for.
     */
    vertragId: number;
    /**
     * The ID of the AktenEintrag that was generated for this abmeldung.
     */
    eintragId: number;
}

export interface VertragKfzAbmeldungDto extends IAbmeldungDaten, AkteEditWithFilesDto {
    abmeldeDatum: string;
    verkaufsDatum: string;
}

export interface VertragListeOptionsDto {
    vertragArten: VertragArtDto[];
    kunden: KundeDto[];
}

export interface VertragListeRechteDto {
    anmelden: boolean;
    evbAbruf: boolean;
    vertragsanliegen: boolean;
}

export interface VertragSchaedenDto {
    berechtigtCount: number;
    count: number;
    veAktenzeichen: string;
}

export interface VertragTenantFeldListDto extends PosteingangEntryFilterDto, ISavableUserFilter<VertragTenantFeldListDto> {
    key: "vad";
}

export interface VertragsListeRequestDto extends ListRequest, ISavableUserFilter<VertragsListeRequestDto> {
    /**
     * The filter text that the user is currently searching for.
     */
    search: string;
    pageIndex: number;
    elementToShowId: number;
    order: SortDto<string>;
    vertragStatus: VertragStatusDto;
    vertragArt: VertragArtDto[];
    spalten: string[];
    kunden: ListeKundeOptionsDto[];
}

export interface VersicherungsartDto {
    id: string;
    bezeichnung: string;
    sachversicherung: boolean;
}

export interface VertragsanliegenKundeDto extends KundeDto {
    vertraege: VertragsanliegenVertragDto[];
    ansprechpartner: AnsprechpartnerDto[];
}

export interface VertragsanliegenQuestionDto {
    id: string;
    question: string;
    mode: VertragsanliegenModeDto;
}

export interface VertragsanliegenRequestDto {
    kundeId: number;
    questionId: string;
    /**
     * Only used if question mode == VertragsanliegenModeDto.VERTRAG - ignored otherwise
     */
    vertragIds: number[];
    /**
     * Only used if question mode == VertragsanliegenModeDto.VERTRAG - ignored otherwise
     */
    ansprechpartnerId: number;
    /**
     * Only used if question mode == VertragsanliegenModeDto.VERSICHERUNGSSCHUTZ - ignored otherwise
     */
    versicherungsArt: string[];
    beschreibung: string;
    /**
     * Only used if question mode == VertragsanliegenModeDto.VERSICHERUNGSSCHUTZ - ignored otherwise
     */
    sachversicherung: VertragsanliegenSachversicherungExtraDto;
}

export interface VertragsanliegenResponseDto {
    aktenEintragId: number;
}

export interface VertragsanliegenSachversicherungExtraDto extends VertragsanliegenSachversicherungDaten {
}

export interface VertragsanliegenStammdatenDto {
    versicherungsarten: VersicherungsartDto[];
    kunden: VertragsanliegenKundeDto[];
    questions: VertragsanliegenQuestionDto[];
}

export interface VertragsanliegenVertragDto {
    id: number;
    sparte: string;
    aktenzeichen: string;
    beschreibung: string;
    ansprechpartner: AnsprechpartnerDto[];
    status: Status;
}

export interface ClassPathResource extends AbstractFileResolvingResource {
    path: string;
    classLoader: ClassLoader;
}

export interface Resource extends InputStreamSource {
    filename: string;
    open: boolean;
    file: File;
    readable: boolean;
    url: URL;
    description: string;
    uri: URI;
}

export interface ReserveUebersichtRequestDto extends ListRequest {
    from: string;
    until: string;
    lzrs: number[];
    ous: number[];
}

export interface SchadenEigenverwaltungPermissionsDto {
    canEditSchaden: boolean;
    canMeldenSchaden: boolean;
    /**
     * Answers true if the user is allowed to request a SEE (SchweigepflichtEnbindungsErklärung), false otherwise
     */
    canRequestSEE: boolean;
    canEditSchadenAllgemeineDaten: boolean;
    canEditSchadenSpezifischeDaten: boolean;
    canEditSchadenTenantDaten: boolean;
    canConfirmRemoveZahlungen: boolean;
}

export interface VertragEigenverwaltungPermissionsDto {
    /**
     * Edit is allowed if the current user can read/write a contract, the user has the AV role and there is at least one editable extension field available
     */
    canEditVertrag: boolean;
}

export interface AktenAnhang extends Anhang {
    aktenEintragId: number;
    anhangOrder: number;
    previewGenerationStatus: VorschauGenerationStatus;
    previewPageCount: number;
    regeneratable: boolean;
}

export interface IBriefAdresse {
    name1: string;
    name2: string;
    name3: string;
    fax: string;
    strasse: string;
    plz: string;
    ort: string;
    land: string;
    telefon: string;
    email: string;
}

export interface ObjectNode extends ContainerNode<ObjectNode>, Serializable {
}

export interface CustomFieldValueSupport {
    fieldValues: ObjectNode;
}

export interface AdresseInfo extends Comparable<any> {
    letterIndex: string;
    matchedCount: number;
}

export interface CustomFieldSupport {
    /**
     * A collection of field definitions / configurations
     */
    fieldConfigs: AbstractFieldConfigDtoUnion[];
}

export interface ListRequest {
}

export interface IAktenAnhangEdit {
}

export interface IAkteEditSeeAnforderung {
    patientVorname: string;
    patientNachname: string;
    nachricht: string;
}

export interface IAufgabe extends ILightAufgabe {
    text: string;
}

export interface IAufgabeTodo {
    bezeichnung: string;
    erledigt: boolean;
}

export interface ILightAufgabe {
    erinnerung: string;
    erledigt: boolean;
    version: number;
}

export interface IAkteEditBrief extends IAkteEdit {
    vonName: string;
    anName: string;
    betreff: string;
    gesendetAm: string;
}

export interface Locale extends Cloneable, Serializable {
}

export interface IBriefbogen {
    type: "briefbogen";
}

export interface IAkteEditBriefentwurf extends IAkteEditBrief {
}

export interface IAkteEditMail extends IAkteEdit {
    vonName: string;
    anName: string;
    datum: string;
    betreff: string;
    nachricht: string;
}

export interface IAkteEditMaklernachricht extends IAkteEdit {
    targetId: number;
    betreff: string;
    nachricht: string;
}

export interface IAkteEditNotiz extends IAkteEdit {
    von: string;
    datum: string;
    betreff: string;
    nachricht: string;
}

export interface IUserToForwardTo {
    message: string;
    id: number;
}

export interface CaptchaSolutionData {
    captchaRequestId: number;
    proposedSolution: string;
    token: string;
}

export interface AbweichenderHalterAdresse {
    strasse: string;
    plz: string;
    ort: string;
    name: string;
}

export interface SchadenKurzdarstellungEdit {
}

export interface IDrg {
    mdcId: string;
    basisDrg: string;
    drgResourcenverbrauch: string;
}

export interface SchadenEigenverwaltungAdresse {
    strasse: string;
    plz: string;
    ort: string;
    land: string;
}

export interface SchadenEigenverwaltetDaten {
    heilwesen: SchadenEigenverwaltungHeilwesen;
    kfz: SchadenEigenverwaltungKfz;
    verfahrensstaendeGroup: SchadenEigenverwaltungVerfahrensstaende;
    schadenHoehe: string;
    betriebsstaetteId: string;
    statusId: string;
    schliessgrundId: string;
    ursache1Id: string;
    ursache2Id: string;
    risikoZusatzInfo: string;
    kundeVerwaltet: boolean;
    schadenDatum: string;
    tenantFelder: ObjectNode;
    schadenartId: string;
    ouId: string;
    meldeDatum: string;
    kurzdarstellung: string;
    vrSchadennummer: string;
    adresse: SchadenEigenverwaltungAdresse;
    schadentypId: string;
}

export interface SchadenEigenverwaltungFahrer {
    geschlechtId: string;
    geburtsdatum: string;
    fuehrerscheinId: string;
    fuehrerscheinAusstellung: string;
    beschaeftigungsverhaeltnisId: string;
    name: string;
}

export interface SchadenEigenverwaltungFahrzeug {
    typId: string;
    erstzulassung: string;
    herstellerId: string;
}

export interface SchadenEigenverwaltungHeilwesen {
    patient: SchadenEigenverwaltungHeilwesenPatient;
    fachrichtungId: string;
    fachrichtungAnaesthesieId: string;
    behandlungsartId: string;
    dienstartId: string;
    schadenortId: string;
    ursachenIds: string[];
    verursacherIds: string[];
    schadenbegriffeIds: string[];
    haftungId: string;
    drg: IDrg;
}

export interface SchadenEigenverwaltungHeilwesenPatient {
    sterbedatum: string;
    geschlechtId: string;
    geburtsdatum: string;
    patientenId: string;
    vorname: string;
    nachname: string;
}

export interface SchadenEigenverwaltungKfz {
    kennzeichen: IKfzKennzeichen;
    schadenortId: string;
    fahrzeug: SchadenEigenverwaltungFahrzeug;
    fahrer: SchadenEigenverwaltungFahrer;
    verschuldenId: string;
    maschinenkennzeichnung: string;
    uhrzeit: Date;
    kostenstelle: string;
}

export interface SchadenEigenverwaltungVerfahrensstaende {
    verfahrensstaende: SchadenEigenverwaltungVerfahrensstand[];
}

export interface SchadenEigenverwaltungVerfahrensstand {
    verfahrensstandId: string;
    gueltigVon: string;
    gueltigBis: string;
}

export interface ISchadenEreignis {
    schadenIds: number[];
    title: string;
}

export interface ISchadenEreignisEdit extends ISchadenEreignis {
    schadenEreignisId: number;
}

export interface AbstractFieldVisibilityCondition {
    conditionType: "always" | "hasValue" | "not" | "schadenart" | "schadentyp";
}

export interface IAnsprechpartnerDaten {
    ansprechpartner: IAdresse[];
}

export interface IBankdaten {
    vorsteuerabzugberechtigt: boolean;
    vorsteuerabzug: string;
    iban: string;
    kontoinhaber: string;
}

export interface IBeschreibung {
}

export interface IDatenschutzErklaerung {
    datenschutz: boolean;
    vertragsgesetz: boolean;
}

export interface IKfzKennzeichen {
    teil1: string;
    teil2: string;
    teil3: string;
    bezeichnung: string;
}

export interface IAdresse {
    strasse: string;
    plz: string;
    ort: string;
    land: string;
    vorname: string;
    telefon: string;
    name: string;
    email: string;
}

export interface IAnmeldeDatenBasis {
    kennzeichen: IKfzKennzeichen;
    fahrzeugNeuwert: number;
    sonderausstattungNeuwert: number;
    sonderausstattungArt: string;
    leasinggeberId: string;
    haftpflichtversicherung: boolean;
    kasko: string;
    teilkaskoDeckungId: string;
    vollkaskoDeckungId: string;
    insassenDeckungId: string;
    schutzbrief: boolean;
    rabattschutz: boolean;
    kilometerstand: number;
    kilometerleistung: number;
    abstellplatz: string;
    juengsterFahrer: number;
    familiennachlass: string;
    zahlungswiese: string;
    sepa: boolean;
    nutzung: string;
    beruf: string;
    berufsgruppe: string;
    betriebsausgabe: boolean;
    kommentar: string;
    iban: string;
    wohneigentum: string;
    risiko: string;
    versicherer: string;
    gap: boolean;
}

export interface VertragEigenverwaltetDaten {
    tenantFelder: ObjectNode;
}

export interface IAbmeldungDaten {
}

export interface VertragsanliegenSachversicherungDaten {
    gebaeude: string;
    inventar: string;
    strasse: string;
    plz: string;
    ort: string;
}

export interface ClassLoader {
}

export interface InputStream extends Closeable {
}

export interface URL extends Serializable {
}

export interface File extends Serializable, Comparable<File> {
}

export interface URI extends Comparable<URI>, Serializable {
}

export interface AbstractFileResolvingResource extends AbstractResource {
}

export interface InputStreamSource {
    inputStream: InputStream;
}

export interface Anhang extends BaseEntity<number> {
    id: number;
    filename: string;
    blobId: string;
    secretKey: number[];
    fileHash: string;
    saved: boolean;
}

export interface Serializable {
}

export interface ISortDto<T> {
    by: T;
}

export interface Comparable<T> {
}

export interface ISavableUserFilter<T> {
}

export interface IAkteEdit {
    schlagworte: string[];
}

export interface Cloneable {
}

export interface Closeable extends AutoCloseable {
}

export interface AbstractResource extends Resource {
}

export interface ContainerNode<T> extends BaseJsonNode, JsonNodeCreator {
}

export interface AutoCloseable {
}

export interface BaseEntity<T> extends Serializable {
    id: T;
}

export interface BaseJsonNode extends Serializable {
}

export interface JsonNodeCreator {
}

export interface EvbAbrufController {

    /**
     * HTTP POST /internal/ui/evb/abruf
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.abruf
     */
    abruf(request: EvbAbrufRequestDto): RestResponse<AbstractEvbAbrufResponseDtoUnion>;

    /**
     * HTTP GET /internal/ui/evb/dauer
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.dauerEvb
     */
    dauerEvb(): RestResponse<DauerEvbDto[]>;

    /**
     * HTTP GET /internal/ui/evb/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.stammdaten
     */
    stammdaten(): RestResponse<EvbAbrufStammdatenDto>;
}

export interface FileDownloadController {

    /**
     * HTTP GET /download/file/preview/{anhangId}/{pageNo}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadPreviewPage
     */
    downloadPreviewPage(anhangId: number, pageNo: number): RestResponse<Resource>;

    /**
     * HTTP GET /download/file/schadenmeldung/{schadenmeldungToken}/{anhangToken}/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadSchadenmeldungAttachment
     */
    downloadSchadenmeldungAttachment(schadenmeldungToken: string, anhangToken: string, anhangId: number): RestResponse<Resource>;

    /**
     * HTTP GET /download/file/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadAttachmentByAnhangId
     */
    downloadAttachmentByAnhangId(anhangId: number): RestResponse<Resource>;

    /**
     * HTTP GET /download/plugin/{name}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadPlugin
     */
    downloadPlugin(name: string): RestResponse<Resource>;

    /**
     * HTTP GET /download/store/support
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.findTippUndTricksForDownload
     */
    findTippUndTricksForDownload(): RestResponse<Resource>;

    /**
     * HTTP GET /download/store/{key}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.findFileByKey
     */
    findFileByKey(key: string): RestResponse<Resource>;
}

export interface CaptchaController {

    /**
     * HTTP GET /internal/schadenmeldung/captcha/challenge
     * Java method: de.ecclesia.connect.webservice.controller.CaptchaController.newChallenge
     */
    newChallenge(): RestResponse<CaptchaDto>;
}

export interface KundenRisikoController {

    /**
     * HTTP GET /internal/ui/kundenrisiko/risiko/sparten/filter
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenFilterForUser
     */
    getSpartenFilterForUser(): RestResponse<RisikoVertragSpartenRequestDto>;

    /**
     * HTTP GET /internal/ui/kundenrisiko/risiko/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getRisiko
     */
    getRisiko(id: string): RestResponse<KundenRisikoDto>;

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenListe
     */
    getSpartenListe(id: string, request: RisikoVertragSpartenRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenXls
     */
    getSpartenXls(id: number, listeRequest: RisikoVertragSpartenRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten/sums
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenListeColumnSums
     */
    getSpartenListeColumnSums(id: number, request: RisikoVertragSpartenRequestDto): RestResponse<ListSumsDto>;

    /**
     * HTTP GET /internal/ui/kundenrisiko/tree
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getPartnerstrukturTree
     */
    getPartnerstrukturTree(): RestResponse<RisikoStrukturKnotenDto>;
}

export interface SchadenController {

    /**
     * HTTP GET /internal/ui/schaden/details-with-ansprechpartner/{id:\d+}/all
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getDetailsWithAnsprechpartner
     */
    getDetailsWithAnsprechpartner(id: number): RestResponse<DetailsWithAnsprechpartnerDto<number>>;

    /**
     * HTTP GET /internal/ui/schaden/details/{id:\d+}/all
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetails
     */
    getSchadenDetails(id: number): RestResponse<DetailsDto<number>>;

    /**
     * HTTP GET /internal/ui/schaden/details/{id:\d+}/posteingang
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsForPosteingang
     */
    getSchadenDetailsForPosteingang(id: number): RestResponse<DetailsDto<number>>;

    /**
     * HTTP POST /internal/ui/schaden/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsXls
     */
    getSchadenDetailsXls(request: SchadenListeRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/schaden/liste
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenList
     */
    getSchadenList(originalRequest: SchadenListeRequestDto): RestResponse<GroupedListDto>;

    /**
     * HTTP GET /internal/ui/schaden/liste/eigenverwaltung/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenEigenverwaltungListFilter
     */
    getSchadenEigenverwaltungListFilter(): RestResponse<SchadenListeRequestDto>;

    /**
     * HTTP GET /internal/ui/schaden/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenListFilter
     */
    getSchadenListFilter(): RestResponse<SchadenListeRequestDto>;

    /**
     * HTTP GET /internal/ui/schaden/liste/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.permissions
     */
    permissions(): RestResponse<SchadenListePermissionsDto>;

    /**
     * HTTP GET /internal/ui/schaden/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]>;

    /**
     * @deprecated
     * HTTP GET /internal/ui/schaden/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.get
     */
    get(id: number): RestResponse<SchadenDto>;

    /**
     * HTTP POST /internal/ui/schaden/{id:\d+}/anonymize
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.anonymizeSchaden
     */
    anonymizeSchaden(id: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/schaden/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsPdf
     */
    getSchadenDetailsPdf(id: number): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/schaden/{id:\d+}/vertrag
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getVertragBySchaden
     */
    getVertragBySchaden(id: number): RestResponse<SchadenVertragDto>;
}

export interface BriefEditorController {

    /**
     * HTTP GET /internal/ui/brief/briefbogen/available
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getAvailableBriefbogenForTenant
     */
    getAvailableBriefbogenForTenant(): RestResponse<IdBezeichnungDto[]>;

    /**
     * HTTP POST /internal/ui/brief/briefbogen/preview
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.generateBriefbogenPreview
     */
    generateBriefbogenPreview(briefentwurfDto: BriefentwurfDtoUnion): RestResponse<BriefPreviewDto>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/create
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.createBrief
     */
    createBrief(): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/edit
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.editBrief
     */
    editBrief(): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/forward
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.forwardBriefentwurf
     */
    forwardBriefentwurf(forward: ForwardRequestDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/freigabe/{akteEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.setFreigabe
     */
    setFreigabe(akteEintragId: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/brief/briefentwurf/freigaben/{akteEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getFreigabeview
     */
    getFreigabeview(akteEintragId: number): RestResponse<FreigabeViewDto>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/freigabeview/save
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.saveFreigabeView
     */
    saveFreigabeView(request: BriefSendenRequestDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/send
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.sendBrief
     */
    sendBrief(request: BriefSendenRequestDto): RestResponse<BriefSendenReplyDto>;

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getStammdatenForEditor
     */
    getStammdatenForEditor(akteRefsDto: AkteRefDto[]): RestResponse<BriefEditorStammdatenDto>;

    /**
     * HTTP GET /internal/ui/brief/briefentwurf/{akteEintragId:\d+}/historie
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getBriefhistorie
     */
    getBriefhistorie(akteEintragId: number): RestResponse<BriefhistorieDto>;

    /**
     * HTTP GET /internal/ui/brief/{akteEintragId:\d+}/download/zip
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getZippedBriefForAkteEintragId
     */
    getZippedBriefForAkteEintragId(akteEintragId: number): RestResponse<Resource>;
}

export interface AdressbuchController {

    /**
     * HTTP GET /internal/ui/adressbuch/access
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.hasAdressbuchFeatureAccess
     */
    hasAdressbuchFeatureAccess(): RestResponse<boolean>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.createAdressbuchEintrag
     */
    createAdressbuchEintrag(request: AdressbuchEintragDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/connect
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.connectAdresse
     */
    connectAdresse(request: AdressbuchEintragConnectDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/edit
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.editAdressbuchEintrag
     */
    editAdressbuchEintrag(request: AdressbuchEintragDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/email/valid
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.validateEmail
     */
    validateEmail(email: string): RestResponse<boolean>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/liste
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressListe
     */
    getAdressListe(request: AdressbuchSucheDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/suche
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.sucheAdressbuchEintrag
     */
    sucheAdressbuchEintrag(request: AdressbuchSucheDto): RestResponse<AdressbuchEintragListeDto>;

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/box/{boxEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEitnragForBox
     */
    deleteAdressbuchEitnragForBox(adresseId: number, boxEintragId: number): RestResponse<void>;

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/schaden/{schadenId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForSchaden
     */
    deleteAdressbuchEintragForSchaden(adresseId: number, schadenId: number): RestResponse<void>;

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/type/{type}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForType
     */
    deleteAdressbuchEintragForType(adresseId: number, type: AdressbuchEintragTypDto): RestResponse<void>;

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/vertrag/{vertragId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForVertrag
     */
    deleteAdressbuchEintragForVertrag(adresseId: number, vertragId: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/adressbuch/adresse/{id}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressbuchEintrag
     */
    getAdressbuchEintrag(id: number): RestResponse<AdressbuchEintragDto>;

    /**
     * HTTP POST /internal/ui/adressbuch/info
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressBuchInfo
     */
    getAdressBuchInfo(request: AdressbuchInfoSucheDto): RestResponse<AdressbuchInfoDto>;

    /**
     * HTTP GET /internal/ui/adressbuch/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getStammdaten
     */
    getStammdaten(): RestResponse<AdressbuchStammdatenDto>;
}

export interface VertragController {

    /**
     * HTTP GET /internal/ui/vertrag/details/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetails
     */
    getVertragDetails(id: number): RestResponse<VertragDto>;

    /**
     * HTTP POST /internal/ui/vertrag/details/{id:\d+}/praemien
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getPraemienListe
     */
    getPraemienListe(id: number, request: PraemienAufteilungRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/vertrag/details/{id:\d+}/praemien/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getPraemienXls
     */
    getPraemienXls(id: number, listeRequest: PraemienAufteilungRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/vertrag/export/csv
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragListExportAsCsv
     */
    getVertragListExportAsCsv(): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/vertrag/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetailsXls
     */
    getVertragDetailsXls(listeRequest: VertragsListeRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/vertrag/list
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragListe
     */
    getVertragListe(originalRequest: VertragsListeRequestDto): RestResponse<ListDto>;

    /**
     * HTTP GET /internal/ui/vertrag/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragFilterForUser
     */
    getVertragFilterForUser(): RestResponse<VertragsListeRequestDto>;

    /**
     * HTTP GET /internal/ui/vertrag/list/kunden/user
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getAvailableKundenForUser
     */
    getAvailableKundenForUser(): RestResponse<ListeKundeOptionsDto[]>;

    /**
     * HTTP GET /internal/ui/vertrag/list/vertragarten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getAllVertragsArtenForRequest
     */
    getAllVertragsArtenForRequest(): RestResponse<VertragArtDto[]>;

    /**
     * HTTP GET /internal/ui/vertrag/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]>;

    /**
     * HTTP GET /internal/ui/vertrag/rechte
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.rechte
     */
    rechte(): RestResponse<VertragListeRechteDto>;

    /**
     * HTTP GET /internal/ui/vertrag/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetailsPdf
     */
    getVertragDetailsPdf(id: number): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/vertrag/{id:\d+}/schaeden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getSchaedenByVertrag
     */
    getSchaedenByVertrag(id: number): RestResponse<VertragSchaedenDto>;
}

export interface SchadenReserveController {

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/cancel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.cancel
     */
    cancel(schadenId: number, cancel: ReserveCancelDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/create
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.create
     */
    create(schadenId: number, create: ReserveCreateDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/edit/{reserveId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.edit
     */
    edit(schadenId: number, reserveId: number, edit: ZahlungReserveEditDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSchadenReserven
     */
    getSchadenReserven(schadenId: number, requestDto: ReserveListRequestDto): RestResponse<ListDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSchadenReservenFilterForUser
     */
    getSchadenReservenFilterForUser(schadenId: string): RestResponse<ReserveListRequestDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getReserveStammdaten
     */
    getReserveStammdaten(schadenId: number): RestResponse<ReserveStammdatenDto>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/summary
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSummary
     */
    getSummary(schadenId: number): RestResponse<ReserveListSummaryDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/{reserveId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.get
     */
    get(schadenId: number, reserveId: number): RestResponse<ZahlungReserveDto>;
}

export interface RohdatenExportController {

    /**
     * HTTP GET /internal/ui/rohdaten/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.RohdatenExportController.getRohdatenXls
     */
    getRohdatenXls(): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/rohdaten/permission
     * Java method: de.ecclesia.connect.webservice.controller.RohdatenExportController.canSeeRohdatenExport
     */
    canSeeRohdatenExport(): RestResponse<boolean>;
}

export interface KundenSeitenController {

    /**
     * HTTP GET /internal/ui/kundenseite/seite/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenController.seite
     */
    seite(id: number): RestResponse<KundenSeiteDto>;

    /**
     * HTTP GET /internal/ui/kundenseite/token/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenController.downloadToken
     */
    downloadToken(id: number): RestResponse<KundenSeiteDownloadDto>;
}

export interface SupportController {

    /**
     * HTTP GET /internal/ui/support/url
     * Java method: de.ecclesia.connect.webservice.controller.SupportController.getUrl
     */
    getUrl(): RestResponse<SupportDto>;
}

export interface UserPreferencesController {

    /**
     * HTTP GET /internal/ui/user/preferences
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.getPreferences
     */
    getPreferences(): RestResponse<UserPreferencesMenuDto>;

    /**
     * HTTP GET /internal/ui/user/preferences/default
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.getDefaultPreferences
     */
    getDefaultPreferences(): RestResponse<UserPreferencesMenuDto>;

    /**
     * HTTP GET /internal/ui/user/preferences/reset
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.resetPreferences
     */
    resetPreferences(): RestResponse<UserPreferencesMenuDto>;

    /**
     * HTTP POST /internal/ui/user/preferences/update
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.updatePreferences
     */
    updatePreferences(prefs: UserPreferencesDto): RestResponse<void>;
}

export interface ErrorPageController {

    /**
     * HTTP GET /error
     * Java method: de.ecclesia.connect.webservice.controller.ErrorPageController.getErrorPage
     */
    getErrorPage(): RestResponse<ClassPathResource>;

    /**
     * HTTP GET /error
     * Java method: de.ecclesia.connect.webservice.controller.ErrorPageController.getRestErrorJson
     */
    getRestErrorJson(): RestResponse<ExceptionDto>;
}

export interface SchadenEigenverwaltungController {

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.anlegen
     */
    anlegen(request: SchadenEigenverwaltungDataDto): RestResponse<SchadenEigenverwaltungResultDto>;

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/bearbeiten/anfragen/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenRequest
     */
    bearbeitenRequest(id: number): RestResponse<SchadenEigenverwaltungDataDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/bearbeiten/kurzdarstellung/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenKurzdarstellungSubmit
     */
    bearbeitenKurzdarstellungSubmit(id: number, request: SchadenKurzdarstellungDataDto): RestResponse<SchadenEigenverwaltungResultDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/bearbeiten/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenSubmit
     */
    bearbeitenSubmit(id: number, request: SchadenEigenverwaltungDataDto): RestResponse<SchadenEigenverwaltungResultDto>;

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/bearbeiten/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<SchadenEigenverwaltungPermissionsDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/fahrzeugtyp
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.fahrzeugtyp
     */
    fahrzeugtyp(request: SchadenEigenverwaltungFahrzeugtypRequestDto): RestResponse<SchadenEigenverwaltungFahrzeugtypDatenDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/schadenart
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.schadenart
     */
    schadenart(request: SchadenEigenverwaltungSchadenartRequestDto): RestResponse<SchadenEigenverwaltungSchadenartDatenDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/schadentyp
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.schadentyp
     */
    schadentyp(request: SchadenEigenverwaltungSchadentypRequestDto): RestResponse<SchadenEigenverwaltungSchadentypDatenDto>;

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/see
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.createSEE
     */
    createSEE(see: SeeAnforderungErstellenDto): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.stammdaten
     */
    stammdaten(queryParams?: { ouId?: string; }): RestResponse<SchadenEigenverwaltungStammdatenDto>;
}

export interface AufgabenController {

    /**
     * HTTP POST /internal/ui/akte/aufgabe/store/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AufgabenController.setAufgabe
     */
    setAufgabe(id: number, aufgabeDto: LightAufgabeDtoUnion): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/akte/aufgabe/users/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AufgabenController.possibleUsers
     */
    possibleUsers(id: number): RestResponse<ExternalUserWithAccessDto[]>;
}

export interface PosteingangController {

    /**
     * HTTP GET /internal/ui/posteingang/canberemoved/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.canBeRemoved
     */
    canBeRemoved(id: number): RestResponse<boolean>;

    /**
     * HTTP DELETE /internal/ui/posteingang/eintrag/{eintragId}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.deletePosteingangEintrag
     */
    deletePosteingangEintrag(eintragId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/posteingang/filter
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.filterPosteingang
     */
    filterPosteingang(filterDto: PosteingangFilterDto): RestResponse<PosteingangListeDto>;

    /**
     * HTTP GET /internal/ui/posteingang/filter
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getFilter
     */
    getFilter(): RestResponse<PosteingangFilterDto>;

    /**
     * HTTP GET /internal/ui/posteingang/filter/entryFilters
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getEntryFilters
     */
    getEntryFilters(): RestResponse<PosteingangEntryFiltersDto>;

    /**
     * HTTP POST /internal/ui/posteingang/filter/save
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.saveFilter
     */
    saveFilter(pref: PosteingangEntryFilterDtoUnion): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/posteingang/get/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.get
     */
    get(id: number): RestResponse<PosteingangDetailEintragDto>;

    /**
     * HTTP GET /internal/ui/posteingang/info
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getInfo
     */
    getInfo(): RestResponse<PosteingangListenInfoDto[]>;

    /**
     * HTTP GET /internal/ui/posteingang/list/{aktenEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getAllForAktenEintrag
     */
    getAllForAktenEintrag(aktenEintragId: number): RestResponse<PosteingangListenEintragDto[]>;

    /**
     * HTTP GET /internal/ui/posteingang/users
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getUsers
     */
    getUsers(): RestResponse<UserReferenceDto[]>;
}

export interface KundenSeitenDownloadController {

    /**
     * HTTP GET /download/kundenseite/anhang/{token}/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenDownloadController.downloadFile
     */
    downloadFile(token: string, id: number): RestResponse<Resource>;
}

export interface SchadenMeldenController {

    /**
     * HTTP POST /internal/schadenmeldung
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.saveSchadenmeldung
     */
    saveSchadenmeldung(): RestResponse<SchadenmeldungAntwortDto>;

    /**
     * HTTP GET /internal/schadenmeldung/formular
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getSchadenmeldungFormular
     */
    getSchadenmeldungFormular(queryParams: { schadenTypId: string; indiv?: string; }): RestResponse<SchadenmeldungFormularDto>;

    /**
     * HTTP GET /internal/schadenmeldung/formular/allgemeineDaten
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getAllgemeineDaten
     */
    getAllgemeineDaten(queryParams?: { firma?: string; indiv?: string; }): RestResponse<SchadenMeldungAllgemeineDatenDto>;

    /**
     * HTTP POST /internal/schadenmeldung/result
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.schadenmeldungResult
     */
    schadenmeldungResult(token: string): RestResponse<SchadenmeldungErgebnisDto>;

    /**
     * HTTP GET /internal/schadenmeldung/schadenmeldungformular/exists
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getDezentraleSchadenmeldungExists
     */
    getDezentraleSchadenmeldungExists(queryParams: { uuid: string; }): RestResponse<boolean>;
}

export interface SchadenEreignisController {

    /**
     * HTTP POST /internal/ui/schadenereignis/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.update
     */
    update(request: SchadenEreignisEditDto): RestResponse<SchadenEreignisResultDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.create
     */
    create(request: SchadenEreignisDto): RestResponse<SchadenEreignisResultDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/details
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisDetails
     */
    getSchadenEreignisDetails(request: SchadenEreignisDetailsRequestDto): RestResponse<SchadenEreignisDetailsDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/details/pdf
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisDetailsPdf
     */
    getSchadenEreignisDetailsPdf(): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/schadenereignis/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisListXls
     */
    getSchadenEreignisListXls(request: SchadenEreignisListeRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/schadenereignis/liste
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisList
     */
    getSchadenEreignisList(request: SchadenEreignisListeRequestDto): RestResponse<ListDto>;

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisFilterForUser
     */
    getSchadenEreignisFilterForUser(): RestResponse<SchadenEreignisListeRequestDto>;

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter/reserven
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisReservenFilterForUser
     */
    getSchadenEreignisReservenFilterForUser(): RestResponse<SchadenEreignisReserveListRequestDto>;

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter/zahlungen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisZahlungenFilterForUser
     */
    getSchadenEreignisZahlungenFilterForUser(): RestResponse<SchadenEreignisZahlungenListRequestDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/reserven/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenReserven
     */
    getSchadenReserven(ereignisId: number, requestDto: SchadenEreignisReserveListRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/reserven/{ereignisId}/sum
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getReservenSumForEreignis
     */
    getReservenSumForEreignis(ereignisId: number, requestDto: SchadenEreignisReserveListRequestDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/zahlungen/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenZahlungen
     */
    getSchadenZahlungen(ereignisId: number, requestDto: SchadenEreignisZahlungenListRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/zahlungen/{ereignisId}/sum
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getZahlungenSumForEreignis
     */
    getZahlungenSumForEreignis(ereignisId: number, requestDto: SchadenEreignisZahlungenListRequestDto): RestResponse<number>;

    /**
     * HTTP DELETE /internal/ui/schadenereignis/loeschen/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.delete
     */
    delete(ereignisId: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/schadenereignis/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]>;

    /**
     * HTTP GET /internal/ui/schadenereignis/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getPermissions
     */
    getPermissions(): RestResponse<SchadenEreignisPermissionsDto>;
}

export interface StatistikController {

    /**
     * HTTP GET /internal/ui/statistik/rechte
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.rechte
     */
    rechte(): RestResponse<StatistikRechteDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenStatistik
     */
    getSchadenTypenStatistik(request: SchadenTypenStatistikRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenStatistikXls
     */
    getSchadenTypenStatistikXls(request: SchadenTypenStatistikRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypStatistikPdf
     */
    getSchadenTypStatistikPdf(): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/statistik/schaden/typen/filter
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenFilterForUser
     */
    getSchadenTypenFilterForUser(): RestResponse<SchadenTypenStatistikRequestDto>;
}

export interface AkteController {

    /**
     * HTTP POST /internal/ui/akte/anhaenge
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.downloadAnhaengeAsZip
     */
    downloadAnhaengeAsZip(request: AnhaengeZipDownloadRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/akte/anhang
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.downloadSingleAkteneintragAsZip
     */
    downloadSingleAkteneintragAsZip(request: AktenEintragAnhaengeZipDownloadRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/akte/anhang/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getForDownload
     */
    getForDownload(anhangId: number): RestResponse<AktenAnhangDownloadDto>;

    /**
     * HTTP GET /internal/ui/akte/eintrag/anhang/{anhangId}/{seite}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenEintragAnnotations
     */
    getAktenEintragAnnotations(anhangId: number, seite: number): RestResponse<string>;

    /**
     * HTTP POST /internal/ui/akte/eintrag/permission/write
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getCreatePermissions
     */
    getCreatePermissions(akteRefsDto: AkteRefDto[]): RestResponse<AktePermissionsDto>;

    /**
     * HTTP DELETE /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.deleteAktenEintrag
     */
    deleteAktenEintrag(aktenEintragId: number, version: TempWithVersionDto): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenEintrag
     */
    getAktenEintrag(aktenEintragId: number): RestResponse<AktenEintragDto>;

    /**
     * HTTP POST /internal/ui/akte/filter/dateitypen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAvailableDateitypen
     */
    getAvailableDateitypen(akteRefs: AkteRefDto[]): RestResponse<DateiTypenListDto>;

    /**
     * HTTP POST /internal/ui/akte/liste
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenListeForFilter
     */
    getAktenListeForFilter(akteListePagedRequestDto: AkteListePagedRequestDto): RestResponse<AktenListeDto>;

    /**
     * HTTP POST /internal/ui/akte/liste/akteRefs
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenListeForAkteRefs
     */
    getAktenListeForAkteRefs(akteRefs: AkteRefDto[]): RestResponse<AktenListeDto>;

    /**
     * HTTP GET /internal/ui/akte/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAkteFilterForUser
     */
    getAkteFilterForUser(): RestResponse<AkteListePagedRequestDto>;

    /**
     * HTTP GET /internal/ui/akte/liste/kunden/user
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAvailableKundenForUser
     */
    getAvailableKundenForUser(): RestResponse<ListeKundeOptionsDto[]>;

    /**
     * HTTP POST /internal/ui/akte/notiz/benutzerliste
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getPossibleUsersForNotiz
     */
    getPossibleUsersForNotiz(dto: DomainObjectIdDto): RestResponse<MinimalExternalUserDto[]>;

    /**
     * HTTP GET /internal/ui/akte/users/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.possibleUsers
     */
    possibleUsers(id: number): RestResponse<ExternalUserWithAccessDto[]>;
}

export interface TextbausteinController {

    /**
     * HTTP POST /internal/ui/textbaustein/create
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.createTextbaustein
     */
    createTextbaustein(textbausteinErstellenDto: TextbausteinErstellenDto): RestResponse<void>;

    /**
     * HTTP DELETE /internal/ui/textbaustein/delete/{textbausteinId}
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.deleteTextbaustein
     */
    deleteTextbaustein(textbausteinId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/textbaustein/edit
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.editTextbaustein
     */
    editTextbaustein(textbausteinDto: TextbausteinDto): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/textbaustein/list/allVariables
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listAllVariablesForUser
     */
    listAllVariablesForUser(): RestResponse<TextbausteinVariableDto[]>;

    /**
     * HTTP GET /internal/ui/textbaustein/list/raw
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserRaw
     */
    listTextbausteineForUserRaw(): RestResponse<TextbausteinDto[]>;

    /**
     * HTTP POST /internal/ui/textbaustein/list/replaced
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserReplaced
     */
    listTextbausteineForUserReplaced(request: TextbausteinRequestDto): RestResponse<TextbausteinDto[]>;

    /**
     * HTTP POST /internal/ui/textbaustein/list/replaced/brief
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserReplacedBrief
     */
    listTextbausteineForUserReplacedBrief(request: BriefeditorTextbausteinRequestDto): RestResponse<TextbausteinDto[]>;

    /**
     * HTTP POST /internal/ui/textbaustein/list/variables
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteinVariablesForUser
     */
    listTextbausteinVariablesForUser(request: TextbausteinRequestDto): RestResponse<TextbausteinVariableDto[]>;

    /**
     * HTTP POST /internal/ui/textbaustein/list/variables/brief
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteinVariablesForUserBrief
     */
    listTextbausteinVariablesForUserBrief(request: BriefeditorTextbausteinRequestDto): RestResponse<TextbausteinVariableDto[]>;
}

export interface ZahlungsuebersichtController {

    /**
     * HTTP POST /internal/ui/zahlungsuebersicht/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getSchadenZahlungen
     */
    getSchadenZahlungen(requestDto: ZahlungsuebersichtRequestDto): RestResponse<ListDto>;

    /**
     * HTTP POST /internal/ui/zahlungsuebersicht/list/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getZahlungsuebersichtXls
     */
    getZahlungsuebersichtXls(requestDto: ZahlungsuebersichtRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/zahlungsuebersicht/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getSchadenZahlungenFilterForUser
     */
    getSchadenZahlungenFilterForUser(): RestResponse<ZahlungsuebersichtRequestDto>;

    /**
     * HTTP GET /internal/ui/zahlungsuebersicht/list/permission
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.canSeeZahlungsuebersicht
     */
    canSeeZahlungsuebersicht(): RestResponse<boolean>;
}

export interface DashboardController {

    /**
     * HTTP GET /internal/ui/dashboard/all
     * Java method: de.ecclesia.connect.webservice.controller.DashboardController.getDashboardInformation
     */
    getDashboardInformation(): RestResponse<DashboardDto>;
}

export interface ReserveUebersichtController {

    /**
     * HTTP POST /internal/ui/reserveuebersicht/export
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.export
     */
    export(request: ReserveUebersichtRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/reserveuebersicht/list/permission
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.canSeeReserveuebersicht
     */
    canSeeReserveuebersicht(): RestResponse<boolean>;

    /**
     * HTTP GET /internal/ui/reserveuebersicht/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.stammdaten
     */
    stammdaten(): RestResponse<ReserveUebersichtStammdatenDto>;
}

export interface SchadenStatistikController {

    /**
     * HTTP POST /internal/ui/statistik/schaden/anzahldreieck/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getAnzahldreieckExport
     */
    getAnzahldreieckExport(anzahldreieckRequest: StatistikSchadenListRequestDto): RestResponse<Resource>;

    /**
     * HTTP DELETE /internal/ui/statistik/schaden/auswertung/delete/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.deleteStatisticsEvaluation
     */
    deleteStatisticsEvaluation(id: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/statistik/schaden/auswertung/load/all
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getStatisticsEvaluations
     */
    getStatisticsEvaluations(): RestResponse<StatistikAuswertungListsDto>;

    /**
     * HTTP GET /internal/ui/statistik/schaden/auswertung/load/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getStatisticsEvaluation
     */
    getStatisticsEvaluation(id: number): RestResponse<StatistikAuswertungDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/auswertung/save
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.saveStatisticsEvaluation
     */
    saveStatisticsEvaluation(evaluation: StatistikAuswertungDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/auswertung/update
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.updateStatisticsEvaluation
     */
    updateStatisticsEvaluation(evaluation: StatistikAuswertungDto): RestResponse<number>;

    /**
     * HTTP GET /internal/ui/statistik/schaden/available
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getAvailableStatistics
     */
    getAvailableStatistics(): RestResponse<AvailableStatistikCriteriaDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/available/options
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getFilterOptionsForFilter
     */
    getFilterOptionsForFilter(requestDto: FilterOptionsRequestDto): RestResponse<FilterWithOptionsDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/chainladder/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getChainladderExport
     */
    getChainladderExport(chainladderStatistikDto: ChainladderStatistikDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getDiagram
     */
    getDiagram(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<StatistikDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/count
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getResultSchadenCount
     */
    getResultSchadenCount(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getPdfExport
     */
    getPdfExport(): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getExcelExport
     */
    getExcelExport(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/list/sums
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSumsForHeaders
     */
    getSumsForHeaders(statistikSchadenListRequestDto: StatistikSchadenListRequestDto): RestResponse<ListSumsDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/kurzfassung/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getKurzfassungPdf
     */
    getKurzfassungPdf(request: StatistikSchadenListRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/kurzfassung/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getKurzfassungExcelExport
     */
    getKurzfassungExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/list
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenList
     */
    getSchadenList(request: StatistikSchadenListRequestDto): RestResponse<GroupedListDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/list/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getListExcelExport
     */
    getListExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/statistik/schaden/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenFilterForUser
     */
    getSchadenFilterForUser(): RestResponse<StatistikSchadenListRequestDto>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/schadenuebersicht/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenuebersichtPdfExport
     */
    getSchadenuebersichtPdfExport(request: StatistikSchadenListRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/statistik/schaden/schadenuebersicht/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenuebersichtExcelExport
     */
    getSchadenuebersichtExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource>;
}

export interface AkteEditController {

    /**
     * HTTP POST /internal/ui/akte/eintrag/anhang/{anhangId}/{seite}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.editAktenEintragAnnotations
     */
    editAktenEintragAnnotations(anhangId: number, seite: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.editAktenEintrag
     */
    editAktenEintrag(aktenEintragId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/eintrag/{aktenEintragId}/rename/anhang
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.renameAnhang
     */
    renameAnhang(aktenEintragId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/neu/email/ablegen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueEmailsAblegen
     */
    neueEmailsAblegen(): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/neu/email/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueEmailErstellen
     */
    neueEmailErstellen(): RestResponse<AktenAnhangDto>;

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueMaklernachricht
     */
    neueMaklernachricht$POST$internal_ui_akte_neu_maklernachricht(): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht/adressen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.maklernachrichtAdressen
     */
    maklernachrichtAdressen(requestDto: MaklernachrichtAdressenRequestDto): RestResponse<MaklernachrichtEmpfaengerDto[]>;

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht/{respondToId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueMaklernachricht
     */
    neueMaklernachricht$POST$internal_ui_akte_neu_maklernachricht_respondToId(respondToId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/neu/notiz
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueNotiz
     */
    neueNotiz(): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/neu/schadenmeldung
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueSchadenmeldung
     */
    neueSchadenmeldung(): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/notiz/teilen/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.forwardNotiz
     */
    forwardNotiz(aktenEintragId: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/akte/schlagworte
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.getSchlagworte
     */
    getSchlagworte(akteRefs: AkteRefDto[]): RestResponse<string[]>;

    /**
     * HTTP POST /internal/ui/akte/schlagworte/filter
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.getSchlagworteForFilter
     */
    getSchlagworteForFilter(akteRefs: AkteRefDto[]): RestResponse<string[]>;

    /**
     * HTTP GET /internal/ui/akte/schlagworte/hasTenantSchlagworte
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.hasTenantSchlagworte
     */
    hasTenantSchlagworte(): RestResponse<boolean>;

    /**
     * HTTP GET /internal/ui/akte/schlagworte/permission/read
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.canReadSchlagworte
     */
    canReadSchlagworte(): RestResponse<boolean>;

    /**
     * HTTP POST /internal/ui/akte/teilen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueForwardLight
     */
    neueForwardLight(forward: AkteCreateForwardLightDto): RestResponse<Resource>;
}

export interface TerminController {

    /**
     * HTTP POST /internal/ui/termin/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTermin
     */
    updateTermin(dto: TerminEditDto): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/termin/benutzerliste
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getPossibleUsers
     */
    getPossibleUsers(): RestResponse<MinimalExternalUserDto[]>;

    /**
     * HTTP POST /internal/ui/termin/count
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTerminCount
     */
    getTerminCount(requestDto: PosteingangTerminListePagedRequestDto): RestResponse<number>;

    /**
     * HTTP POST /internal/ui/termin/erledigen/{terminId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.closeTermin
     */
    closeTermin(terminId: number, version: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/termin/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.createTermin
     */
    createTermin(dto: TerminCreateDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/termin/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermineExcelExport
     */
    getTermineExcelExport(requestDto: AbstractTerminListePagedRequestDto): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/termin/filter
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getPosteingangFilter
     */
    getPosteingangFilter(): RestResponse<PosteingangTerminListePagedRequestDto>;

    /**
     * HTTP GET /internal/ui/termin/gruende
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getGruende
     */
    getGruende(): RestResponse<TerminGrundDto[]>;

    /**
     * HTTP POST /internal/ui/termin/list
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermine
     */
    getTermine(requestDto: AbstractTerminListePagedRequestDto): RestResponse<TerminListDto>;

    /**
     * HTTP GET /internal/ui/termin/mostRecentlyUpdated
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.mostRecentlyUpdated
     */
    mostRecentlyUpdated(): RestResponse<InternalHyperlinkDto[]>;

    /**
     * HTTP POST /internal/ui/termin/terminFormData
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTerminFormData
     */
    getTerminFormData(dto: TerminReferencesDto): RestResponse<TerminFormDataDto>;

    /**
     * HTTP POST /internal/ui/termin/todo/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTodo
     */
    updateTodo(dto: TerminTodoDto): RestResponse<void>;

    /**
     * HTTP DELETE /internal/ui/termin/todo/{todoId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteTodo
     */
    deleteTodo(todoId: number, version: number): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/termin/vorlage/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTerminVorlage
     */
    updateTerminVorlage(dto: TerminVorlageEditDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/termin/vorlage/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.createTerminVorlage
     */
    createTerminVorlage(dto: TerminVorlageCreateDto): RestResponse<number>;

    /**
     * HTTP GET /internal/ui/termin/vorlage/{vorlageId}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getVorlage
     */
    getVorlage(vorlageId: number): RestResponse<TerminVorlageDto>;

    /**
     * HTTP DELETE /internal/ui/termin/vorlage/{vorlageId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteVorlage
     */
    deleteVorlage(vorlageId: number, version: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/termin/vorlagen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getVorlagen
     */
    getVorlagen(): RestResponse<ListeDto<TerminVorlageDto>>;

    /**
     * HTTP GET /internal/ui/termin/{terminId}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermin
     */
    getTermin(terminId: number): RestResponse<TerminDetailsDto>;

    /**
     * HTTP DELETE /internal/ui/termin/{terminId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteTermin
     */
    deleteTermin(terminId: number, version: number): RestResponse<void>;
}

export interface UserController {

    /**
     * HTTP POST /internal/ui/user/bedingung/accept
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.acceptBundle
     */
    acceptBundle(ids: number[]): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/user/bedingung/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getBedingungFile
     */
    getBedingungFile(id: number): RestResponse<Resource>;

    /**
     * HTTP GET /internal/ui/user/current
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getConnectUser
     */
    getConnectUser(): RestResponse<LoginInfoDto>;

    /**
     * HTTP GET /internal/ui/user/rights/aktetypen
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getAvailableAkteTypenForCurrentUser
     */
    getAvailableAkteTypenForCurrentUser(): RestResponse<AkteTypenRightDto>;
}

export interface VertragsanliegenController {

    /**
     * HTTP POST /internal/ui/vertragsanliegen/send
     * Java method: de.ecclesia.connect.webservice.controller.VertragsanliegenController.send
     */
    send(): RestResponse<VertragsanliegenResponseDto>;

    /**
     * HTTP GET /internal/ui/vertragsanliegen/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.VertragsanliegenController.getVersicherungsarten
     */
    getVersicherungsarten(queryParams?: { vertragId?: number; }): RestResponse<VertragsanliegenStammdatenDto>;
}

export interface FakeOauthController {

    /**
     * HTTP GET /fakeoauth2/hints
     * Java method: de.ecclesia.connect.webservice.fakeuser.FakeOauthController.hints
     */
    hints(): RestResponse<string>;

    /**
     * HTTP GET /fakeoauth2/hints/{userId}
     * Java method: de.ecclesia.connect.webservice.fakeuser.FakeOauthController.hintsForUser
     */
    hintsForUser(userId: number): RestResponse<string>;
}

export interface KfzAnAbmeldenController {

    /**
     * HTTP POST /internal/ui/vertrag/kfz/anmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.anmelden
     */
    anmelden(): RestResponse<KfzAnmeldenResultDto>;

    /**
     * HTTP GET /internal/ui/vertrag/kfz/anmelden/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.anmeldenStammdaten
     */
    anmeldenStammdaten(): RestResponse<KfzAnmeldenStammdatenDto>;

    /**
     * HTTP GET /internal/ui/vertrag/kfz/fahrzeugwechsel/stammdaten/{vertragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.fahrzeugwechselStammdaten
     */
    fahrzeugwechselStammdaten(vertragId: number): RestResponse<KfzFahrzeugwechselStammdatenDto>;

    /**
     * HTTP POST /internal/ui/vertrag/kfz/fahrzeugwechsel/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.fahrzeugwechsel
     */
    fahrzeugwechsel(id: number): RestResponse<KfzAnmeldenResultDto>;

    /**
     * HTTP POST /internal/ui/vertrag/kfz/{id:\d+}/abmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.abmelden
     */
    abmelden$POST$internal_ui_vertrag_kfz_id_abmelden(id: number): RestResponse<VertragKfzAbmeldenResultDto>;

    /**
     * HTTP GET /internal/ui/vertrag/kfz/{id:\d+}/abmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.abmelden
     */
    abmelden$GET$internal_ui_vertrag_kfz_id_abmelden(id: number): RestResponse<VertragKfzAbmeldenDto>;
}

export interface UpdateInfoController {

    /**
     * HTTP POST /internal/ui/updateinfo/hide/{updateInfoId}
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.hide
     */
    hide(updateInfoId: number): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/updateinfo/listAll
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.listAll
     */
    listAll(): RestResponse<UpdateInfoDto[]>;

    /**
     * HTTP GET /internal/ui/updateinfo/listUnread
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.listUnread
     */
    listUnread(): RestResponse<UpdateInfoDto[]>;
}

export interface SchadenZahlungenController {

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/cancel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.cancel
     */
    cancel(schadenId: number, cancel: ZahlungCancelDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/create
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.create
     */
    create(schadenId: number, create: ZahlungCreateDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/edit/{zahlungId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.edit
     */
    edit(schadenId: number, zahlungId: number, edit: ZahlungEditDto): RestResponse<void>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSchadenZahlungen
     */
    getSchadenZahlungen(schadenId: number, requestDto: ZahlungListRequestDto): RestResponse<ListDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSchadenZahlungenFilterForUser
     */
    getSchadenZahlungenFilterForUser(schadenId: string): RestResponse<ZahlungListRequestDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.stammdaten
     */
    stammdaten(schadenId: number): RestResponse<ZahlungStammdatenDto>;

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/summary
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSummary
     */
    getSummary(schadenId: number): RestResponse<ZahlungListSummaryDto>;

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/{zahlungId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.get
     */
    get(schadenId: number, zahlungId: number): RestResponse<SchadenZahlungDto>;
}

export interface BoxController {

    /**
     * HTTP POST /internal/ui/box/create
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.createBoxEntry
     */
    createBoxEntry(request: BoxDataDto): RestResponse<BoxCreateResultDto>;

    /**
     * HTTP GET /internal/ui/box/details/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryDetails
     */
    getBoxEntryDetails(id: number): RestResponse<DetailsDto<number>>;

    /**
     * HTTP GET /internal/ui/box/details/{id:\d+}/status
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryStatus
     */
    getBoxEntryStatus(id: number): RestResponse<BoxDataDto>;

    /**
     * HTTP GET /internal/ui/box/edit/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryEditFormData
     */
    getBoxEntryEditFormData(id: number): RestResponse<BoxDataDto>;

    /**
     * HTTP POST /internal/ui/box/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxDetailsXls
     */
    getBoxDetailsXls(request: BoxListRequestDto): RestResponse<Resource>;

    /**
     * HTTP POST /internal/ui/box/list
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getList
     */
    getList(request: BoxListRequestDto): RestResponse<ListDto>;

    /**
     * HTTP GET /internal/ui/box/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListFilter
     */
    getListFilter(): RestResponse<BoxListRequestDto>;

    /**
     * HTTP GET /internal/ui/box/list/filter/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListFilterForThema
     */
    getListFilterForThema(themaId: number): RestResponse<BoxListRequestDto>;

    /**
     * HTTP GET /internal/ui/box/list/permissions
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListPermissions
     */
    getListPermissions(): RestResponse<BoxListPermissionsDto>;

    /**
     * HTTP GET /internal/ui/box/list/permissions/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListPermissionsForThema
     */
    getListPermissionsForThema(themaId: number): RestResponse<BoxListPermissionsDto>;

    /**
     * HTTP GET /internal/ui/box/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getMasterDataWithoutThema
     */
    getMasterDataWithoutThema(): RestResponse<BoxStammdatenDto>;

    /**
     * HTTP GET /internal/ui/box/stammdaten/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getMasterDataByThema
     */
    getMasterDataByThema(themaId: number): RestResponse<BoxStammdatenDto>;

    /**
     * HTTP GET /internal/ui/box/themen
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getThemenForUser
     */
    getThemenForUser(): RestResponse<IdBezeichnungDto[]>;

    /**
     * HTTP POST /internal/ui/box/update/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.updateBoxEntry
     */
    updateBoxEntry(id: number, request: BoxDataDto): RestResponse<void>;

    /**
     * HTTP GET /internal/ui/box/update/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<BoxPermissionsDto>;

    /**
     * HTTP GET /internal/ui/box/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryDetailsPdf
     */
    getBoxEntryDetailsPdf(id: number): RestResponse<Resource>;
}

export interface VertragEigenverwaltungController {

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/bearbeiten/anfragen/{id}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.bearbeitenRequest
     */
    bearbeitenRequest(id: number): RestResponse<VertragEigenverwaltungDataDto>;

    /**
     * HTTP POST /internal/ui/vertrag/eigenverwaltung/bearbeiten/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.bearbeitenSubmit
     */
    bearbeitenSubmit(id: number, request: VertragEigenverwaltungDataDto): RestResponse<VertragEigenverwaltungResultDto>;

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/bearbeiten/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<VertragEigenverwaltungPermissionsDto>;

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.stammdaten
     */
    stammdaten(): RestResponse<VertragEigenverwaltungStammdatenDto>;
}

export interface HttpClient {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; }): RestResponse<R>;
}

export class EvbAbrufControllerClient implements EvbAbrufController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/evb/abruf
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.abruf
     */
    abruf(request: EvbAbrufRequestDto): RestResponse<AbstractEvbAbrufResponseDtoUnion> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/evb/abruf`, data: request });
    }

    /**
     * HTTP GET /internal/ui/evb/dauer
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.dauerEvb
     */
    dauerEvb(): RestResponse<DauerEvbDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/evb/dauer` });
    }

    /**
     * HTTP GET /internal/ui/evb/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.EvbAbrufController.stammdaten
     */
    stammdaten(): RestResponse<EvbAbrufStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/evb/stammdaten` });
    }
}

export class FileDownloadControllerClient implements FileDownloadController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /download/file/preview/{anhangId}/{pageNo}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadPreviewPage
     */
    downloadPreviewPage(anhangId: number, pageNo: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/file/preview/${anhangId}/${pageNo}` });
    }

    /**
     * HTTP GET /download/file/schadenmeldung/{schadenmeldungToken}/{anhangToken}/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadSchadenmeldungAttachment
     */
    downloadSchadenmeldungAttachment(schadenmeldungToken: string, anhangToken: string, anhangId: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/file/schadenmeldung/${schadenmeldungToken}/${anhangToken}/${anhangId}` });
    }

    /**
     * HTTP GET /download/file/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadAttachmentByAnhangId
     */
    downloadAttachmentByAnhangId(anhangId: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/file/${anhangId}` });
    }

    /**
     * HTTP GET /download/plugin/{name}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.downloadPlugin
     */
    downloadPlugin(name: string): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/plugin/${name}` });
    }

    /**
     * HTTP GET /download/store/support
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.findTippUndTricksForDownload
     */
    findTippUndTricksForDownload(): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/store/support` });
    }

    /**
     * HTTP GET /download/store/{key}
     * Java method: de.ecclesia.connect.webservice.controller.FileDownloadController.findFileByKey
     */
    findFileByKey(key: string): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/store/${key}` });
    }
}

export class CaptchaControllerClient implements CaptchaController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/schadenmeldung/captcha/challenge
     * Java method: de.ecclesia.connect.webservice.controller.CaptchaController.newChallenge
     */
    newChallenge(): RestResponse<CaptchaDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/schadenmeldung/captcha/challenge` });
    }
}

export class KundenRisikoControllerClient implements KundenRisikoController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/kundenrisiko/risiko/sparten/filter
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenFilterForUser
     */
    getSpartenFilterForUser(): RestResponse<RisikoVertragSpartenRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/kundenrisiko/risiko/sparten/filter` });
    }

    /**
     * HTTP GET /internal/ui/kundenrisiko/risiko/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getRisiko
     */
    getRisiko(id: string): RestResponse<KundenRisikoDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/kundenrisiko/risiko/${id}` });
    }

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenListe
     */
    getSpartenListe(id: string, request: RisikoVertragSpartenRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/kundenrisiko/risiko/${id}/sparten`, data: request });
    }

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenXls
     */
    getSpartenXls(id: number, listeRequest: RisikoVertragSpartenRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/kundenrisiko/risiko/${id}/sparten/export/excel`, data: listeRequest });
    }

    /**
     * HTTP POST /internal/ui/kundenrisiko/risiko/{id:\d+}/sparten/sums
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getSpartenListeColumnSums
     */
    getSpartenListeColumnSums(id: number, request: RisikoVertragSpartenRequestDto): RestResponse<ListSumsDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/kundenrisiko/risiko/${id}/sparten/sums`, data: request });
    }

    /**
     * HTTP GET /internal/ui/kundenrisiko/tree
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KundenRisikoController.getPartnerstrukturTree
     */
    getPartnerstrukturTree(): RestResponse<RisikoStrukturKnotenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/kundenrisiko/tree` });
    }
}

export class SchadenControllerClient implements SchadenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/schaden/details-with-ansprechpartner/{id:\d+}/all
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getDetailsWithAnsprechpartner
     */
    getDetailsWithAnsprechpartner(id: number): RestResponse<DetailsWithAnsprechpartnerDto<number>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/details-with-ansprechpartner/${id}/all` });
    }

    /**
     * HTTP GET /internal/ui/schaden/details/{id:\d+}/all
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetails
     */
    getSchadenDetails(id: number): RestResponse<DetailsDto<number>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/details/${id}/all` });
    }

    /**
     * HTTP GET /internal/ui/schaden/details/{id:\d+}/posteingang
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsForPosteingang
     */
    getSchadenDetailsForPosteingang(id: number): RestResponse<DetailsDto<number>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/details/${id}/posteingang` });
    }

    /**
     * HTTP POST /internal/ui/schaden/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsXls
     */
    getSchadenDetailsXls(request: SchadenListeRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/export/excel`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schaden/liste
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenList
     */
    getSchadenList(originalRequest: SchadenListeRequestDto): RestResponse<GroupedListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/liste`, data: originalRequest });
    }

    /**
     * HTTP GET /internal/ui/schaden/liste/eigenverwaltung/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenEigenverwaltungListFilter
     */
    getSchadenEigenverwaltungListFilter(): RestResponse<SchadenListeRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/liste/eigenverwaltung/filter` });
    }

    /**
     * HTTP GET /internal/ui/schaden/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenListFilter
     */
    getSchadenListFilter(): RestResponse<SchadenListeRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/liste/filter` });
    }

    /**
     * HTTP GET /internal/ui/schaden/liste/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.permissions
     */
    permissions(): RestResponse<SchadenListePermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/liste/permissions` });
    }

    /**
     * HTTP GET /internal/ui/schaden/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/mostRecentlyViewed` });
    }

    /**
     * @deprecated
     * HTTP GET /internal/ui/schaden/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.get
     */
    get(id: number): RestResponse<SchadenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${id}` });
    }

    /**
     * HTTP POST /internal/ui/schaden/{id:\d+}/anonymize
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.anonymizeSchaden
     */
    anonymizeSchaden(id: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${id}/anonymize` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getSchadenDetailsPdf
     */
    getSchadenDetailsPdf(id: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${id}/export` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{id:\d+}/vertrag
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenController.getVertragBySchaden
     */
    getVertragBySchaden(id: number): RestResponse<SchadenVertragDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${id}/vertrag` });
    }
}

export class BriefEditorControllerClient implements BriefEditorController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/brief/briefbogen/available
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getAvailableBriefbogenForTenant
     */
    getAvailableBriefbogenForTenant(): RestResponse<IdBezeichnungDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/brief/briefbogen/available` });
    }

    /**
     * HTTP POST /internal/ui/brief/briefbogen/preview
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.generateBriefbogenPreview
     */
    generateBriefbogenPreview(briefentwurfDto: BriefentwurfDtoUnion): RestResponse<BriefPreviewDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefbogen/preview`, data: briefentwurfDto });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/create
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.createBrief
     */
    createBrief(): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/create` });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/edit
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.editBrief
     */
    editBrief(): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/edit` });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/forward
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.forwardBriefentwurf
     */
    forwardBriefentwurf(forward: ForwardRequestDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/forward`, data: forward });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/freigabe/{akteEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.setFreigabe
     */
    setFreigabe(akteEintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/freigabe/${akteEintragId}` });
    }

    /**
     * HTTP GET /internal/ui/brief/briefentwurf/freigaben/{akteEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getFreigabeview
     */
    getFreigabeview(akteEintragId: number): RestResponse<FreigabeViewDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/brief/briefentwurf/freigaben/${akteEintragId}` });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/freigabeview/save
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.saveFreigabeView
     */
    saveFreigabeView(request: BriefSendenRequestDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/freigabeview/save`, data: request });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/send
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.sendBrief
     */
    sendBrief(request: BriefSendenRequestDto): RestResponse<BriefSendenReplyDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/send`, data: request });
    }

    /**
     * HTTP POST /internal/ui/brief/briefentwurf/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getStammdatenForEditor
     */
    getStammdatenForEditor(akteRefsDto: AkteRefDto[]): RestResponse<BriefEditorStammdatenDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/brief/briefentwurf/stammdaten`, data: akteRefsDto });
    }

    /**
     * HTTP GET /internal/ui/brief/briefentwurf/{akteEintragId:\d+}/historie
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getBriefhistorie
     */
    getBriefhistorie(akteEintragId: number): RestResponse<BriefhistorieDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/brief/briefentwurf/${akteEintragId}/historie` });
    }

    /**
     * HTTP GET /internal/ui/brief/{akteEintragId:\d+}/download/zip
     * Java method: de.ecclesia.connect.webservice.controller.akte.BriefEditorController.getZippedBriefForAkteEintragId
     */
    getZippedBriefForAkteEintragId(akteEintragId: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/brief/${akteEintragId}/download/zip` });
    }
}

export class AdressbuchControllerClient implements AdressbuchController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/adressbuch/access
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.hasAdressbuchFeatureAccess
     */
    hasAdressbuchFeatureAccess(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/adressbuch/access` });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.createAdressbuchEintrag
     */
    createAdressbuchEintrag(request: AdressbuchEintragDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/anlegen`, data: request });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/connect
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.connectAdresse
     */
    connectAdresse(request: AdressbuchEintragConnectDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/connect`, data: request });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/edit
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.editAdressbuchEintrag
     */
    editAdressbuchEintrag(request: AdressbuchEintragDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/edit`, data: request });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/email/valid
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.validateEmail
     */
    validateEmail(email: string): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/email/valid`, data: email });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/liste
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressListe
     */
    getAdressListe(request: AdressbuchSucheDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/liste`, data: request });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/adresse/suche
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.sucheAdressbuchEintrag
     */
    sucheAdressbuchEintrag(request: AdressbuchSucheDto): RestResponse<AdressbuchEintragListeDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/adresse/suche`, data: request });
    }

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/box/{boxEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEitnragForBox
     */
    deleteAdressbuchEitnragForBox(adresseId: number, boxEintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/adressbuch/adresse/${adresseId}/loeschen/box/${boxEintragId}` });
    }

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/schaden/{schadenId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForSchaden
     */
    deleteAdressbuchEintragForSchaden(adresseId: number, schadenId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/adressbuch/adresse/${adresseId}/loeschen/schaden/${schadenId}` });
    }

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/type/{type}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForType
     */
    deleteAdressbuchEintragForType(adresseId: number, type: AdressbuchEintragTypDto): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/adressbuch/adresse/${adresseId}/loeschen/type/${type}` });
    }

    /**
     * HTTP DELETE /internal/ui/adressbuch/adresse/{adresseId}/loeschen/vertrag/{vertragId}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.deleteAdressbuchEintragForVertrag
     */
    deleteAdressbuchEintragForVertrag(adresseId: number, vertragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/adressbuch/adresse/${adresseId}/loeschen/vertrag/${vertragId}` });
    }

    /**
     * HTTP GET /internal/ui/adressbuch/adresse/{id}
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressbuchEintrag
     */
    getAdressbuchEintrag(id: number): RestResponse<AdressbuchEintragDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/adressbuch/adresse/${id}` });
    }

    /**
     * HTTP POST /internal/ui/adressbuch/info
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getAdressBuchInfo
     */
    getAdressBuchInfo(request: AdressbuchInfoSucheDto): RestResponse<AdressbuchInfoDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/adressbuch/info`, data: request });
    }

    /**
     * HTTP GET /internal/ui/adressbuch/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.adressbuch.AdressbuchController.getStammdaten
     */
    getStammdaten(): RestResponse<AdressbuchStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/adressbuch/stammdaten` });
    }
}

export class VertragControllerClient implements VertragController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/vertrag/details/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetails
     */
    getVertragDetails(id: number): RestResponse<VertragDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/details/${id}` });
    }

    /**
     * HTTP POST /internal/ui/vertrag/details/{id:\d+}/praemien
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getPraemienListe
     */
    getPraemienListe(id: number, request: PraemienAufteilungRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/details/${id}/praemien`, data: request });
    }

    /**
     * HTTP POST /internal/ui/vertrag/details/{id:\d+}/praemien/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getPraemienXls
     */
    getPraemienXls(id: number, listeRequest: PraemienAufteilungRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/details/${id}/praemien/export/excel`, data: listeRequest });
    }

    /**
     * HTTP GET /internal/ui/vertrag/export/csv
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragListExportAsCsv
     */
    getVertragListExportAsCsv(): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/export/csv` });
    }

    /**
     * HTTP POST /internal/ui/vertrag/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetailsXls
     */
    getVertragDetailsXls(listeRequest: VertragsListeRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/export/excel`, data: listeRequest });
    }

    /**
     * HTTP POST /internal/ui/vertrag/list
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragListe
     */
    getVertragListe(originalRequest: VertragsListeRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/list`, data: originalRequest });
    }

    /**
     * HTTP GET /internal/ui/vertrag/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragFilterForUser
     */
    getVertragFilterForUser(): RestResponse<VertragsListeRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/list/filter` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/list/kunden/user
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getAvailableKundenForUser
     */
    getAvailableKundenForUser(): RestResponse<ListeKundeOptionsDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/list/kunden/user` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/list/vertragarten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getAllVertragsArtenForRequest
     */
    getAllVertragsArtenForRequest(): RestResponse<VertragArtDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/list/vertragarten` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/mostRecentlyViewed` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/rechte
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.rechte
     */
    rechte(): RestResponse<VertragListeRechteDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/rechte` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getVertragDetailsPdf
     */
    getVertragDetailsPdf(id: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/${id}/export` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/{id:\d+}/schaeden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragController.getSchaedenByVertrag
     */
    getSchaedenByVertrag(id: number): RestResponse<VertragSchaedenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/${id}/schaeden` });
    }
}

export class SchadenReserveControllerClient implements SchadenReserveController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/cancel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.cancel
     */
    cancel(schadenId: number, cancel: ReserveCancelDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/cancel`, data: cancel });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/create
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.create
     */
    create(schadenId: number, create: ReserveCreateDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/create`, data: create });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/edit/{reserveId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.edit
     */
    edit(schadenId: number, reserveId: number, edit: ZahlungReserveEditDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/edit/${reserveId}`, data: edit });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSchadenReserven
     */
    getSchadenReserven(schadenId: number, requestDto: ReserveListRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/list`, data: requestDto });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSchadenReservenFilterForUser
     */
    getSchadenReservenFilterForUser(schadenId: string): RestResponse<ReserveListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/list/filter` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getReserveStammdaten
     */
    getReserveStammdaten(schadenId: number): RestResponse<ReserveStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/stammdaten` });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/reserve/summary
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.getSummary
     */
    getSummary(schadenId: number): RestResponse<ReserveListSummaryDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/summary` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/reserve/{reserveId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenReserveController.get
     */
    get(schadenId: number, reserveId: number): RestResponse<ZahlungReserveDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/reserve/${reserveId}` });
    }
}

export class RohdatenExportControllerClient implements RohdatenExportController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/rohdaten/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.RohdatenExportController.getRohdatenXls
     */
    getRohdatenXls(): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/rohdaten/export/excel` });
    }

    /**
     * HTTP GET /internal/ui/rohdaten/permission
     * Java method: de.ecclesia.connect.webservice.controller.RohdatenExportController.canSeeRohdatenExport
     */
    canSeeRohdatenExport(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/rohdaten/permission` });
    }
}

export class KundenSeitenControllerClient implements KundenSeitenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/kundenseite/seite/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenController.seite
     */
    seite(id: number): RestResponse<KundenSeiteDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/kundenseite/seite/${id}` });
    }

    /**
     * HTTP GET /internal/ui/kundenseite/token/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenController.downloadToken
     */
    downloadToken(id: number): RestResponse<KundenSeiteDownloadDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/kundenseite/token/${id}` });
    }
}

export class SupportControllerClient implements SupportController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/support/url
     * Java method: de.ecclesia.connect.webservice.controller.SupportController.getUrl
     */
    getUrl(): RestResponse<SupportDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/support/url` });
    }
}

export class UserPreferencesControllerClient implements UserPreferencesController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/user/preferences
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.getPreferences
     */
    getPreferences(): RestResponse<UserPreferencesMenuDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/preferences` });
    }

    /**
     * HTTP GET /internal/ui/user/preferences/default
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.getDefaultPreferences
     */
    getDefaultPreferences(): RestResponse<UserPreferencesMenuDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/preferences/default` });
    }

    /**
     * HTTP GET /internal/ui/user/preferences/reset
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.resetPreferences
     */
    resetPreferences(): RestResponse<UserPreferencesMenuDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/preferences/reset` });
    }

    /**
     * HTTP POST /internal/ui/user/preferences/update
     * Java method: de.ecclesia.connect.webservice.controller.userpreferences.UserPreferencesController.updatePreferences
     */
    updatePreferences(prefs: UserPreferencesDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/user/preferences/update`, data: prefs });
    }
}

export class ErrorPageControllerClient implements ErrorPageController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /error
     * Java method: de.ecclesia.connect.webservice.controller.ErrorPageController.getErrorPage
     */
    getErrorPage(): RestResponse<ClassPathResource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`error` });
    }

    /**
     * HTTP GET /error
     * Java method: de.ecclesia.connect.webservice.controller.ErrorPageController.getRestErrorJson
     */
    getRestErrorJson(): RestResponse<ExceptionDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`error` });
    }
}

export class SchadenEigenverwaltungControllerClient implements SchadenEigenverwaltungController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.anlegen
     */
    anlegen(request: SchadenEigenverwaltungDataDto): RestResponse<SchadenEigenverwaltungResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/anlegen`, data: request });
    }

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/bearbeiten/anfragen/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenRequest
     */
    bearbeitenRequest(id: number): RestResponse<SchadenEigenverwaltungDataDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/eigenverwaltung/bearbeiten/anfragen/${id}` });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/bearbeiten/kurzdarstellung/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenKurzdarstellungSubmit
     */
    bearbeitenKurzdarstellungSubmit(id: number, request: SchadenKurzdarstellungDataDto): RestResponse<SchadenEigenverwaltungResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/bearbeiten/kurzdarstellung/speichern/${id}`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/bearbeiten/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.bearbeitenSubmit
     */
    bearbeitenSubmit(id: number, request: SchadenEigenverwaltungDataDto): RestResponse<SchadenEigenverwaltungResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/bearbeiten/speichern/${id}`, data: request });
    }

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/bearbeiten/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<SchadenEigenverwaltungPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/eigenverwaltung/bearbeiten/${id}/permissions` });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/fahrzeugtyp
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.fahrzeugtyp
     */
    fahrzeugtyp(request: SchadenEigenverwaltungFahrzeugtypRequestDto): RestResponse<SchadenEigenverwaltungFahrzeugtypDatenDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/fahrzeugtyp`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/schadenart
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.schadenart
     */
    schadenart(request: SchadenEigenverwaltungSchadenartRequestDto): RestResponse<SchadenEigenverwaltungSchadenartDatenDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/schadenart`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/schadentyp
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.schadentyp
     */
    schadentyp(request: SchadenEigenverwaltungSchadentypRequestDto): RestResponse<SchadenEigenverwaltungSchadentypDatenDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/schadentyp`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schaden/eigenverwaltung/see
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.createSEE
     */
    createSEE(see: SeeAnforderungErstellenDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/eigenverwaltung/see`, data: see });
    }

    /**
     * HTTP GET /internal/ui/schaden/eigenverwaltung/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEigenverwaltungController.stammdaten
     */
    stammdaten(queryParams?: { ouId?: string; }): RestResponse<SchadenEigenverwaltungStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/eigenverwaltung/stammdaten`, queryParams: queryParams });
    }
}

export class AufgabenControllerClient implements AufgabenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/akte/aufgabe/store/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AufgabenController.setAufgabe
     */
    setAufgabe(id: number, aufgabeDto: LightAufgabeDtoUnion): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/aufgabe/store/${id}`, data: aufgabeDto });
    }

    /**
     * HTTP GET /internal/ui/akte/aufgabe/users/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AufgabenController.possibleUsers
     */
    possibleUsers(id: number): RestResponse<ExternalUserWithAccessDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/aufgabe/users/${id}` });
    }
}

export class PosteingangControllerClient implements PosteingangController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/posteingang/canberemoved/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.canBeRemoved
     */
    canBeRemoved(id: number): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/canberemoved/${id}` });
    }

    /**
     * HTTP DELETE /internal/ui/posteingang/eintrag/{eintragId}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.deletePosteingangEintrag
     */
    deletePosteingangEintrag(eintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/posteingang/eintrag/${eintragId}` });
    }

    /**
     * HTTP POST /internal/ui/posteingang/filter
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.filterPosteingang
     */
    filterPosteingang(filterDto: PosteingangFilterDto): RestResponse<PosteingangListeDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/posteingang/filter`, data: filterDto });
    }

    /**
     * HTTP GET /internal/ui/posteingang/filter
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getFilter
     */
    getFilter(): RestResponse<PosteingangFilterDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/filter` });
    }

    /**
     * HTTP GET /internal/ui/posteingang/filter/entryFilters
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getEntryFilters
     */
    getEntryFilters(): RestResponse<PosteingangEntryFiltersDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/filter/entryFilters` });
    }

    /**
     * HTTP POST /internal/ui/posteingang/filter/save
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.saveFilter
     */
    saveFilter(pref: PosteingangEntryFilterDtoUnion): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/posteingang/filter/save`, data: pref });
    }

    /**
     * HTTP GET /internal/ui/posteingang/get/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.get
     */
    get(id: number): RestResponse<PosteingangDetailEintragDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/get/${id}` });
    }

    /**
     * HTTP GET /internal/ui/posteingang/info
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getInfo
     */
    getInfo(): RestResponse<PosteingangListenInfoDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/info` });
    }

    /**
     * HTTP GET /internal/ui/posteingang/list/{aktenEintragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getAllForAktenEintrag
     */
    getAllForAktenEintrag(aktenEintragId: number): RestResponse<PosteingangListenEintragDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/list/${aktenEintragId}` });
    }

    /**
     * HTTP GET /internal/ui/posteingang/users
     * Java method: de.ecclesia.connect.webservice.controller.PosteingangController.getUsers
     */
    getUsers(): RestResponse<UserReferenceDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/posteingang/users` });
    }
}

export class KundenSeitenDownloadControllerClient implements KundenSeitenDownloadController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /download/kundenseite/anhang/{token}/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.kundenseite.KundenSeitenDownloadController.downloadFile
     */
    downloadFile(token: string, id: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`download/kundenseite/anhang/${token}/${id}` });
    }
}

export class SchadenMeldenControllerClient implements SchadenMeldenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/schadenmeldung
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.saveSchadenmeldung
     */
    saveSchadenmeldung(): RestResponse<SchadenmeldungAntwortDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/schadenmeldung` });
    }

    /**
     * HTTP GET /internal/schadenmeldung/formular
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getSchadenmeldungFormular
     */
    getSchadenmeldungFormular(queryParams: { schadenTypId: string; indiv?: string; }): RestResponse<SchadenmeldungFormularDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/schadenmeldung/formular`, queryParams: queryParams });
    }

    /**
     * HTTP GET /internal/schadenmeldung/formular/allgemeineDaten
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getAllgemeineDaten
     */
    getAllgemeineDaten(queryParams?: { firma?: string; indiv?: string; }): RestResponse<SchadenMeldungAllgemeineDatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/schadenmeldung/formular/allgemeineDaten`, queryParams: queryParams });
    }

    /**
     * HTTP POST /internal/schadenmeldung/result
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.schadenmeldungResult
     */
    schadenmeldungResult(token: string): RestResponse<SchadenmeldungErgebnisDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/schadenmeldung/result`, data: token });
    }

    /**
     * HTTP GET /internal/schadenmeldung/schadenmeldungformular/exists
     * Java method: de.ecclesia.connect.webservice.controller.SchadenMeldenController.getDezentraleSchadenmeldungExists
     */
    getDezentraleSchadenmeldungExists(queryParams: { uuid: string; }): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/schadenmeldung/schadenmeldungformular/exists`, queryParams: queryParams });
    }
}

export class SchadenEreignisControllerClient implements SchadenEreignisController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.update
     */
    update(request: SchadenEreignisEditDto): RestResponse<SchadenEreignisResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/aktualisieren`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/anlegen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.create
     */
    create(request: SchadenEreignisDto): RestResponse<SchadenEreignisResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/anlegen`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/details
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisDetails
     */
    getSchadenEreignisDetails(request: SchadenEreignisDetailsRequestDto): RestResponse<SchadenEreignisDetailsDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/details`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/details/pdf
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisDetailsPdf
     */
    getSchadenEreignisDetailsPdf(): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/details/pdf` });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisListXls
     */
    getSchadenEreignisListXls(request: SchadenEreignisListeRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/export/excel`, data: request });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/liste
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisList
     */
    getSchadenEreignisList(request: SchadenEreignisListeRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/liste`, data: request });
    }

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisFilterForUser
     */
    getSchadenEreignisFilterForUser(): RestResponse<SchadenEreignisListeRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schadenereignis/liste/filter` });
    }

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter/reserven
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisReservenFilterForUser
     */
    getSchadenEreignisReservenFilterForUser(): RestResponse<SchadenEreignisReserveListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schadenereignis/liste/filter/reserven` });
    }

    /**
     * HTTP GET /internal/ui/schadenereignis/liste/filter/zahlungen
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenEreignisZahlungenFilterForUser
     */
    getSchadenEreignisZahlungenFilterForUser(): RestResponse<SchadenEreignisZahlungenListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schadenereignis/liste/filter/zahlungen` });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/reserven/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenReserven
     */
    getSchadenReserven(ereignisId: number, requestDto: SchadenEreignisReserveListRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/liste/reserven/${ereignisId}`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/reserven/{ereignisId}/sum
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getReservenSumForEreignis
     */
    getReservenSumForEreignis(ereignisId: number, requestDto: SchadenEreignisReserveListRequestDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/liste/reserven/${ereignisId}/sum`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/zahlungen/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getSchadenZahlungen
     */
    getSchadenZahlungen(ereignisId: number, requestDto: SchadenEreignisZahlungenListRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/liste/zahlungen/${ereignisId}`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/schadenereignis/liste/zahlungen/{ereignisId}/sum
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getZahlungenSumForEreignis
     */
    getZahlungenSumForEreignis(ereignisId: number, requestDto: SchadenEreignisZahlungenListRequestDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schadenereignis/liste/zahlungen/${ereignisId}/sum`, data: requestDto });
    }

    /**
     * HTTP DELETE /internal/ui/schadenereignis/loeschen/{ereignisId}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.delete
     */
    delete(ereignisId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/schadenereignis/loeschen/${ereignisId}` });
    }

    /**
     * HTTP GET /internal/ui/schadenereignis/mostRecentlyViewed
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.mostRecentlyViewed
     */
    mostRecentlyViewed(): RestResponse<InternalHyperlinkDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schadenereignis/mostRecentlyViewed` });
    }

    /**
     * HTTP GET /internal/ui/schadenereignis/permissions
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenEreignisController.getPermissions
     */
    getPermissions(): RestResponse<SchadenEreignisPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schadenereignis/permissions` });
    }
}

export class StatistikControllerClient implements StatistikController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/statistik/rechte
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.rechte
     */
    rechte(): RestResponse<StatistikRechteDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/rechte` });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenStatistik
     */
    getSchadenTypenStatistik(request: SchadenTypenStatistikRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/typen`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenStatistikXls
     */
    getSchadenTypenStatistikXls(request: SchadenTypenStatistikRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/typen/export/excel`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/typen/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypStatistikPdf
     */
    getSchadenTypStatistikPdf(): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/typen/export/pdf` });
    }

    /**
     * HTTP GET /internal/ui/statistik/schaden/typen/filter
     * Java method: de.ecclesia.connect.webservice.controller.statistik.StatistikController.getSchadenTypenFilterForUser
     */
    getSchadenTypenFilterForUser(): RestResponse<SchadenTypenStatistikRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/schaden/typen/filter` });
    }
}

export class AkteControllerClient implements AkteController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/akte/anhaenge
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.downloadAnhaengeAsZip
     */
    downloadAnhaengeAsZip(request: AnhaengeZipDownloadRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/anhaenge`, data: request });
    }

    /**
     * HTTP POST /internal/ui/akte/anhang
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.downloadSingleAkteneintragAsZip
     */
    downloadSingleAkteneintragAsZip(request: AktenEintragAnhaengeZipDownloadRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/anhang`, data: request });
    }

    /**
     * HTTP GET /internal/ui/akte/anhang/{anhangId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getForDownload
     */
    getForDownload(anhangId: number): RestResponse<AktenAnhangDownloadDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/anhang/${anhangId}` });
    }

    /**
     * HTTP GET /internal/ui/akte/eintrag/anhang/{anhangId}/{seite}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenEintragAnnotations
     */
    getAktenEintragAnnotations(anhangId: number, seite: number): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/eintrag/anhang/${anhangId}/${seite}` });
    }

    /**
     * HTTP POST /internal/ui/akte/eintrag/permission/write
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getCreatePermissions
     */
    getCreatePermissions(akteRefsDto: AkteRefDto[]): RestResponse<AktePermissionsDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/eintrag/permission/write`, data: akteRefsDto });
    }

    /**
     * HTTP DELETE /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.deleteAktenEintrag
     */
    deleteAktenEintrag(aktenEintragId: number, version: TempWithVersionDto): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/akte/eintrag/${aktenEintragId}`, data: version });
    }

    /**
     * HTTP GET /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenEintrag
     */
    getAktenEintrag(aktenEintragId: number): RestResponse<AktenEintragDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/eintrag/${aktenEintragId}` });
    }

    /**
     * HTTP POST /internal/ui/akte/filter/dateitypen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAvailableDateitypen
     */
    getAvailableDateitypen(akteRefs: AkteRefDto[]): RestResponse<DateiTypenListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/filter/dateitypen`, data: akteRefs });
    }

    /**
     * HTTP POST /internal/ui/akte/liste
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenListeForFilter
     */
    getAktenListeForFilter(akteListePagedRequestDto: AkteListePagedRequestDto): RestResponse<AktenListeDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/liste`, data: akteListePagedRequestDto });
    }

    /**
     * HTTP POST /internal/ui/akte/liste/akteRefs
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAktenListeForAkteRefs
     */
    getAktenListeForAkteRefs(akteRefs: AkteRefDto[]): RestResponse<AktenListeDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/liste/akteRefs`, data: akteRefs });
    }

    /**
     * HTTP GET /internal/ui/akte/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAkteFilterForUser
     */
    getAkteFilterForUser(): RestResponse<AkteListePagedRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/liste/filter` });
    }

    /**
     * HTTP GET /internal/ui/akte/liste/kunden/user
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getAvailableKundenForUser
     */
    getAvailableKundenForUser(): RestResponse<ListeKundeOptionsDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/liste/kunden/user` });
    }

    /**
     * HTTP POST /internal/ui/akte/notiz/benutzerliste
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.getPossibleUsersForNotiz
     */
    getPossibleUsersForNotiz(dto: DomainObjectIdDto): RestResponse<MinimalExternalUserDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/notiz/benutzerliste`, data: dto });
    }

    /**
     * HTTP GET /internal/ui/akte/users/{id}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteController.possibleUsers
     */
    possibleUsers(id: number): RestResponse<ExternalUserWithAccessDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/users/${id}` });
    }
}

export class TextbausteinControllerClient implements TextbausteinController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/textbaustein/create
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.createTextbaustein
     */
    createTextbaustein(textbausteinErstellenDto: TextbausteinErstellenDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/create`, data: textbausteinErstellenDto });
    }

    /**
     * HTTP DELETE /internal/ui/textbaustein/delete/{textbausteinId}
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.deleteTextbaustein
     */
    deleteTextbaustein(textbausteinId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/textbaustein/delete/${textbausteinId}` });
    }

    /**
     * HTTP POST /internal/ui/textbaustein/edit
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.editTextbaustein
     */
    editTextbaustein(textbausteinDto: TextbausteinDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/edit`, data: textbausteinDto });
    }

    /**
     * HTTP GET /internal/ui/textbaustein/list/allVariables
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listAllVariablesForUser
     */
    listAllVariablesForUser(): RestResponse<TextbausteinVariableDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/textbaustein/list/allVariables` });
    }

    /**
     * HTTP GET /internal/ui/textbaustein/list/raw
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserRaw
     */
    listTextbausteineForUserRaw(): RestResponse<TextbausteinDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/textbaustein/list/raw` });
    }

    /**
     * HTTP POST /internal/ui/textbaustein/list/replaced
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserReplaced
     */
    listTextbausteineForUserReplaced(request: TextbausteinRequestDto): RestResponse<TextbausteinDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/list/replaced`, data: request });
    }

    /**
     * HTTP POST /internal/ui/textbaustein/list/replaced/brief
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteineForUserReplacedBrief
     */
    listTextbausteineForUserReplacedBrief(request: BriefeditorTextbausteinRequestDto): RestResponse<TextbausteinDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/list/replaced/brief`, data: request });
    }

    /**
     * HTTP POST /internal/ui/textbaustein/list/variables
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteinVariablesForUser
     */
    listTextbausteinVariablesForUser(request: TextbausteinRequestDto): RestResponse<TextbausteinVariableDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/list/variables`, data: request });
    }

    /**
     * HTTP POST /internal/ui/textbaustein/list/variables/brief
     * Java method: de.ecclesia.connect.webservice.controller.TextbausteinController.listTextbausteinVariablesForUserBrief
     */
    listTextbausteinVariablesForUserBrief(request: BriefeditorTextbausteinRequestDto): RestResponse<TextbausteinVariableDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/textbaustein/list/variables/brief`, data: request });
    }
}

export class ZahlungsuebersichtControllerClient implements ZahlungsuebersichtController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/zahlungsuebersicht/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getSchadenZahlungen
     */
    getSchadenZahlungen(requestDto: ZahlungsuebersichtRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/zahlungsuebersicht/list`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/zahlungsuebersicht/list/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getZahlungsuebersichtXls
     */
    getZahlungsuebersichtXls(requestDto: ZahlungsuebersichtRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/zahlungsuebersicht/list/export/excel`, data: requestDto });
    }

    /**
     * HTTP GET /internal/ui/zahlungsuebersicht/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.getSchadenZahlungenFilterForUser
     */
    getSchadenZahlungenFilterForUser(): RestResponse<ZahlungsuebersichtRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/zahlungsuebersicht/list/filter` });
    }

    /**
     * HTTP GET /internal/ui/zahlungsuebersicht/list/permission
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ZahlungsuebersichtController.canSeeZahlungsuebersicht
     */
    canSeeZahlungsuebersicht(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/zahlungsuebersicht/list/permission` });
    }
}

export class DashboardControllerClient implements DashboardController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/dashboard/all
     * Java method: de.ecclesia.connect.webservice.controller.DashboardController.getDashboardInformation
     */
    getDashboardInformation(): RestResponse<DashboardDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/dashboard/all` });
    }
}

export class ReserveUebersichtControllerClient implements ReserveUebersichtController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/reserveuebersicht/export
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.export
     */
    export(request: ReserveUebersichtRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/reserveuebersicht/export`, data: request });
    }

    /**
     * HTTP GET /internal/ui/reserveuebersicht/list/permission
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.canSeeReserveuebersicht
     */
    canSeeReserveuebersicht(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/reserveuebersicht/list/permission` });
    }

    /**
     * HTTP GET /internal/ui/reserveuebersicht/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.ReserveUebersichtController.stammdaten
     */
    stammdaten(): RestResponse<ReserveUebersichtStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/reserveuebersicht/stammdaten` });
    }
}

export class SchadenStatistikControllerClient implements SchadenStatistikController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/anzahldreieck/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getAnzahldreieckExport
     */
    getAnzahldreieckExport(anzahldreieckRequest: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/anzahldreieck/export/xls`, data: anzahldreieckRequest });
    }

    /**
     * HTTP DELETE /internal/ui/statistik/schaden/auswertung/delete/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.deleteStatisticsEvaluation
     */
    deleteStatisticsEvaluation(id: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/statistik/schaden/auswertung/delete/${id}` });
    }

    /**
     * HTTP GET /internal/ui/statistik/schaden/auswertung/load/all
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getStatisticsEvaluations
     */
    getStatisticsEvaluations(): RestResponse<StatistikAuswertungListsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/schaden/auswertung/load/all` });
    }

    /**
     * HTTP GET /internal/ui/statistik/schaden/auswertung/load/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getStatisticsEvaluation
     */
    getStatisticsEvaluation(id: number): RestResponse<StatistikAuswertungDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/schaden/auswertung/load/${id}` });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/auswertung/save
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.saveStatisticsEvaluation
     */
    saveStatisticsEvaluation(evaluation: StatistikAuswertungDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/auswertung/save`, data: evaluation });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/auswertung/update
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.updateStatisticsEvaluation
     */
    updateStatisticsEvaluation(evaluation: StatistikAuswertungDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/auswertung/update`, data: evaluation });
    }

    /**
     * HTTP GET /internal/ui/statistik/schaden/available
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getAvailableStatistics
     */
    getAvailableStatistics(): RestResponse<AvailableStatistikCriteriaDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/schaden/available` });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/available/options
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getFilterOptionsForFilter
     */
    getFilterOptionsForFilter(requestDto: FilterOptionsRequestDto): RestResponse<FilterWithOptionsDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/available/options`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/chainladder/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getChainladderExport
     */
    getChainladderExport(chainladderStatistikDto: ChainladderStatistikDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/chainladder/export/xls`, data: chainladderStatistikDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getDiagram
     */
    getDiagram(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<StatistikDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/diagram`, data: statistikDefinitionDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/count
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getResultSchadenCount
     */
    getResultSchadenCount(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/diagram/count`, data: statistikDefinitionDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getPdfExport
     */
    getPdfExport(): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/diagram/export/pdf` });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getExcelExport
     */
    getExcelExport(statistikDefinitionDto: StatistikDefinitionDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/diagram/export/xls`, data: statistikDefinitionDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/diagram/list/sums
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSumsForHeaders
     */
    getSumsForHeaders(statistikSchadenListRequestDto: StatistikSchadenListRequestDto): RestResponse<ListSumsDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/diagram/list/sums`, data: statistikSchadenListRequestDto });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/kurzfassung/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getKurzfassungPdf
     */
    getKurzfassungPdf(request: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/kurzfassung/export/pdf`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/kurzfassung/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getKurzfassungExcelExport
     */
    getKurzfassungExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/kurzfassung/export/xls`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/list
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenList
     */
    getSchadenList(request: StatistikSchadenListRequestDto): RestResponse<GroupedListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/list`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/list/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getListExcelExport
     */
    getListExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/list/export/xls`, data: request });
    }

    /**
     * HTTP GET /internal/ui/statistik/schaden/liste/filter
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenFilterForUser
     */
    getSchadenFilterForUser(): RestResponse<StatistikSchadenListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/statistik/schaden/liste/filter` });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/schadenuebersicht/export/pdf
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenuebersichtPdfExport
     */
    getSchadenuebersichtPdfExport(request: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/schadenuebersicht/export/pdf`, data: request });
    }

    /**
     * HTTP POST /internal/ui/statistik/schaden/schadenuebersicht/export/xls
     * Java method: de.ecclesia.connect.webservice.controller.statistik.v2.SchadenStatistikController.getSchadenuebersichtExcelExport
     */
    getSchadenuebersichtExcelExport(request: StatistikSchadenListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/statistik/schaden/schadenuebersicht/export/xls`, data: request });
    }
}

export class AkteEditControllerClient implements AkteEditController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/akte/eintrag/anhang/{anhangId}/{seite}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.editAktenEintragAnnotations
     */
    editAktenEintragAnnotations(anhangId: number, seite: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/eintrag/anhang/${anhangId}/${seite}` });
    }

    /**
     * HTTP POST /internal/ui/akte/eintrag/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.editAktenEintrag
     */
    editAktenEintrag(aktenEintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/eintrag/${aktenEintragId}` });
    }

    /**
     * HTTP POST /internal/ui/akte/eintrag/{aktenEintragId}/rename/anhang
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.renameAnhang
     */
    renameAnhang(aktenEintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/eintrag/${aktenEintragId}/rename/anhang` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/email/ablegen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueEmailsAblegen
     */
    neueEmailsAblegen(): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/email/ablegen` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/email/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueEmailErstellen
     */
    neueEmailErstellen(): RestResponse<AktenAnhangDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/email/erstellen` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueMaklernachricht
     */
    neueMaklernachricht$POST$internal_ui_akte_neu_maklernachricht(): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/maklernachricht` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht/adressen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.maklernachrichtAdressen
     */
    maklernachrichtAdressen(requestDto: MaklernachrichtAdressenRequestDto): RestResponse<MaklernachrichtEmpfaengerDto[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/maklernachricht/adressen`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/maklernachricht/{respondToId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueMaklernachricht
     */
    neueMaklernachricht$POST$internal_ui_akte_neu_maklernachricht_respondToId(respondToId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/maklernachricht/${respondToId}` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/notiz
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueNotiz
     */
    neueNotiz(): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/notiz` });
    }

    /**
     * HTTP POST /internal/ui/akte/neu/schadenmeldung
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueSchadenmeldung
     */
    neueSchadenmeldung(): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/neu/schadenmeldung` });
    }

    /**
     * HTTP POST /internal/ui/akte/notiz/teilen/{aktenEintragId}
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.forwardNotiz
     */
    forwardNotiz(aktenEintragId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/notiz/teilen/${aktenEintragId}` });
    }

    /**
     * HTTP POST /internal/ui/akte/schlagworte
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.getSchlagworte
     */
    getSchlagworte(akteRefs: AkteRefDto[]): RestResponse<string[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/schlagworte`, data: akteRefs });
    }

    /**
     * HTTP POST /internal/ui/akte/schlagworte/filter
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.getSchlagworteForFilter
     */
    getSchlagworteForFilter(akteRefs: AkteRefDto[]): RestResponse<string[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/schlagworte/filter`, data: akteRefs });
    }

    /**
     * HTTP GET /internal/ui/akte/schlagworte/hasTenantSchlagworte
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.hasTenantSchlagworte
     */
    hasTenantSchlagworte(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/schlagworte/hasTenantSchlagworte` });
    }

    /**
     * HTTP GET /internal/ui/akte/schlagworte/permission/read
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.canReadSchlagworte
     */
    canReadSchlagworte(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/akte/schlagworte/permission/read` });
    }

    /**
     * HTTP POST /internal/ui/akte/teilen
     * Java method: de.ecclesia.connect.webservice.controller.akte.AkteEditController.neueForwardLight
     */
    neueForwardLight(forward: AkteCreateForwardLightDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/akte/teilen`, data: forward });
    }
}

export class TerminControllerClient implements TerminController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/termin/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTermin
     */
    updateTermin(dto: TerminEditDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/aktualisieren`, data: dto });
    }

    /**
     * HTTP GET /internal/ui/termin/benutzerliste
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getPossibleUsers
     */
    getPossibleUsers(): RestResponse<MinimalExternalUserDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/benutzerliste` });
    }

    /**
     * HTTP POST /internal/ui/termin/count
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTerminCount
     */
    getTerminCount(requestDto: PosteingangTerminListePagedRequestDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/count`, data: requestDto });
    }

    /**
     * HTTP POST /internal/ui/termin/erledigen/{terminId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.closeTermin
     */
    closeTermin(terminId: number, version: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/erledigen/${terminId}/${version}` });
    }

    /**
     * HTTP POST /internal/ui/termin/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.createTermin
     */
    createTermin(dto: TerminCreateDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/erstellen`, data: dto });
    }

    /**
     * HTTP POST /internal/ui/termin/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermineExcelExport
     */
    getTermineExcelExport(requestDto: AbstractTerminListePagedRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/export/excel`, data: requestDto });
    }

    /**
     * HTTP GET /internal/ui/termin/filter
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getPosteingangFilter
     */
    getPosteingangFilter(): RestResponse<PosteingangTerminListePagedRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/filter` });
    }

    /**
     * HTTP GET /internal/ui/termin/gruende
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getGruende
     */
    getGruende(): RestResponse<TerminGrundDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/gruende` });
    }

    /**
     * HTTP POST /internal/ui/termin/list
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermine
     */
    getTermine(requestDto: AbstractTerminListePagedRequestDto): RestResponse<TerminListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/list`, data: requestDto });
    }

    /**
     * HTTP GET /internal/ui/termin/mostRecentlyUpdated
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.mostRecentlyUpdated
     */
    mostRecentlyUpdated(): RestResponse<InternalHyperlinkDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/mostRecentlyUpdated` });
    }

    /**
     * HTTP POST /internal/ui/termin/terminFormData
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTerminFormData
     */
    getTerminFormData(dto: TerminReferencesDto): RestResponse<TerminFormDataDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/terminFormData`, data: dto });
    }

    /**
     * HTTP POST /internal/ui/termin/todo/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTodo
     */
    updateTodo(dto: TerminTodoDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/todo/aktualisieren`, data: dto });
    }

    /**
     * HTTP DELETE /internal/ui/termin/todo/{todoId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteTodo
     */
    deleteTodo(todoId: number, version: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/termin/todo/${todoId}/${version}` });
    }

    /**
     * HTTP POST /internal/ui/termin/vorlage/aktualisieren
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.updateTerminVorlage
     */
    updateTerminVorlage(dto: TerminVorlageEditDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/vorlage/aktualisieren`, data: dto });
    }

    /**
     * HTTP POST /internal/ui/termin/vorlage/erstellen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.createTerminVorlage
     */
    createTerminVorlage(dto: TerminVorlageCreateDto): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/termin/vorlage/erstellen`, data: dto });
    }

    /**
     * HTTP GET /internal/ui/termin/vorlage/{vorlageId}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getVorlage
     */
    getVorlage(vorlageId: number): RestResponse<TerminVorlageDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/vorlage/${vorlageId}` });
    }

    /**
     * HTTP DELETE /internal/ui/termin/vorlage/{vorlageId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteVorlage
     */
    deleteVorlage(vorlageId: number, version: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/termin/vorlage/${vorlageId}/${version}` });
    }

    /**
     * HTTP GET /internal/ui/termin/vorlagen
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getVorlagen
     */
    getVorlagen(): RestResponse<ListeDto<TerminVorlageDto>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/vorlagen` });
    }

    /**
     * HTTP GET /internal/ui/termin/{terminId}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.getTermin
     */
    getTermin(terminId: number): RestResponse<TerminDetailsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/termin/${terminId}` });
    }

    /**
     * HTTP DELETE /internal/ui/termin/{terminId}/{version}
     * Java method: de.ecclesia.connect.webservice.controller.TerminController.deleteTermin
     */
    deleteTermin(terminId: number, version: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`internal/ui/termin/${terminId}/${version}` });
    }
}

export class UserControllerClient implements UserController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/user/bedingung/accept
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.acceptBundle
     */
    acceptBundle(ids: number[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/user/bedingung/accept`, data: ids });
    }

    /**
     * HTTP GET /internal/ui/user/bedingung/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getBedingungFile
     */
    getBedingungFile(id: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/bedingung/${id}` });
    }

    /**
     * HTTP GET /internal/ui/user/current
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getConnectUser
     */
    getConnectUser(): RestResponse<LoginInfoDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/current` });
    }

    /**
     * HTTP GET /internal/ui/user/rights/aktetypen
     * Java method: de.ecclesia.connect.webservice.controller.user.UserController.getAvailableAkteTypenForCurrentUser
     */
    getAvailableAkteTypenForCurrentUser(): RestResponse<AkteTypenRightDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/user/rights/aktetypen` });
    }
}

export class VertragsanliegenControllerClient implements VertragsanliegenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/vertragsanliegen/send
     * Java method: de.ecclesia.connect.webservice.controller.VertragsanliegenController.send
     */
    send(): RestResponse<VertragsanliegenResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertragsanliegen/send` });
    }

    /**
     * HTTP GET /internal/ui/vertragsanliegen/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.VertragsanliegenController.getVersicherungsarten
     */
    getVersicherungsarten(queryParams?: { vertragId?: number; }): RestResponse<VertragsanliegenStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertragsanliegen/stammdaten`, queryParams: queryParams });
    }
}

export class FakeOauthControllerClient implements FakeOauthController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /fakeoauth2/hints
     * Java method: de.ecclesia.connect.webservice.fakeuser.FakeOauthController.hints
     */
    hints(): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`fakeoauth2/hints` });
    }

    /**
     * HTTP GET /fakeoauth2/hints/{userId}
     * Java method: de.ecclesia.connect.webservice.fakeuser.FakeOauthController.hintsForUser
     */
    hintsForUser(userId: number): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`fakeoauth2/hints/${userId}` });
    }
}

export class KfzAnAbmeldenControllerClient implements KfzAnAbmeldenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/vertrag/kfz/anmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.anmelden
     */
    anmelden(): RestResponse<KfzAnmeldenResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/kfz/anmelden` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/kfz/anmelden/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.anmeldenStammdaten
     */
    anmeldenStammdaten(): RestResponse<KfzAnmeldenStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/kfz/anmelden/stammdaten` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/kfz/fahrzeugwechsel/stammdaten/{vertragId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.fahrzeugwechselStammdaten
     */
    fahrzeugwechselStammdaten(vertragId: number): RestResponse<KfzFahrzeugwechselStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/kfz/fahrzeugwechsel/stammdaten/${vertragId}` });
    }

    /**
     * HTTP POST /internal/ui/vertrag/kfz/fahrzeugwechsel/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.fahrzeugwechsel
     */
    fahrzeugwechsel(id: number): RestResponse<KfzAnmeldenResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/kfz/fahrzeugwechsel/${id}` });
    }

    /**
     * HTTP POST /internal/ui/vertrag/kfz/{id:\d+}/abmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.abmelden
     */
    abmelden$POST$internal_ui_vertrag_kfz_id_abmelden(id: number): RestResponse<VertragKfzAbmeldenResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/kfz/${id}/abmelden` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/kfz/{id:\d+}/abmelden
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.KfzAnAbmeldenController.abmelden
     */
    abmelden$GET$internal_ui_vertrag_kfz_id_abmelden(id: number): RestResponse<VertragKfzAbmeldenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/kfz/${id}/abmelden` });
    }
}

export class UpdateInfoControllerClient implements UpdateInfoController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/updateinfo/hide/{updateInfoId}
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.hide
     */
    hide(updateInfoId: number): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/updateinfo/hide/${updateInfoId}` });
    }

    /**
     * HTTP GET /internal/ui/updateinfo/listAll
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.listAll
     */
    listAll(): RestResponse<UpdateInfoDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/updateinfo/listAll` });
    }

    /**
     * HTTP GET /internal/ui/updateinfo/listUnread
     * Java method: de.ecclesia.connect.webservice.controller.UpdateInfoController.listUnread
     */
    listUnread(): RestResponse<UpdateInfoDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/updateinfo/listUnread` });
    }
}

export class SchadenZahlungenControllerClient implements SchadenZahlungenController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/cancel
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.cancel
     */
    cancel(schadenId: number, cancel: ZahlungCancelDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/cancel`, data: cancel });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/create
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.create
     */
    create(schadenId: number, create: ZahlungCreateDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/create`, data: create });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/edit/{zahlungId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.edit
     */
    edit(schadenId: number, zahlungId: number, edit: ZahlungEditDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/edit/${zahlungId}`, data: edit });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/list
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSchadenZahlungen
     */
    getSchadenZahlungen(schadenId: number, requestDto: ZahlungListRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/list`, data: requestDto });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSchadenZahlungenFilterForUser
     */
    getSchadenZahlungenFilterForUser(schadenId: string): RestResponse<ZahlungListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/list/filter` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.stammdaten
     */
    stammdaten(schadenId: number): RestResponse<ZahlungStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/stammdaten` });
    }

    /**
     * HTTP POST /internal/ui/schaden/{schadenId:\d+}/zahlungen/summary
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.getSummary
     */
    getSummary(schadenId: number): RestResponse<ZahlungListSummaryDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/summary` });
    }

    /**
     * HTTP GET /internal/ui/schaden/{schadenId:\d+}/zahlungen/{zahlungId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.schaden.SchadenZahlungenController.get
     */
    get(schadenId: number, zahlungId: number): RestResponse<SchadenZahlungDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/schaden/${schadenId}/zahlungen/${zahlungId}` });
    }
}

export class BoxControllerClient implements BoxController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /internal/ui/box/create
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.createBoxEntry
     */
    createBoxEntry(request: BoxDataDto): RestResponse<BoxCreateResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/box/create`, data: request });
    }

    /**
     * HTTP GET /internal/ui/box/details/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryDetails
     */
    getBoxEntryDetails(id: number): RestResponse<DetailsDto<number>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/details/${id}` });
    }

    /**
     * HTTP GET /internal/ui/box/details/{id:\d+}/status
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryStatus
     */
    getBoxEntryStatus(id: number): RestResponse<BoxDataDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/details/${id}/status` });
    }

    /**
     * HTTP GET /internal/ui/box/edit/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryEditFormData
     */
    getBoxEntryEditFormData(id: number): RestResponse<BoxDataDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/edit/${id}` });
    }

    /**
     * HTTP POST /internal/ui/box/export/excel
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxDetailsXls
     */
    getBoxDetailsXls(request: BoxListRequestDto): RestResponse<Resource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/box/export/excel`, data: request });
    }

    /**
     * HTTP POST /internal/ui/box/list
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getList
     */
    getList(request: BoxListRequestDto): RestResponse<ListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/box/list`, data: request });
    }

    /**
     * HTTP GET /internal/ui/box/list/filter
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListFilter
     */
    getListFilter(): RestResponse<BoxListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/list/filter` });
    }

    /**
     * HTTP GET /internal/ui/box/list/filter/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListFilterForThema
     */
    getListFilterForThema(themaId: number): RestResponse<BoxListRequestDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/list/filter/${themaId}` });
    }

    /**
     * HTTP GET /internal/ui/box/list/permissions
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListPermissions
     */
    getListPermissions(): RestResponse<BoxListPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/list/permissions` });
    }

    /**
     * HTTP GET /internal/ui/box/list/permissions/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getListPermissionsForThema
     */
    getListPermissionsForThema(themaId: number): RestResponse<BoxListPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/list/permissions/${themaId}` });
    }

    /**
     * HTTP GET /internal/ui/box/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getMasterDataWithoutThema
     */
    getMasterDataWithoutThema(): RestResponse<BoxStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/stammdaten` });
    }

    /**
     * HTTP GET /internal/ui/box/stammdaten/{themaId:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getMasterDataByThema
     */
    getMasterDataByThema(themaId: number): RestResponse<BoxStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/stammdaten/${themaId}` });
    }

    /**
     * HTTP GET /internal/ui/box/themen
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getThemenForUser
     */
    getThemenForUser(): RestResponse<IdBezeichnungDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/themen` });
    }

    /**
     * HTTP POST /internal/ui/box/update/{id:\d+}
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.updateBoxEntry
     */
    updateBoxEntry(id: number, request: BoxDataDto): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/box/update/${id}`, data: request });
    }

    /**
     * HTTP GET /internal/ui/box/update/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<BoxPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/update/${id}/permissions` });
    }

    /**
     * HTTP GET /internal/ui/box/{id:\d+}/export
     * Java method: de.ecclesia.connect.webservice.controller.box.BoxController.getBoxEntryDetailsPdf
     */
    getBoxEntryDetailsPdf(id: number): RestResponse<Resource> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/box/${id}/export` });
    }
}

export class VertragEigenverwaltungControllerClient implements VertragEigenverwaltungController {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/bearbeiten/anfragen/{id}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.bearbeitenRequest
     */
    bearbeitenRequest(id: number): RestResponse<VertragEigenverwaltungDataDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/eigenverwaltung/bearbeiten/anfragen/${id}` });
    }

    /**
     * HTTP POST /internal/ui/vertrag/eigenverwaltung/bearbeiten/speichern/{id}
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.bearbeitenSubmit
     */
    bearbeitenSubmit(id: number, request: VertragEigenverwaltungDataDto): RestResponse<VertragEigenverwaltungResultDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`internal/ui/vertrag/eigenverwaltung/bearbeiten/speichern/${id}`, data: request });
    }

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/bearbeiten/{id:\d+}/permissions
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.getPermissionsOnDetails
     */
    getPermissionsOnDetails(id: number): RestResponse<VertragEigenverwaltungPermissionsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/eigenverwaltung/bearbeiten/${id}/permissions` });
    }

    /**
     * HTTP GET /internal/ui/vertrag/eigenverwaltung/stammdaten
     * Java method: de.ecclesia.connect.webservice.controller.vertrag.VertragEigenverwaltungController.stammdaten
     */
    stammdaten(): RestResponse<VertragEigenverwaltungStammdatenDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`internal/ui/vertrag/eigenverwaltung/stammdaten` });
    }
}

export type RestResponse<R> = Observable<R>;

export type AbstractAkteUploadListItemDtoUnion = AkteUploadListItemExistingDto | AkteUploadListItemUploadDto;

export type LightAufgabeDtoUnion = AufgabeDto;

export type BriefentwurfDtoUnion = BriefentwurfDto;

export type AbstractAkteEditDtoUnion = AkteEditSchlagworteDto | AkteEditSchadenmeldungDto;

export type AkteEditSchlagworteDtoUnion = AkteEditNotizDto | AkteEditEmailDto | AkteEditBriefDto | AkteEditMaklernachrichtDto;

export type AbstractEvbAbrufRequestWagnisDtoUnion = EvbAbrufRequestWagnisPredefinedDto | EvbAbrufRequestWagnisSonstigeDto;

export type AbstractEvbAbrufResponseDtoUnion = EvbAbrufResponseFehlgeschlagenDto | EvbAbrufResponseSuccessDto;

export type PosteingangEntryFilterDtoUnion = PosteingangSchadenFilterDto | PosteingangVertragFilterDto | SchadenTenantFeldListDto | VertragTenantFeldListDto | PosteingangBoxFilterDto | BoxTenantFeldListDto | SchadenEreignisFeldListDto;

export type AbstractSchadenmeldungFormularTargetDtoUnion = SchadenmeldungFormularTargetKundenDto | SchadenmeldungFormularTargetLzrDto | SchadenmeldungFormularTargetOuDto;

export type AbstractFieldConfigDtoUnion = FieldConfigAdresseDto | FieldConfigCheckboxDto | FieldConfigComboboxDto | FieldConfigDateDto | FieldConfigHeadlineDto | FieldConfigInputDto | FieldConfigKennzeichenDto | FieldConfigListDto | FieldConfigHinweisDto | FieldConfigNumericInputDto | FieldConfigInputAreaDto | FieldConfigBigDecimalInputDto | FieldConfigTimeDto;

export type AbstractSchadenmeldungTargetDtoUnion = SchadenmeldungTargetKundeDto | SchadenmeldungTargetLzrDto | SchadenmeldungTargetOuDto;

export type IBriefbogenUnion = BriefbogenListEntryDto;

export type AbstractFieldVisibilityConditionUnion = FieldVisibilityConditionSchadenartDto | FieldVisibilityConditionHasValueDto | FieldVisibilityConditionSchadentypDto | FieldVisibilityConditionNotDto | FieldVisibilityConditionAlwaysDto;

export const enum KundeListColumnDto {
}

export const enum RisikoStrukturKnotenTypDto {
    OU = "OU",
    RISIKO = "RISIKO",
}

export const enum SortOrderDto {
    ASCENDING = "ascending",
    DESCENDING = "descending",
}

export const enum AdressbuchEintragRefTypDto {
    SCHADEN = "SCHADEN",
    VERTRAG = "VERTRAG",
    KUNDENAKTE = "KUNDENAKTE",
    SCHADENEREIGNIS = "SCHADENEREIGNIS",
}

export const enum AdressbuchEintragTypDto {
    TENANT = "TENANT",
    PERSONAL = "PERSONAL",
}

export const enum AdressbuchListeSpalteDto {
    NAME1 = "name1",
    NAME2 = "name2",
    NAME3 = "name3",
    STRASSE = "strasse",
    HAUSNUMMER = "hausnummer",
    PLZ = "plz",
    ORT = "ort",
    LAND = "land",
    EMAIL = "email",
    TELEFON = "telefon",
    POSITION = "position",
    TITEL = "titel",
    MOBIL = "mobil",
    VORNAME = "vorname",
    NACHNAME = "nachname",
    UNTERNEHMEN = "unternehmen",
    ANSCHRIFT = "anschrift",
}

export const enum AdressbuchSucheTypDto {
    ALL = "ALL",
    PERSONAL = "PERSONAL",
}

export const enum AkteListeTypRequestDto {
    VERTRAG = "VERTRAG",
    SCHADEN = "SCHADEN",
    ALLGEMEIN = "ALLGEMEIN",
}

export const enum AktePermissionDto {
    UPLOAD_EMAIL = "UPLOAD_EMAIL",
    CREATE_EMAIL = "CREATE_EMAIL",
    CREATE_BRIEF = "CREATE_BRIEF",
    CREATE_MAKLERNACHRICHT = "CREATE_MAKLERNACHRICHT",
    CREATE_MAKLER_REGULIERER_NACHRICHT = "CREATE_MAKLER_REGULIERER_NACHRICHT",
    CREATE_NOTIZ = "CREATE_NOTIZ",
    USE_SCHLAGWORT_FILTER = "USE_SCHLAGWORT_FILTER",
    USE_DATEITYP_FILTER = "USE_DATEITYP_FILTER",
    USE_TEXTBAUSTEINE = "USE_TEXTBAUSTEINE",
    CREATE_MARKIERUNG = "CREATE_MARKIERUNG",
}

export const enum AkteRefTypDto {
    KUNDE = "KUNDE",
    VERTRAG = "VERTRAG",
    SCHADEN = "SCHADEN",
    KUNDENAKTE = "KUNDENAKTE",
}

export const enum AkteSortDto {
    DATUM = "datum",
    VORGANGSTYP = "vorgangstyp",
    BETREFF = "betreff",
    ANHANG = "anhang",
}

export const enum AktenEintragListColumnDto {
    AKTEN_EINTRAG_VON_NAME = "akten_eintrag_von_name",
    AKTEN_EINTRAG_AN_POSTKORB = "akten_eintrag_an_postkorb",
    AKTEN_EINTRAG_AN_NAME = "akten_eintrag_an_name",
    AKTEN_EINTRAG_BETREFF = "akten_eintrag_betreff",
    AKTEN_EINTRAG_NACHRICHT = "akten_eintrag_nachricht",
    AKTEN_EINTRAG_GESENDET_AM = "akten_eintrag_gesendet_am",
    AKTEN_EINTRAG_TYP = "akten_eintrag_typ",
    AKTEN_EINTRAG_WEITERGELEITET_AM = "akten_eintrag_weitergeleitet_am",
    AKTEN_EINTRAG_GEANTWORTET_AM = "akten_eintrag_geantwortet_am",
    AKTEN_EINTRAG_GELOESCHT = "akten_eintrag_geloescht",
}

export const enum AktenTypDto {
    BRIEFENTWURF = "BRIEFENTWURF",
    BRIEF = "BRIEF",
    NOTIZ = "NOTIZ",
    EMAIL = "EMAIL",
    MAKLERNACHRICHT = "MAKLERNACHRICHT",
    SCHADENMELDUNG = "SCHADENMELDUNG",
    VERSICHERERNACHRICHT = "VERSICHERERNACHRICHT",
}

export const enum DateiTypDto {
    MAKLERNACHRICHT = "MAKLERNACHRICHT",
    VERSICHERERNACHRICHT = "VERSICHERERNACHRICHT",
    BRIEF = "BRIEF",
    BRIEFENTWURF = "BRIEFENTWURF",
    SCHADENMELDUNG = "SCHADENMELDUNG",
    NOTIZ = "NOTIZ",
    EMAIL = "EMAIL",
}

export const enum MaklerNachrichtTargetDto {
    MAKLER = "MAKLER",
    VERSICHERER = "VERSICHERER",
    REGULIERER = "REGULIERER",
}

export const enum PosteingangListenEintragTypDto {
    VORGANG = "VORGANG",
    ERINNERUNG = "ERINNERUNG",
}

export const enum PosteingangSortDto {
    TYP = "typ",
    DATUM = "datum",
    VORGANGSTYP = "vorgangstyp",
    BENUTZERNAME = "benutzername",
    KOMMENTAR = "kommentar",
    INHALT = "inhalt",
    ANHANG = "anhang",
}

export const enum SchlagwortOperatorDto {
    AND = "and",
    OR = "or",
}

export const enum AufgabeTypDto {
    LIGHT = "light",
    FULL = "full",
}

export const enum TextbausteinVisibilityDto {
    TENANT = "TENANT",
    PRIVATE = "PRIVATE",
}

export const enum VersandArtDto {
    LOKALDRUCK = "LOKALDRUCK",
    EMAIL = "EMAIL",
}

export const enum AkteEintragTypRichtungDto {
    AUSGEHEND = "AUSGEHEND",
    EINGEHEND = "EINGEHEND",
}

export const enum BoxListColumnDto {
    BOX_AKTENNUMMER = "box_aktennummer",
    BOX_STATUS = "box_status",
}

export const enum BoxStatusDto {
    OPEN = "open",
    CLOSED = "closed",
}

export const enum RisikoVertragSpartenListeSpalteDto {
    BEZEICHNUNG = "bezeichnung",
    ADDITIONAL_BEZEICHNUNG = "additional_bezeichnung",
    DECKUNGSSUMME = "deckungssumme",
    BRUTTOPRAEMIE = "bruttopraemie",
    NETTOPRAEMIE = "nettopraemie",
    VERSICHERUNG_LFD_NR = "versicherung_lfd_nr",
    VERSICHERUNGSSCHEIN_NR = "versicherungsschein_nr",
    LAUFZEIT_BIS = "laufzeit_bis",
    VERSICHERER = "versicherer",
    SPARTE_NR = "sparte_nr",
    SPARTEN_DETAIL_TEXT = "sparten_detail_text",
}

export const enum ColumnTypeDto {
    TEXT = "text",
    DATE = "date",
    DATETIME = "datetime",
    CURRENCY = "currency",
    NUMBER = "number",
    BADGE = "badge",
    KENNZEICHEN = "kennzeichen",
    KENNZEICHEN_OR_TEXT = "kennzeichen-or-text",
    HTML = "html",
}

export const enum RowModeDto {
    NORMAL = "normal",
    DELETED = "deleted",
    DISABLED = "disabled",
    EMPHASIZED = "emphasized",
}

export const enum TerminRowZuordnungDto {
    SCHADEN = "SCHADEN",
    VERTRAG = "VERTRAG",
    AKTEN_EINTRAG = "AKTEN_EINTRAG",
    AKTE_KUNDE = "AKTE_KUNDE",
}

export const enum SchadenEreignisSpalteDto {
    SCHADEN_LFD_NR = "schaden_lfd_nr",
    SCHADEN_ZAHLUNG_VN = "schaden_zahlung_vn",
    SCHADEN_ZAHLUNG_VR = "schaden_zahlung_vr",
    SCHADEN_ZAHLUNG_VR_VN = "schaden_zahlung_vr_vn",
    SCHADEN_ZAHLUNG_AUFWAND = "schaden_zahlung_aufwand",
    SCHADEN_ZAHLUNG_RESERVE = "schaden_zahlung_reserve",
    HAS_UNBERECHTIGTE_SCHAEDEN = "has_unberechtigte_schaeden",
    SCHADEN_TYPEN = "schaden_typen",
    SCHADEN_DATUM_LISTE = "schaden_datum_liste",
}

export const enum SchadenListeSpalteDto {
    SCHADEN_NUMMER = "schaden_nummer",
    SCHADEN_DATUM = "schaden_datum",
    SCHADEN_JAHR = "schaden_jahr",
    SCHADEN_MELDEDATUM = "schaden_meldedatum",
    SCHADEN_MELDEJAHR = "schaden_meldejahr",
    SCHADEN_TYP = "schaden_typ",
    SCHADEN_BETRIEBSSTAETTE = "schaden_betriebsstaette",
    SCHADEN_BETRIEBSSTAETTE_OU = "schaden_betriebsstaette_ou",
    SCHADEN_ART = "schaden_art",
    SCHADEN_SCHLIESSGRUND = "schaden_schliessgrund",
    SCHADEN_NAME_GEMELDET_VON = "schaden_name_gemeldet_von",
    SCHADEN_VERSICHERER = "schaden_versicherer",
    SCHADEN_ZUSATZ = "schaden_zusatz",
    SCHADEN_ZAHLUNG_VR = "schaden_zahlung_vr",
    SCHADEN_ZAHLUNG_VN = "schaden_zahlung_vn",
    SCHADEN_ZAHLUNG_VR_VN = "schaden_zahlung_vr_vn",
    SCHADEN_ZAHLUNG_AUFWAND = "schaden_zahlung_aufwand",
    SCHADEN_ZAHLUNG_RESERVE = "schaden_zahlung_reserve",
    SCHADEN_STATUS = "schaden_status",
    SCHADEN_AKTIVE_VERFAHRENSSTAENDE = "schaden_aktive_verfahrensstaende",
    SCHADEN_VORHERIGE_VERFAHRENSSTAENDE = "schaden_vorherige_verfahrensstaende",
    SCHADEN_URSACHE1 = "schaden_ursache1",
    SCHADEN_URSACHE2 = "schaden_ursache2",
    SCHADEN_ORT = "schaden_ort",
    SCHADEN_KUNDENNUMMER = "schaden_kundennummer",
    SCHADEN_KUNDENNAME = "schaden_kundenname",
    SCHADEN_VR_SCHADENNUMMER = "schaden_vr_schadennummer",
    SCHADEN_EREIGNISLFDNR = "schaden_ereignislfdnr",
    SCHADEN_VERTRAG = "schaden_vertrag",
    SCHADEN_VERTRAGSNUMMER = "schaden_vertragsnummer",
    SCHADEN_PATIENT_VORNAME = "schaden_patient_vorname",
    SCHADEN_PATIENT_NACHNAME = "schaden_patient_nachname",
    SCHADEN_PATIENT_GESCHLECHT = "schaden_patient_geschlecht",
    SCHADEN_PATIENT_GEBURTSDATUM = "schaden_patient_geburtsdatum",
    SCHADEN_PATIENT_STERBEDATUM = "schaden_patient_sterbedatum",
    SCHADEN_PATIENT_PATIENT_ID = "schaden_patient_patient_id",
    SCHADEN_FACHRICHTUNG = "schaden_fachrichtung",
    SCHADEN_FACHRICHTUNG_ANAESTHESIE = "schaden_fachrichtung_anaesthesie",
    SCHADEN_BEHANDLUNGSART = "schaden_behandlungsart",
    SCHADEN_DIENSTART = "schaden_dienstart",
    SCHADEN_KRANKENHAUSBEREICH = "schaden_krankenhausbereich",
    SCHADEN_URSACHEN_HEILWESEN = "schaden_ursachen_heilwesen",
    SCHADEN_VERURSACHER = "schaden_verursacher",
    SCHADEN_BEGRIFFE = "schaden_begriffe",
    SCHADEN_HAFTUNG = "schaden_haftung",
    SCHADEN_KURZDARSTELLUNG = "schaden_kurzdarstellung",
    SCHADEN_VERWALTUNG = "schaden_verwaltung",
    SCHADEN_DRG = "schaden_drg",
    SCHADEN_MDC = "schaden_mdc",
    SCHADEN_KFZ_KENNZEICHEN = "schaden_kfz_kennzeichen",
    SCHADEN_MASCHINENKENNZEICHNUNG = "schaden_maschinenkennzeichnung",
    SCHADEN_UHRZEIT = "schaden_uhrzeit",
    SCHADEN_KOSTENSTELLE = "schaden_kostenstelle",
    SCHADEN_KFZ_SCHADENORT = "schaden_kfz_schadenort",
    SCHADEN_VERSCHULDEN = "schaden_verschulden",
    SCHADEN_FAHRER_NAME = "schaden_fahrer_name",
    SCHADEN_FAHRER_GESCHLECHT = "schaden_fahrer_geschlecht",
    SCHADEN_FAHRER_FUEHRERSCHEIN = "schaden_fahrer_fuehrerschein",
    SCHADEN_FAHRER_GEBURTSDATUM = "schaden_fahrer_geburtsdatum",
    SCHADEN_FAHRER_FUEHRERSCHEINAUSSTELLUNGSDATUM = "schaden_fahrer_fuehrerscheinausstellungsdatum",
    SCHADEN_FAHRER_BESCHAEFTIGUNGSVERHAELTNIS = "schaden_fahrer_beschaeftigungsverhaeltnis",
    SCHADEN_FAHRZEUG_HERSTELLER = "schaden_fahrzeug_hersteller",
    SCHADEN_FAHRZEUG_TYP = "schaden_fahrzeug_typ",
    SCHADEN_FAHRZEUG_ERSTZULASSUNG = "schaden_fahrzeug_erstzulassung",
    SCHADEN_GRUPPIERUNG = "schaden_gruppierung",
    SCHADEN_HAS_TERMINE = "schaden_has_termine",
}

export const enum SchadenStatusTypDto {
    OFFEN = "offen",
    GESCHLOSSEN = "geschlossen",
    GESCHLOSSEN_DOPPELT = "geschlossen_doppelt",
}

export const enum ReserveListSpalteDto {
    ERFASSUNGSDATUM = "erfassungsdatum",
    VERAENDERUNGSART = "veraenderungsart",
    USER = "user",
    GRUND = "grund",
    BETRAG = "betrag",
    SCHADENNUMMER = "schadennummer",
    SCHADENTYP = "schadentyp",
}

export const enum ReserveUebersichtSpalteDto {
    ANFANGSBESTAND = "anfangsbestand",
    ANFANGSBESTAND2 = "anfangsbestand2",
    AUFLOESUNG = "aufloesung",
    AUFLOESUNG2 = "aufloesung2",
    BETRIEBSSTAETTE = "betriebsstaette",
    BETRIEBSSTAETTE_OU = "betriebsstaette_ou",
    ENDBESTAND = "endbestand",
    ENDBESTAND2 = "endbestand2",
    INANSPRUCHNAHME = "inanspruchnahme",
    LEERSPALTE1 = "leerspalte1",
    LEERSPALTE2 = "leerspalte2",
    RESERVE_ERHOEHUNG = "reserve_erhoehung",
    SCHADEN_NUMMER = "schaden_nummer",
    UNBEANSPRUCHTE_RESERVE_ERHOEHUNG = "unbeanspruchte_reserve_erhoehung",
    ZAHLUNG = "zahlung",
}

export const enum ZahlungListSpalteDto {
    ABSENDER_BIC = "absender_bic",
    ABSENDER_IBAN = "absender_iban",
    ABSENDER_KONTOINHABER = "absender_kontoinhaber",
    BETRAG = "betrag",
    BETRIEBSSTAETTE = "betriebsstaette",
    EMPFAENGER_BIC = "empfaenger_bic",
    EMPFAENGER_IBAN = "empfaenger_iban",
    EMPFAENGER_KONTOINHABER = "empfaenger_kontoinhaber",
    ERFASSUNGSDATUM = "erfassungsdatum",
    ID = "id",
    KATEGORIE = "kategorie",
    KOSTENART = "kostenart",
    SCHADENNUMMER = "schadennummer",
    STORNOGRUND = "stornogrund",
    STORNOZEITPUNKT = "stornozeitpunkt",
    USER = "user",
    VERWENDUNGSZWECK = "verwendungszweck",
    VORGANGSART = "vorgangsart",
    VERWALTUNG = "verwaltung",
    SCHADENTYP = "schadentyp",
}

export const enum FilterDomainDto {
    SCHAEDEN = "Filter claims",
    ZAHLUNGEN = "Filter payments",
}

export const enum FilterTypeDto {
    DATE_RANGE = "DATE_RANGE",
    NUMBER_RANGE = "NUMBER_RANGE",
    SELECT = "SELECT",
}

export const enum SchadenStatistikListeSpalteDto {
    LABEL = "label",
    SCHADEN_ZAHLUNG_VR = "schaden_zahlung_vr",
    SCHADEN_ZAHLUNG_VN = "schaden_zahlung_vn",
    SCHADEN_ZAHLUNG_AUFWAND = "schaden_zahlung_aufwand",
    SCHADEN_ZAHLUNG_RESERVE = "schaden_zahlung_reserve",
    SCHADEN_ZAHLUNG_VR_VN = "schaden_zahlung_vr_vn",
    ANZAHL = "anzahl",
}

export const enum StatistikColumnTypeDto {
    COUNT = "COUNT",
    CURRENCY = "CURRENCY",
    TEXT = "TEXT",
}

export const enum StatistikDefinitionTypeDto {
    SCHADEN = "SCHADEN",
}

export const enum TerminListColumnDto {
    TERMIN_EXTERNALUSER_ID = "termin_externaluser_id",
    TERMIN_GRUND = "termin_grund",
    TERMIN_TENANT = "termin_tenant",
    TERMIN_ZEITPUNKT = "termin_zeitpunkt",
    TERMIN_ERLEDIGT = "termin_erledigt",
    TERMIN_OPEN_TODOS = "termin_open_todos",
    TERMIN_ZUSATZ = "termin_zusatz",
    TERMIN_ZUORDNUNG = "termin_zuordnung",
}

export const enum TerminRefTypDto {
    SCHADEN = "SCHADEN",
    VERTRAG = "VERTRAG",
    AKTE = "AKTE",
    AKTE_KUNDE = "AKTE_KUNDE",
}

export const enum GruppierungsTypDto {
    NACH_VERSICHERUNGSART = "nach_versicherungsart",
    MULTILINE = "multiline",
    KFZ = "kfz",
}

export const enum PraemienListeSpalteDto {
    BEZEICHNUNG = "bezeichnung",
    STRASSE = "strasse",
    PLZ = "plz",
    ORT = "ort",
    BRUTTOPRAEMIE = "bruttopraemie",
    NETTOPRAEMIE = "nettopraemie",
    VERSICHERUNGSSTEUER = "versicherungssteuer",
    VERSICHERUNG_LFD_NR = "versicherung_lfd_nr",
    VERSICHERUNGSSCHEIN_NR = "versicherungsschein_nr",
    LAUFZEIT_BIS = "laufzeit_bis",
    VERSICHERER = "versicherer",
    SPARTE_NR = "sparte_nr",
    OBERBEGRIFF = "oberbegriff",
}

export const enum SchadenTypStatistikSpalteDto {
    BEZEICHNUNG = "bezeichnung",
    SUMME_RESERVE = "summe_reserve",
    SUMME_ZAHLUNG_VN = "summe_zahlung_vn",
    SUMME_ZAHLUNG_VR = "summe_zahlung_vr",
    SUMME_AUFWAND = "summe_aufwand",
    ANZAHL_SCHAEDEN = "anzahl_schaeden",
}

export const enum VertragListeSpalteDto {
    VERTRAG_ABMELDEDATUM = "vertrag_abmeldedatum",
    VERTRAG_BEZEICHNUNG = "vertrag_bezeichnung",
    VERTRAG_BRUTTOJAHRESPRAEMIE = "vertrag_bruttojahrespraemie",
    VERTRAG_DECKUNGSUMFANG = "vertrag_deckungsumfang",
    VERTRAG_FIRMIERUNG = "vertrag_firmierung",
    VERTRAG_HAUPTFAELLIGKEIT = "vertrag_hauptfaelligkeit",
    VERTRAG_KFZ_HERSTELLER = "vertrag_kfz_hersteller",
    VERTRAG_KFZ_KENNZEICHEN = "vertrag_kfz_kennzeichen",
    VERTRAG_KFZ_TYP = "vertrag_kfz_typ",
    VERTRAG_KFZ_WAGNIS = "vertrag_kfz_wagnis",
    VERTRAG_FAHRZEUGIDENTIFIKATIONSNUMMER = "vertrag_fahrzeugidentifikationsnummer",
    VERTRAG_KUNDENNAME = "vertrag_kundenname",
    VERTRAG_KUNDENNUMMER = "vertrag_kundennummer",
    VERTRAG_KVS_STATUS = "vertrag_kvs_status",
    VERTRAG_LFD_PROD_NR = "vertrag_lfd_prod_nr",
    VERTRAG_NETTO_JAHRESPRAEMIE = "vertrag_netto_jahrespraemie",
    VERTRAG_POLICE_ART = "vertrag_police_art",
    VERTRAG_RISIKO = "vertrag_risiko",
    VERTRAG_SCHAEDEN = "vertrag_schaeden",
    VERTRAG_SF_HAFTPFLICHT = "vertrag_sf_haftpflicht",
    VERTRAG_SF_VOLLKASKO = "vertrag_sf_vollkasko",
    VERTRAG_SPARTE = "vertrag_sparte",
    VERTRAG_STATUS = "vertrag_status",
    VERTRAG_VERSICHERER = "vertrag_versicherer",
    VERTRAG_VERSICHERUNGSSCHEIN_NUMMER = "vertrag_versicherungsschein_nummer",
    VERTRAG_VERSICHERUNGSSTEUER = "vertrag_versicherungssteuer",
    VERTRAG_VERSICHERUNGSUMFANG = "vertrag_versicherungsumfang",
    VERTRAG_VERTRAGSLAUFZEIT_BIS = "vertrag_vertragslaufzeit_bis",
    VERTRAG_VERTRAGSLAUFZEIT_VON = "vertrag_vertragslaufzeit_von",
    VERTRAG_ZAHLUNGSWEISE = "vertrag_zahlungsweise",
    VERTRAG_MONTALICHER_BETRAG = "vertrag_montalicher_betrag",
    VERTRAG_TITEL = "vertrag_titel",
    VERTRAG_EINHEIT = "vertrag_einheit",
    VERTRAG_FINANZART = "vertrag_finanzart",
    VERTRAG_HAS_TERMINE = "vertrag_has_termine",
    VERTRAG_KFZ_ERSTZULASSUNG = "vertrag_kfz_erstzulassung",
    VERTRAG_INTERNE_VERTRAGSNUMMER_ASKLEPIOS = "vertrag_interne_vertragsnummer_asklepios",
    VERTRAG_KOSTENSTELLE = "vertrag_kostenstelle",
    VERTRAG_NIEDERLASSUNG = "vertrag_niederlassung",
    VERTRAG_BUCHUNGSKREIS = "vertrag_buchungskreis",
    VERTRAG_ZUSATZINFO1 = "vertrag_zusatzinfo1",
    VERTRAG_ZUSATZINFO2 = "vertrag_zusatzinfo2",
    VERTRAG_ZUSATZINFO3 = "vertrag_zusatzinfo3",
}

export const enum VertragStatusDto {
    AKTIV = "aktiv",
    ABGELAUFEN = "abgelaufen",
}

export const enum VertragsanliegenModeDto {
    VERTRAG = "VERTRAG",
    VERSICHERUNGSSCHUTZ = "VERSICHERUNGSSCHUTZ",
    KUNDE_ALLGEMEIN = "KUNDE_ALLGEMEIN",
}

export const enum BoxType {
    ABSENDER_BOX = "ABSENDER_BOX",
    ADDITIONAL_BOX1 = "ADDITIONAL_BOX1",
    ADDITIONAL_BOX2 = "ADDITIONAL_BOX2",
    ADDITIONAL_BOX3 = "ADDITIONAL_BOX3",
    ADDITIONAL_BOX4 = "ADDITIONAL_BOX4",
    ADRESS_BOX = "ADRESS_BOX",
    BETREFF_BOX = "BETREFF_BOX",
    DATUM_BOX = "DATUM_BOX",
    KONTAKT_BOX1 = "KONTAKT_BOX1",
    KONTAKT_BOX2 = "KONTAKT_BOX2",
    PAPIER_BOX = "PAPIER_BOX",
    SEITENZAHL = "SEITENZAHL",
    SEITENZAHL_FF = "SEITENZAHL_FF",
    TEXT_BOX = "TEXT_BOX",
    TEXTFF_BOX = "TEXTFF_BOX",
}

export const enum FreigabeTyp {
    LINKSUNTERSCHRIFT = "LINKSUNTERSCHRIFT",
    RECHTSUNTERSCHRIFT = "RECHTSUNTERSCHRIFT",
    FREIGABE = "FREIGABE",
}

export const enum TextbausteinVarGroup {
    BRIEF = "BRIEF",
    SCHADEN = "SCHADEN",
    VERTRAG = "VERTRAG",
    KUNDEAKTE = "KUNDEAKTE",
    KUNDE = "KUNDE",
}

export const enum VariableGroup {
    SCHADEN = "SCHADEN",
    VERTRAG = "VERTRAG",
    KUNDEAKTE = "KUNDEAKTE",
    KUNDE = "KUNDE",
}

export const enum KundenFeatureName {
    ASKLEPIOS = "ASKLEPIOS",
    KFZ_ADDITIONAL_FIELDS = "KFZ_ADDITIONAL_FIELDS",
    MEDICLIN_IMPORT = "MEDICLIN_IMPORT",
    ROSSMANN = "ROSSMANN",
    VKB_IMPORT = "VKB_IMPORT",
}

export const enum EMenuItem {
    ADRESSBUCH = "adressbuch",
    BOX = "box",
    DASHBOARD = "dashboard",
    VERTRAG = "vertrag",
    RISIKEN = "risiken",
    SCHADEN = "schaden",
    STATISTIK = "statistik",
    POSTEINGANG = "posteingang",
    ARCHIV = "archiv",
    FUNKTIONEN = "funktionen",
    SCHADENEREIGNIS = "schadenereignis",
}

export const enum Verwaltung {
    KUNDE = "KUNDE",
    MAKLER = "MAKLER",
    VERSICHERER = "VERSICHERER",
}

export const enum SchadenDezentralTyp {
    BVS_KUNDE = "BVS_KUNDE",
    OU = "OU",
}

export const enum SchadenGruppe {
    GEB_INVENTAR_BU = "GEB_INVENTAR_BU",
    HAFTPFLICHT = "HAFTPFLICHT",
    KFZ = "KFZ",
    SONSTIGES = "SONSTIGES",
    TECHNISCH = "TECHNISCH",
    WEITERE = "WEITERE",
}

export const enum StatistikAuswertungAccessType {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC",
    GLOBAL = "GLOBAL",
}

export const enum StatistikAuswertungDefaultView {
    FILTER = "FILTER",
    DIAGRAM = "DIAGRAM",
    LIST = "LIST",
}

export const enum ChainLadderDataType {
    AJ_ZAHLUNG = "AJ_ZAHLUNG",
    MJ_ZALHUNG = "MJ_ZALHUNG",
    AJ_AUFWAND = "AJ_AUFWAND",
    MJ_AUFWAND = "MJ_AUFWAND",
}

export const enum TerminGrundDomain {
    VERTRAG = "VERTRAG",
    SCHADEN = "SCHADEN",
}

export const enum OffsetType {
    DAYS = "DAYS",
    WEEKS = "WEEKS",
    MONTHS = "MONTHS",
    YEARS = "YEARS",
}

export const enum Status {
    AKTIV = "AKTIV",
    ABGELAUFEN = "ABGELAUFEN",
}

export const enum VorschauGenerationStatus {
    NOT_PROCESSED = "NOT_PROCESSED",
    IN_PROCESSING = "IN_PROCESSING",
    PROCESSED = "PROCESSED",
    ERROR = "ERROR",
    ENCRYPTED = "ENCRYPTED",
    NOT_RELEVANT = "NOT_RELEVANT",
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}
